import React from "react";
import { InputAdornment, TextField, WithStyles } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import classNames from "classnames";
import { TextFieldProps } from "@material-ui/core/TextField";

import validNumber from "../../helpers/validNumber";
import validDecimalNumber from "../../helpers/validDecimalNumber";
import formatCurrency from "../../helpers/format/formatCurrency";
import styles from "./CustomTextField/styles";

interface FormatProps extends WithStyles<typeof styles> {
  withStyle: boolean;
  color?: string;
  disableUnderline?: boolean;
}

interface Props {
  inputRef?: ((el: HTMLInputElement) => void) | React.Ref<any>;
  setFieldValue: (name: string, value: any) => void;
  name: string;
  disableUnderline?: boolean;
  color?: string;
  id?: string;
}

const PenniesInput = ({
  setFieldValue,
  name,
  disableUnderline,
  classes,
  color,
  withStyle,
  ...props
}: Props &
  Partial<TextFieldProps> &
  Partial<FormatProps> &
  WithStyles<typeof styles>): JSX.Element => {
  const underlineClasses = classNames({
    [classes.underlineAddress]: true
  });
  const inputClasses = withStyle
    ? {
        classes: {
          underline: underlineClasses,
          input: classes.input,
          error: classes.errorInput
        }
      }
    : {};
  const labelClasses = withStyle
    ? {
        classes: {
          focused: classes.cssFocused,
          error: classes.errorLabel
        }
      }
    : {};

  return (
    // @ts-ignore
    <TextField
      onChange={(event): void => {
        const value = Number(event.target.value);
        if (validNumber(value) || validDecimalNumber(event.target.value)) {
          const str = String(event.target.value);
          if (str.includes(".") && str.split(".")[1].length >= 2) {
            const formattedValue = Number(str).toFixed(2);
            setFieldValue(name, formattedValue);
          } else {
            setFieldValue(name, str);
          }
        }
      }}
      {...props}
      InputLabelProps={{
        ...labelClasses
      }}
      InputProps={{
        ...inputClasses,
        onBlur: (): void => {
          const value = Number(props.value);
          setFieldValue(name, formatCurrency(value));
        },
        startAdornment: (
          <InputAdornment position="start">
            <div style={{ color }}>$</div>
          </InputAdornment>
        ),
        disableUnderline
      }}
    />
  );
};

export default withStyles(styles)(PenniesInput);
