import axios, { AxiosRequestConfig } from "axios";
import isEmpty from "lodash/isEmpty";
import deleteCommaKeys from "../../../helpers/deleteCommaKeys";
import omitEmptyKeys from "../../../helpers/omitEmptyKeys";

const createPaymentProfileAttachments = ({
  id,
  filename,
  category = ""
}: {
  id: string;
  filename: string;
  category?: string;
}): Promise<any> => {
  const url = `/factoring/payment_profiles/${id}/attachments/`;
  const options: AxiosRequestConfig = {
    url,
    method: "POST",
    data: {
      filename,
      category
    }
  };
  return axios(options);
};

const getSimilarPaymentProfile = async (
  paymentProfileId: string
): Promise<any> => {
  const url = `/factoring/payment_profiles/${paymentProfileId}/similar/?limit=100`;
  const options: AxiosRequestConfig = {
    url,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

const createPaymentProfileAdmin = (data: {}): Promise<any> => {
  const url = `/admin/payment_profile/`;
  const options: AxiosRequestConfig = {
    url,
    method: "POST",
    data
  };
  return axios(options);
};

const createPaymentProfileFromBrokerAdmin = ({
  brokerFactoringId,
  factoringUserId
}: {
  brokerFactoringId: string;
  factoringUserId: string;
}): Promise<any> => {
  const url = `admin/factoring/${factoringUserId}/payment_profile/`;
  const options: AxiosRequestConfig = {
    url,
    method: "POST",
    data: {
      broker_id: brokerFactoringId
    }
  };
  return axios(options);
};

const createPaymentProfileRelationship = (data: {
  user_id?: string;
  factoring_id?: string;
  email?: string;
  payment_profile_id?: string;
}): Promise<any> => {
  const url = `/admin/payment_profile/`;
  const options: AxiosRequestConfig = {
    url,
    method: "POST",
    data
  };
  return axios(options);
};

const getPaymentProfilePurchases = (
  paymentProfileId: string,
  limit: number,
  filters: Record<string, string>
): Promise<any> => {
  const url = `/factoring/payment_profiles/${paymentProfileId}/payments/`;
  const options: AxiosRequestConfig = {
    url,
    method: "GET",
    params: {
      limit,
      ...deleteCommaKeys(omitEmptyKeys(filters))
    }
  };
  return axios(options);
};

const exportPaymentProfilePurchases = async (
  paymentProfileId: string,
  filters: Record<string, string>,
  ids: string[]
): Promise<any> => {
  const url = `/factoring/payment_profiles/${paymentProfileId}/payments/export/`;
  const options: AxiosRequestConfig = {
    url,
    method: "GET",
    data: {
      ...deleteCommaKeys(omitEmptyKeys(filters)),
      ...(() => (!isEmpty(ids) ? { id: ids } : {}))()
    }
  };
  const response = await axios(options);
  return response.data;
};

const exportPaymentProfilePurchaseReport = async (
  paymentProfileId: string,
  purchaseId: string
): Promise<any> => {
  const url = `/factoring/payment_profiles/${paymentProfileId}/payments/${purchaseId}/export/`;
  const options: AxiosRequestConfig = {
    url,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

const checkExistingPaymentProfile = async (
  mc: string,
  dot: string
): Promise<any> => {
  const url = `/factoring/payment_profiles/check_exists/?mc=${mc}&dot=${dot}`;
  const options: AxiosRequestConfig = {
    url,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

export default {
  createPaymentProfileAttachments,
  createPaymentProfileRelationship,
  getPaymentProfilePurchases,
  exportPaymentProfilePurchases,
  createPaymentProfileAdmin,
  exportPaymentProfilePurchaseReport,
  createPaymentProfileFromBrokerAdmin,
  checkExistingPaymentProfile,
  getSimilarPaymentProfile
};
