import React, { ChangeEventHandler } from "react";
import { Grid, List, Typography } from "@material-ui/core";
import Card from "../../../../../components/Card";
import ListItem from "../../../../../components/ListItem";
import BoldInput, {Select} from "../../../../../components/inputs/BoldInput";
import formatPhone from "../../../../../helpers/format/formatPhone";
import onlyNumbers from "../../../../../helpers/format/onlyNumbers";
import ProfileSection from "../../../../../components/ProfileSection";

interface Props {
  values: {
    apEmail: string;
    contactEmail: string;
    contactName: string;
    timezone: string;
    paymentStatusEmail: string;
    noaContactEmail: string;
    companyName: string;
    streetOne: string;
    streetTwo: string;
    city: string;
    zip: string;
    phoneNumber: string;
    faxNumber: string;
    state: string;
    phoneNumberExt: string;
    faxNumberExt: string;
  };
  errors: {
    apEmail?: string;
    contactEmail?: string;
    noaContactEmail?: string;
    companyName?: string;
    paymentStatusEmail?: string;
    streetOne?: string;
    streetTwo?: string;
    city?: string;
    zip?: string;
    phoneNumber?: string;
    faxNumber?: string;
    state?: string;
    phoneNumberExt?: string;
    faxNumberExt?: string;
    contactName?: string;
    timezone?: string;
  };
  touched: {
    apEmail?: boolean;
    contactEmail?: boolean;
    paymentStatusEmail?: boolean;
    noaContactEmail?: boolean;
    companyName?: boolean;
    streetOne?: boolean;
    streetTwo?: boolean;
    city?: boolean;
    zip?: boolean;
    phoneNumber?: boolean;
    faxNumber?: boolean;
    state?: boolean;
    phoneNumberExt?: boolean;
    faxNumberExt?: boolean;
    contactName?: boolean;
    timezone?: boolean;
  };
  handleChange: ChangeEventHandler<HTMLInputElement>;
  handleBlur: ChangeEventHandler<HTMLInputElement>;
  disabled: boolean;
}

export default ({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  disabled
}: Props): JSX.Element => {
  return (
    <Card>
      <List dense style={{ width: "100%" }}>
        <ListItem>
          <ProfileSection>Contact Info:</ProfileSection>
        </ListItem>
        <ListItem>
          <BoldInput
            fullWidth
            name="apEmail"
            label="Invoicing Email"  // Renamed from AP Email
            value={values.apEmail}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.apEmail}
            helperText={errors.apEmail}
          />
        </ListItem>
        <ListItem>
          <BoldInput
            fullWidth
            label="Payment Status Email"
            name="paymentStatusEmail"
            value={values.paymentStatusEmail}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.paymentStatusEmail}
            helperText={errors.paymentStatusEmail}
          />
        </ListItem>
        <ListItem>
          <BoldInput
            fullWidth
            label="Contact Email"
            name="contactEmail"
	    disabled={true}
            value={values.contactEmail}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.contactEmail}
            helperText={errors.contactEmail}
          />
        </ListItem>
        <ListItem>
          <BoldInput
            fullWidth
            label="NOA Contact Email"
            name="noaContactEmail"
            value={values.noaContactEmail}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.noaContactEmail}
            helperText={errors.noaContactEmail}
          />
        </ListItem>
        <ListItem>
          <BoldInput
            fullWidth
            label="Contact Name"
            name="contactName"
            value={values.contactName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.contactName}
            helperText={errors.contactName}
          />
        </ListItem>
        <ListItem>
          <Select
            fullWidth
            label="Timezone"
            value={values.timezone}
            onChange={handleChange}
            onBlur={handleBlur}
            name="timezone"
            selections={[
              {
                text: "PST/PDT",
                value: "PT"
              },
              {
                text: "MST/MDT",
                value: "MT"
              },
              {
                text: "CST/CDT",
                value: "CT"
              },
              {
                text: "EST/EDT",
                value: "ET"
              },
              {
                text: "AKST/AKDT",
                value: "AKT"
              }
            ]}
          />
        </ListItem>
        <ListItem>
          <BoldInput
            fullWidth
            label="Company"
            name="companyName"
            value={values.companyName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.companyName}
            helperText={errors.companyName}
          />
        </ListItem>
        <ListItem>
          <BoldInput
            fullWidth
            label="Street 1"
            name="streetOne"
            value={values.streetOne}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.streetOne}
            helperText={errors.streetOne}
          />
        </ListItem>
        <ListItem>
          <BoldInput
            fullWidth
            label="Street 2"
            name="streetTwo"
            value={values.streetTwo}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.streetTwo}
            helperText={errors.streetTwo}
          />
        </ListItem>
        <ListItem>
          <BoldInput
            fullWidth
            label="City"
            name="city"
            value={values.city}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.city}
            helperText={errors.city}
          />
        </ListItem>
        <ListItem>
          <Grid container justify="space-between" spacing={16}>
            <Grid item xs={6}>
              <BoldInput
                fullWidth
                label="State"
                name="state"
                value={values.state}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.state}
                helperText={errors.state}
              />
            </Grid>
            <Grid item xs={6}>
              <BoldInput
                fullWidth
                label="Zip"
                name="zip"
                value={values.zip}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.zip}
                helperText={errors.zip}
              />
            </Grid>
          </Grid>
        </ListItem>
        <ListItem>
          <Grid container justify="space-between" spacing={16}>
            <Grid item xs={6}>
              <BoldInput
                fullWidth
                label="Phone"
                name="phoneNumber"
                value={formatPhone(onlyNumbers(values.phoneNumber))}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber}
              />
            </Grid>
            <Grid item xs={6}>
              <BoldInput
                fullWidth
                label="Ext."
                name="phoneNumberExt"
                value={values.phoneNumberExt}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.phoneNumberExt}
                helperText={errors.phoneNumberExt}
              />
            </Grid>
          </Grid>
        </ListItem>
        <ListItem>
          <Grid container justify="space-between" spacing={16}>
            <Grid item xs={6}>
              <BoldInput
                fullWidth
                label="Fax"
                name="faxNumber"
                value={formatPhone(onlyNumbers(values.faxNumber))}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.faxNumber}
                helperText={errors.faxNumber}
              />
            </Grid>
            <Grid item xs={6}>
              <BoldInput
                fullWidth
                label="Ext."
                name="faxNumberExt"
                value={values.faxNumberExt}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.faxNumberExt}
                helperText={errors.faxNumberExt}
              />
            </Grid>
          </Grid>
        </ListItem>
      </List>
    </Card>
  );
};
