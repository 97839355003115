import { connect } from 'react-redux';
import { compose, lifecycle, withProps, withState } from 'recompose';
import { findIndex, get } from 'lodash';

import {
  closeDialog,
  getDialog,
  openDialog,
  openSnackbar
} from '../../../../modules/ui';
import {
  createDebtorNote,
  deleteDebtor,
  deleteDebtorNote,
  fetchDebtorNotes,
  fetchHistory,
  getDebtor,
  getDebtorCompanyName,
  getHistory,
  getNotes,
  loadDebtorNoteAttachments,
  queryDebtor,
  updateDebtor,
  updateDebtorNote,
  fetchSimilarDebtors,
  getSimilarDebtors,
  queryCreditReport
} from '../../../../modules/debtor';
import withTabs from '../../../../components/withTabs';
import Profile from './Profile';
import { debtorForm, notesForm } from './forms';
import tabs from '../tabs';
import { getUserType } from 'apps/haulpay-frontend/src/modules/auth';

const mapStateToProps = (
  state,
  {
    match: {
      params: { id }
    }
  }
) => ({
  companyName: getDebtorCompanyName(state),
  debtor: getDebtor(state),
  auditLog: getHistory(state),
  dialog: getDialog(state),
  notes: getNotes(id)(state),
  similarDebtors: getSimilarDebtors(state),
  userType: getUserType(state)
});

const mapDispatchToProps = {
  queryDebtor,
  updateDebtor,
  openSnackbar,
  fetchHistory,
  openDialog,
  closeDialog,
  deleteDebtor,
  fetchDebtorNotes,
  createDebtorNote,
  updateDebtorNote,
  loadDebtorNoteAttachments,
  deleteDebtorNote,
  fetchSimilarDebtors,
  queryCreditReport
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('isLoading', 'setLoading', true),
  withProps(
    ({
      history: {
        location: { pathname },
        push
      },
      match: {
        params: { id }
      },
      debtor,
      updateDebtor,
      openSnackbar,
      createDebtorNote,
      updateDebtorNote,
      similarDebtors,
      userType
    }) => ({
      disabled: userType === 'factoring_remote_admin',
      tabs: tabs(id, similarDebtors.length),
      navIndex: findIndex(tabs(id), tab => pathname === tab.route),
      handleRoute: route => push(route),
      id,
      debtorForm: debtorForm(updateDebtor, openSnackbar, debtor),
      notesForm: notesForm(
        id,
        createDebtorNote,
        updateDebtorNote,
        openSnackbar
      ),
      handleSetMaster: async () => {
        try {
          await updateDebtor(id, { master: true });
          openSnackbar('success', 'Set Debtor As Master!');
        } catch (err) {
          openSnackbar('error', err);
        }
      },
      userId: get(debtor, ['user', 'id'], '')
    })
  ),
  lifecycle({
    async componentDidMount() {
      const { id, setLoading } = this.props;
      setLoading(true);
      await this.props.queryCreditReport(id);
      await Promise.all([
        this.props.queryCreditReport(id),
        this.props.queryDebtor(id),
        this.props.fetchHistory(id),
        this.props.fetchDebtorNotes(id),
        this.props.fetchSimilarDebtors(id)
      ]);
      setLoading(false);
    }
  }),
  withTabs
)(Profile);
