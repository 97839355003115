import React, { useEffect, useCallback, useState, useRef } from "react";
import reduce from "lodash/reduce";
import concat from "lodash/concat";
import includes from "lodash/includes";
import get from "lodash/get";
import map from "lodash/map";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import { useDispatch, useSelector } from "react-redux";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Attach from "./Attach";
import Bank from "../../../components/Bank";
import {
  getPaymentTerms,
  fetchPaymentTerms,
  updatePaymentTerms
} from "../../../modules/invited";
import FactorCompany from "./FactorCompany";

function InviteBank({
  moveToNextStep,
  exchangePlaidToken,
  exchangePlaidLoading,
  exchangePlaidError,
  attachments,
  setAttachmentError,
  attachmentError,
  factoringProfileId,
  usedPlaid,
  brokerFactoringId,
  customToken,
  confirmInvite
}) {
  const dispatch = useDispatch();
  const isGone = useRef(false);
  const [factor, setFactor] = useState(false);
  const [showUploadAttachments, setShowUploadAttachments] = useState(false);
  const paymentTerms = useSelector(getPaymentTerms); // [0, 7, 15, 21]
  const [paymentSpeed, setPaymentSpeed] = useState(null);

  const handleChange = useCallback((...args) =>
    dispatch(updatePaymentTerms(...args), [])
  );

  useEffect(() => {
    if (isGone.current) return;
    async function asyncFetchPaymentTerms() {
      const res = await dispatch(fetchPaymentTerms(brokerFactoringId));
      if (res) {
        const defaultPaymentSpeed = find(res, ["is_default", true]).id || res[0];
        setPaymentSpeed(defaultPaymentSpeed);
        // handleChange(brokerFactoringId, defaultPaymentSpeed, {
        //   is_default: true
        // }, { send_update_email: false })
      }
    }
    asyncFetchPaymentTerms();
    return () => {
      isGone.current = true;
    };
  }, [brokerFactoringId]);
  
  const payoutTermsOption = [];

  map(
    paymentTerms,
    ({ id, is_default, payout_days, carrier_rate, name, payee }) => {
      const label = carrier_rate
        ? `${name} (${carrier_rate}% Quick Pay Fee)`
        : `${name} (Free)`;
      return payoutTermsOption.push({
        value: id,
        label,
        is_default
      });
    }
  );

  
  if (isEmpty(paymentTerms)) {
    return (
      <CircularProgress
        style={{ alignSelf: "center", justifySelf: "center", margin: 30 }}
        color="secondary"
        size={50}
      />
    );
  }
  return (
    <Grid
      container
      xs={10}
      sm={9}
      md={8}
      direction="column"
      justify="center"
      alignItems="center"
      alignContent="center"
      style={{ margin: "auto", width: "100%" }}
      spacing={16}
    >
      {(() => {
        if (factor) {
          return (
            <FactorCompany
              brokerFactoringId={brokerFactoringId}
              setFactor={setFactor}
              moveToNextStep={moveToNextStep}
            />
          );
        }
        if (showUploadAttachments) {
          return (
            <Attach
              nextStep={() => {
                const categories = reduce(
                  attachments,
                  (acc, cur) => concat(acc, get(cur, "categories")),
                  []
                );
                if (!usedPlaid && !includes(categories, "Void Check")) {
                  setAttachmentError("Please attach Voided Check.");
                } else {
                  setAttachmentError("");
                  setPaymentSpeed([]);
                  moveToNextStep();
                }
              }}
              error={attachmentError}
              factoringProfileId={factoringProfileId}
              usedPlaid={usedPlaid}
              setShowUploadAttachments={setShowUploadAttachments}
            />
          );
        }
        return (
          <>
            <Typography variant="body2" align="center">
              Please select your default payment option. You can ask the broker
              to change this later if needed.
            </Typography>
            <FormControl style={{ minWidth: 250 }}>
              <InputLabel id="default_payment">Default Payment</InputLabel>
              <Select
                id="default_payment"
                value={
                  paymentSpeed ||
                  (find(paymentTerms, ["payout_days", 0]) || {}).id
                }
                onChange={event => {
                  if (event.target.value === "factoring") {
                    return setFactor(true);
                  }
                  setPaymentSpeed(event.target.value);
                  handleChange(brokerFactoringId, event.target.value, {
                    is_default: true
                  });
                }}
              >
                {payoutTermsOption.map(
                  ({ value, label, is_default }, index) => (
                    <MenuItem key={index} value={value} id={index}>
                      <em defaultChecked={is_default}>{label}</em>
                    </MenuItem>
                  )
                )}
                <MenuItem value="factoring" id="factoring">
                  <p style={{ fontSize: 15, color: "#2196f3" }}>
                    Use my Factoring Company
                  </p>
                </MenuItem>
              </Select>
            </FormControl>
            <Bank
              handleSubmit={confirmInvite}
              showUploadAttachments={() => setShowUploadAttachments(true)}
              nextStep={moveToNextStep}
              submitToken={exchangePlaidToken}
              loading={exchangePlaidLoading}
              error={exchangePlaidError}
              factorCompany={
                <Typography
                  style={{
                    fontSize: 15,
                    color: "#2196f3",
                    textDecoration: "underline"
                  }}
                  onClick={() => setFactor(true)}
                  align="center"
                >
                  Use my Factoring Company
                </Typography>
              }
            />
          </>
        );
      })()}
    </Grid>
  );
}

export default InviteBank;
