import AdminDropDown from "../../../../components/AdminDropDown";
import formatDays from "../../../../helpers/format/formatDays";

const columns = [
  {
    key: "factoring.company_profile.name",
    name: "Client Business",
    filter: "text",
    filter_key: "client_company_name"
  },
  {
    key: "debtor.dba",
    name: "Debtor",
    filter: "text",
    filter_key: "bill_to_company_name"
  },
  {
    key: "amount",
    name: "Invoice Amnt.",
    sort: true
  },
  {
    key: "invoice_number",
    name: "Invoice #",
    filter: "text",
    filter_key: "invoice_number"
  },
  {
    key: "user_load_number",
    name: "Load #",
    filter: "text",
    filter_key: "user_load_number"
  },
  {
    key: "created",
    name: "Requested At",
    sort: true
  },
  {
    key: "assigned_admin.name",
    name: "Transaction Rep",
    filter: "component",
    filter_key: "assigned_admin",
    filter_value: "",
    filter_component: AdminDropDown,
    sort: "assigned_admin"
  },
  {
    key: "amount_funded",
    name: "Amnt. Funded",
    sort: true
  },
  {
    key: "status",
    name: "Status",
    filter: "dropdown",
    filter_key: "status",
    filter_value: "",
    filter_values: [
      { label: "None", value: null },
      { label: "New (NOA)", value: "new_noa" },
      { label: "New", value: "new" },
      { label: "Fuel Advance", value: "fuel_advance" },
      { label: "Pending", value: "pending" },
      { label: "Pending (NOA)", value: "pending_noa" },
      { label: "Pending Originals", value: "pending_originals" },
      { label: "Document Issue", value: "document_issue" },
      { label: "CF Review", value: "haulpay_review" },
      { label: "Document Review", value: "document_review" },
      { label: "Approved", value: "approved" },
      { label: "Incomplete", value: "incomplete" },
      { label: "Remote Approved", value: "remote_approved" },
      { label: "Declined", value: "declined" },
      { label: "Paid", value: "paid" },
      { label: "Non-Factored", value: "non_factored" },
      { label: "Deleted", value: "deleted" },
      { label: "New - Fast Track", value: "new_fasttrack" }
    ],
    sort: true
  },
  {
    key: "days_to_pay",
    name: "Aging",
    format: formatDays
  },
  {
    key: "more",
    name: "More"
  }
];

export default columns;
