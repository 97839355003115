import { Button, Grid } from "@material-ui/core";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { purchase } from "../../../../../api/schema";
import Table from "../../../../../components/Table";
import { openDialog } from "../../../../../modules/ui";
import columns from "./columns";

interface Props {
  purchases: Record<string, any>[];
}

export default ({ purchases }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const handleResubmitButton = useCallback(({ fundingRequest, id }) => {
    dispatch(
      openDialog(
        "resubmit-funding-request",
        "Confirm",
        "Are you sure you want to resubmit this funding request?",
        {
          fundingRequest,
          fundingRequestPurchase: id
        }
      )
    );
  }, []);
  const purchaseRows = purchases.map(purchase => ({
    ...purchase,
    action:
      ["Returned", "Failed"].includes(purchase.status) ? (
        <Grid>
          <Button
            variant="raised"
            size="medium"
            color="primary"
            onClick={() => handleResubmitButton(purchase)}
          >
            Resubmit
          </Button>
        </Grid>
      ) : (
        <div />
      )
  }));
  return <Table columns={columns} rows={purchaseRows} allowEmpty />;
};
