import { createAction } from "redux-starter-kit";
import { throttle } from "lodash";
import axios from "axios";
import UpdateProgress from "./uploadProgress";
import * as TYPES from "../types";
import paymentProfile from "../../../api/factoring/paymentProfile";

let cancelUploadRequest = null;

function createForm(fields) {
  const documentForm = new FormData();
  for (const key in fields) {
    documentForm.append(key, fields[key]);
  }
  return documentForm;
}

function uploadError(file, dispatch, error) {
  dispatch({
    type: TYPES.CANCEL_FILE_UPLOAD,
    payload: {
      ...file,
      status: "error",
      error
    }
  });
}

const uploadToServer = async (file, form, dispatch) => {
  const CancelToken = axios.CancelToken;
  const axiosInstance = axios.create();
  file.status = "uploading";

  try {
    await axiosInstance({
      url: file.url,
      method: "post",
      headers: {
        "content-type": "multipart/form-data"
      },
      data: form,
      onUploadProgress: throttle(evt => {
        const percentCompleted = evt.loaded / evt.total;
        file.progress = percentCompleted * 100;
        dispatch(UpdateProgress(file));
      }, 500),
      cancelToken: new CancelToken(c => {
        // An executor function receives a cancel function as a parameter
        cancelUploadRequest = c;
      })
    });
    dispatch({
      type: TYPES.FILE_UPLOADED,
      payload: {
        ...file,
        status: "success"
      }
    });
  } catch (error) {
    if (!axios.isCancel(error)) {
      uploadError(file, dispatch, error);
      throw error;
    }
  }
};

const functionCreateAttachments = (api, type) => {
  switch (type) {
    case "funding":
      return api.factoring.addFundingRequestAttachment;
    case "factoring":
      return api.factoring.addAttachementToProfile;
    case "payment":
      return api.factoring.paymentProfile.createPaymentProfileAttachments;
    case "NOA":
      return api.factorCompany.createAttachmentNOA;
    case "LOR":
      return api.factorCompany.createAttachmentNOA;
  }
};

const getAmazonKeys = async (file, api, type, baseId) => {
  const createAttachment = functionCreateAttachments(api, type, baseId);
  let category = "";
  if (type === "NOA" || type === "LOR") {
    category = type;
  }
  const res = await createAttachment({
    id: file.requestId,
    filename: file.name,
    category,
    for_relation: baseId
  });
  const form = createForm({
    ...res.data.upload_url.fields,
    file
  });
  file.url = res.data.upload_url.url;
  file.file_id = res.data.id;
  return { file, form };
};

const startUploads = async (payload, dispatch, api, type, baseId) => {
  if (Array.isArray(payload)) {
    payload.map(async item => {
      try {
        const { form, file } = await getAmazonKeys(item, api, type, baseId);
        await uploadToServer(file, form, dispatch);
      } catch (e) {
        uploadError(item, dispatch, e);
      }
    });
  } else {
    try {
      const { form, file } = await getAmazonKeys(payload, api, type, baseId);
      await uploadToServer(file, form, dispatch);
    } catch (e) {
      uploadError(payload, dispatch, e);
    }
  }
};

const UploadFiles = (payload, type = "factoring", baseId) => (
  dispatch,
  getState,
  { api, schema }
) => {
  startUploads(payload, dispatch, api, type, baseId);
  dispatch(createAction(TYPES.UPLOAD_FILES)(payload));
};

export default UploadFiles;
