import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, FormikConfig } from "formik";
import { compose } from "recompose";
import {
  Visibility as VisibilityIcon
} from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import get from "lodash/get";
import {
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from "@material-ui/core";
import Table from "../../../../../components/Table";
import withSelect from "../../../../../components/withSelect";
import Card from "../../../../../components/Card";
import ProfileSection from "../../../../../components/ProfileSection";
import columns from "./columns";
import LineItemDialog from "../LineItemDialog";
import {
  closeDialog,
  getDialog,
  openDialog,
  openSnackbar
} from "../../../../../modules/ui";
import { updateLineItem } from "../../../../../modules/factoring";
import colors from "../../../../../theme/colors";

interface ChargeBackGiveBackFormFields {
  [index: string]: number;
}

export type ChargeBackGiveBackForm = FormikConfig<ChargeBackGiveBackFormFields>;

interface Outter {
  chargeBacksAndGiveBacks: any[];
  chargeBackGivebackForm: ChargeBackGiveBackForm;
  lineItemCategories: Category[];
  status?: string;
}

interface Category {
  id: string;
  name: string;
  appliedAgainst: string;
}

interface Props {
  chargeBacksAndGiveBacks: any[];
  chargeBackGivebackForm: ChargeBackGiveBackForm;
  isSelected: Function;
  clearSelected: () => void;
  handleSelectAllClick: () => void;
  handleSelect: () => void;
  allSelected: boolean;
  lineItemCategories: Category[];
  status?: string;
}

const ChargebackGivebackCard = compose<Props, Outter>(withSelect)(
  ({
    chargeBacksAndGiveBacks = [],
    chargeBackGivebackForm,
    isSelected,
    allSelected,
    handleSelect,
    handleSelectAllClick,
    clearSelected,
    lineItemCategories = [],
    status = ''
  }: Props): JSX.Element => {
    const dialog = useSelector(getDialog);
    const dispatch = useDispatch();
    const isAdjustable = ["approved", "paid", "declined", "non-factored"].includes(status);
    return (
      <>
        <Formik {...chargeBackGivebackForm}>
          {({
            values,
            handleSubmit,
            handleChange,
            setFieldValue
          }): JSX.Element => (
            <Card style={{ padding: 8 }}>
              <Grid container direction="column" spacing={8}>
                <Grid item>
                  <ProfileSection>Chargebacks / Givebacks</ProfileSection>
                </Grid>
                <Grid item>
                  <Table
                    columns={columns}
                    rows={chargeBacksAndGiveBacks.map(row => ({
                      ...row,
                      action: (
                        <Grid item style={{ minHeight: 50 }}>
                          {isAdjustable ?
                            <IconButton
                              style={{
                                color: colors.link
                              }}
                              aria-label="View/Edit Line Item"
                              onClick={() =>
                                dispatch(
                                  openDialog(
                                    "funding-request-open-line-item",
                                    "",
                                    "",
                                    { ...row, type: "view" }
                                  )
                                )
                              }
                            >
                              <VisibilityIcon color="secondary" />
                            </IconButton> : 
                            <Tooltip title="View/Edit Line Item">
                              <IconButton
                                style={{
                                  width: "25px",
                                  height: "25px",
                                  color: colors.link
                                }}
                                onClick={() =>
                                  dispatch(
                                    openDialog(
                                      "funding-request-open-line-item",
                                      "",
                                      "",
                                      { ...row }
                                    )
                                  )
                                }
                              >
                                <FontAwesomeIcon
                                  color={colors.link}
                                  size="xs"
                                  icon={faPen}
                                />
                              </IconButton>
                            </Tooltip> 
                            }
                          </Grid>
                      )
                    }))}
                    select
                    isSelected={isSelected}
                    handleSelect={handleSelect}
                    handleSelectAllClick={handleSelectAllClick}
                    allSelected={allSelected}
                    form
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    values={values}
                    allowEmpty
                  />
                </Grid>
                <Grid item style={{ width: "100%" }}>
                  <Grid container item direction="row-reverse">
                    <Button
                      size="small"
                      color="primary"
                      variant="outlined"
                      onClick={(): void => {
                        handleSubmit();
                        clearSelected();
                      }}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          )}
        </Formik>
        {dialog.variant === "funding-request-open-line-item" && (
          <LineItemDialog
            open={dialog.show}
            status={status}
            lineItemForm={{
              enableReinitialize: true,
              initialValues: {
                amountRequested: get(dialog, ["data", "amount"]) / 100,
                discountRate: get(dialog, ["data", "discount_rate"]),
                againstWho: get(dialog, ["data", "category_applied_against"]),
                category: get(dialog, ["data", "lineItem"]),
                description: get(dialog, ["data", "description"]),
                approve: get(dialog, ["data", "approved"]),
                billWithFundingRequest: get(dialog, [
                  "data",
                  "lineItem",
                  "bill_with_funding_request"
                ]),
                createdBy: get(dialog, ["data", "changed_by.id"]),
              },
              onSubmit: async (values: any, { setSubmitting }: any) => {
                try {
                  await dispatch(
                    updateLineItem({
                      id: get(dialog, ["data", "relatedLineItem"]),
                      amount_requested: Math.round(
                        values.amountRequested * 100
                      ),
                      category: values.category,
                      description: values.description,
                      discount_rate: values.discountRate,
                      applied_against: values.againstWho,
                      bill_with_funding_request: values.billWithFundingRequest,
                      approval_status: values.approved
                    })
                  );
                  dispatch(openSnackbar("success", "Line Item Modified!"));
                } catch (err) {
                  dispatch(openSnackbar("error", err));
                } finally {
                  setSubmitting(false);
                }
              }
            }}
            categories={lineItemCategories}
            handleClose={() => dispatch(closeDialog())}
            type={get(dialog, ["data", "type"])}
          />
        )}
      </>
    );
  }
);

export default ChargebackGivebackCard;
