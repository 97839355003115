import React, { ChangeEventHandler } from "react";
import { Warning } from "@material-ui/icons";

import PenniesInput from "../../../../../components/inputs/PenniesInput";
import AvatarCard from "../../../../../components/AvatarCard";

interface Props {
  values: {
    creditLimit: string;
  };
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  handleBlur: ChangeEventHandler<HTMLInputElement>;
}

const CreditLimitCard = ({
  values,
  setFieldValue,
  handleBlur
}: Props): JSX.Element => (
  <AvatarCard
    title="Credit Limit"
    color="#f5a623"
    icon={<Warning style={{ fontSize: "32px" }} />}
    subheader={
      <PenniesInput
        name="creditLimit"
        id="creditLimit"
        fullWidth
        value={values.creditLimit}
        setFieldValue={setFieldValue}
        onBlur={handleBlur}
        disableUnderline
        withStyle
      />
    }
  />
);

export default CreditLimitCard;
