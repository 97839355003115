import { Grid, IconButton, List, Tooltip, Typography } from "@material-ui/core";
import React from "react";
import { AccountCircle } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { get, omit, isNull } from "lodash";

import Can from "../../../../components/Can";
import Link from "../../../../components/Link";
import BoldInput from "../../../../components/inputs/BoldInput";
import ListItem from "../../../../components/ListItem";
import Card from "../../../../components/Card";
import formatStatus from "../../../../helpers/format/formatStatus";
import formatAddress from "../../../../helpers/format/formatAddress";
import formatPhone from "../../../../helpers/format/formatPhone";
import getPhone from "../../../../helpers/getPhone";
import getExtension from "../../../../helpers/getExtension";
import { getFactoringProfileById } from "../../../../modules/factoring";
import maskEIN from "../../../../helpers/format/maskEIN";
import ProfileSection from "../../../../components/ProfileSection";

interface Props {
  id: string;
}

function CarrierInfoCard({ id }: Props): JSX.Element {
  const client = useSelector(getFactoringProfileById(id));
  return (
    <Card>
      <List dense>
        <ListItem>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <ProfileSection>Factoring Client Info</ProfileSection>
            </Grid>
            <Grid item>
              <Tooltip title="View Profile">
                <Link to={`/admin/factorclients/${id}/profile`}>
                  <IconButton color="primary">
                    <AccountCircle />
                  </IconButton>
                </Link>
              </Tooltip>
            </Grid>
          </Grid>
        </ListItem>
        <ListItem>
          <BoldInput
            fullWidth
            label="Name"
            value={get(client, ["company_profile", "name"], "")}
            disabled
          />
        </ListItem>
        <Can perform="admin-factorclient-profile-phonenumber:view" yes={() => (
          <ListItem>
            <BoldInput
              fullWidth
              label="Phone"
              value={`${formatPhone(
                getPhone(get(client, ["owner_profile", "phone_number_cell"]))
              )}${
                getExtension(get(client, ["owner_profile", "phone_number_cell"]))
                  ? `;${getExtension(
                  get(client, ["owner_profile", "phone_number_cell"])
                  )}`
                  : ""
              }`}
              disabled
            />
          </ListItem>
        )} />
        <ListItem>
          <BoldInput
            fullWidth
            label="Email"
            value={get(client, ["owner_profile", "email"], "")}
            disabled
          />
        </ListItem>
        <ListItem>
          <BoldInput
            fullWidth
            label="MC"
            value={get(client, ["company_profile", "mc"], "")}
            disabled
          />
        </ListItem>
        <ListItem>
          <BoldInput
            fullWidth
            label="DOT"
            value={get(client, ["company_profile", "dot"], "")}
            disabled
          />
        </ListItem>
        <Can perform="taxid:view" yes={() => (
          <ListItem>
            <BoldInput
              fullWidth
              label="TAX ID"
              value={(get(client, ["company_profile", "tax_id"], "") || "").includes("tok") ? maskEIN(get(client, ["company_profile", "tax_id"], "")) : get(client, ["company_profile", "tax_id"], "")}
              disabled
            />
          </ListItem>
        )} />
        <ListItem>
          <BoldInput
            fullWidth
            label="Factoring Status"
            value={formatStatus(get(client, ["factoring_approved"], ""))}
            disabled
          />
        </ListItem>
        <Can perform="admin-factorclient-profile-address:view" yes={() => (
          <ListItem>
            <BoldInput
              fullWidth
              label="Address"
              value={formatAddress(
                get(client, ["company_profile", "address"], {})
              )}
              disabled
            />
          </ListItem>
        )} />
        <ListItem>
          <BoldInput
            fullWidth
            label="Contact"
            value={`${get(client, ["owner_profile", "name_first"], "")} ${get(
              client,
              ["owner_profile", "name_last"],
              ""
            )}`}
            disabled
          />
        </ListItem>
        <ListItem>
          <BoldInput
            fullWidth
            label="Phone Number"
            value={`${formatPhone(get(client, ["owner_profile", "phone_number_cell"], ""))}`}
            disabled
          />
        </ListItem>
      </List>
    </Card>
  );
}

export default CarrierInfoCard;
