const statuses: { [index: string]: string } = {
  declined_3_months: "Declined 3 Months",
  declined_6_months: "Declined 6 Months",
  open: "Open",
  remote_approved: "Remote Approved",
  new: "New",
  pending: "Pending",
  paid: "Paid",
  approved: "Approved",
  new_noa: "New NOA",
  fuel_advance: "Fuel Advance",
  pending_noa: "Pending NOA",
  client_settled: "Client Settled",
  declined: "Declined",
  special_pending: "Special Pending",
  document_issue: "Document Issue",
  pending_originals: "Pending Originals",
  document_review: "Document Review",
  incomplete: "Incomplete",
  lead_in: "Lead In",
  over_paid: "Over Paid",
  short_paid: "Short Paid",
  promise_to_pay: "Promise To Pay",
  debtor_issue: "Debtor Issue",
  internal_cf: "Internal CF",
  resubmit_invoice: "Resubmit Invoice",
  verify_duplicate: "Verify Duplicate",
  in_process: "In Process",
  unpaid: "Unpaid",
  void: "Void",
  expired: "Expired",
  printed: "Printed",
  mailed: "Mailed",
  failed: "Failed",
  returned: "Returned",
  requested: "Requested",
  pending_for_time_batch: "Pending For Time Batch",
  bank_transfer_requested: "Bank Transfer Requested",
  bank_approved: "Bank Approved",
  account_credited: "Account Credited",
  payment_reversed: "Payment Reversed",
  fuel_advance_fee: "Fuel Advance Fee",
  fuel_advance_discount: "Fuel Advance Discount",
  delivery_advance: "Delivery Advance",
  charges: "Charges",
  needs_review: "Needs Review",
  broker_invited: "Broker Invited",
  CHARGESANDGIVESBACK: "Givebacks/Chargesbacks",
  bad_lead: "Bad Lead",
  haulpay_review: "HaulPay Review",
  contract_out: "Contract Out",
  deleted: "Deleted",
  dead: "Dead",
  talked_to_customer: "Talked to Customer",
  "client settled": "Client Settled",
  rebatched: "Rebatched",
  "new noa": "New NOA",
  not_authorized: "Not Authorized",
  write_off: "Write Off",
  non_factored: "Non-Factored",
  pending_delivery: "Pending Delivery",
  document_issue_a: "Document Issue A",
  document_issue_b: "Document Issue B",
  document_issue_c: "Document Issue C",
  payment_issue: "Payment Issue",
  follow_up: " Follow Up",
  pending_claim: "Pending Claim",
  non_factored_open: "Non Factored Open",
  non_factored_short_paid: "Non Factored Short Paid",
  non_factored_over_paid: "Non Factored Over Paid",
  no_response: "No Response",
  closed: "Closed",
};

export default (status: string): string => statuses[status] || status;
