import React from 'react';
import formatDollars from '../../../../../helpers/format/formatDollars';
import formatDate from '../../../../../helpers/format/formatDate';

const columns = [
    {
        key: 'description',
        name: 'Reason',
    },
    {
        key: 'total_amount',
        name: 'Amount',
        format: formatDollars,
    },
    {
        key: 'payments_due_date',
        name: 'Due Date',
        format: formatDate,
    },
];

export default columns;
