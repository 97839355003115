import axios, { AxiosRequestConfig } from "axios";
import qs from "qs";
import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import { get } from "lodash";
import omitEmptyKeys from "../../helpers/omitEmptyKeys";
import deleteCommaKeys from "../../helpers/deleteCommaKeys";

import getFundingRequest from "./getFundingRequest";
import paymentProfile from "./paymentProfile";
import tms from "./TMS";
import reasonForLeaving from "./reasonForLeaving";
import referralSource from "./referralSource";
import contracts from "./contracts";
import deposit from "./deposit";
import ledger from "./ledger";

const postBrokerLoad = async (data: string): Promise<any> => {
  const url = `/admin/load/broker/`;
  const options: AxiosRequestConfig = {
    url,
    method: "POST",
    data
  };
  const response = await axios(options);
  return response.data;
};

const postBrokerFR = async (
  data: string,
  nonFactored: boolean
): Promise<any> => {
  const url = !nonFactored
    ? `/admin/factoring/funding_request/broker/`
    : `/factoring/funding/request/non_factored/`;
  const options: AxiosRequestConfig = {
    url,
    method: "POST",
    data
  };
  const response = await axios(options);
  return response.data;
};

const getAttachmentFromProfile = async (id: string): Promise<any> => {
  const url = `/factoring/${id}/attachments/`;
  const options: AxiosRequestConfig = {
    url,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

const checkPayments = async (value: string): Promise<any> => {
  const url = `/payments/?limit=1&sch_exact=${value}`;
  const options: AxiosRequestConfig = {
    url,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

const getPaymentProfile = async (id: string): Promise<any> => {
  const host = process.env.REACT_APP_VGS_HOST;
  const url = `${host}/factoring/payment_profiles/${id}/`;
  const options: AxiosRequestConfig = {
    url,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

const deleteAttachmentFromProfile = async (
  frId: string,
  attachmentId: string
): Promise<any> => {
  const url = `/factoring/${frId}/attachments/${attachmentId}/`;
  const options: AxiosRequestConfig = {
    url,
    method: "DELETE"
  };
  const response = await axios(options);
  return response.data;
};

const addAttachementToProfile = ({
  id,
  filename,
  category = ""
}: {
  id: string;
  filename: string;
  category?: string;
}): Promise<any> => {
  const url = `/factoring/${id}/attachments/`;
  const options: AxiosRequestConfig = {
    url,
    method: "POST",
    data: {
      filename,
      category
    }
  };
  return axios(options);
};

const updateAttachmentCategory = (
  factoringId: string,
  attachmentId: string,
  category: string
): Promise<any> => {
  const url = `/factoring/${factoringId}/attachments/${attachmentId}/`;
  const options: AxiosRequestConfig = {
    url,
    method: "PATCH",
    data: {
      category
    }
  };
  return axios(options);
};

export const updateAttachment = (
  factoringId: string,
  attachmentId: string,
  data: Record<string, any>
): Promise<any> => {
  const url = `/funding_requests/${factoringId}/attachments/${attachmentId}/`;
  const options: AxiosRequestConfig = {
    url,
    method: "PATCH",
    data
  };
  return axios(options);
};

export const updateFundingRequestAttachment = (
  factoringId: string,
  attachmentId: string,
  data: Record<string, any>
): Promise<any> => {
  const url = `/factoring/funding/request/${factoringId}/attachments/${attachmentId}/`;
  const options: AxiosRequestConfig = {
    url,
    method: "PATCH",
    data
  };
  return axios(options);
};

export const addFundingRequestAttachment = ({
  id,
  filename,
  category = ""
}: {
  id: string;
  filename: string;
  category?: string;
}): Promise<any> => {
  const url = `/factoring/funding/request/${id}/attachments/`;
  const options: AxiosRequestConfig = {
    url,
    method: "POST",
    data: {
      attachments: [
        {
          filename,
          category
        }
      ]
    }
  };
  return axios(options);
};

const updateProfileAttachmentCategory = (
  factoringId: string,
  attachmentId: string,
  category: string
): Promise<any> => {
  const url = `/factoring/payment_profiles/${factoringId}/attachments/${attachmentId}/`;
  const options: AxiosRequestConfig = {
    url,
    method: "PATCH",
    data: {
      category
    }
  };
  return axios(options);
};

const deletePaymentProfileAttachment = (
  paymentId: string,
  attachmentId: string
): Promise<any> => {
  const url = `/factoring/payment_profiles/${paymentId}/attachments/${attachmentId}/`;
  const options: AxiosRequestConfig = {
    url,
    method: "DELETE"
  };
  return axios(options);
};

const reversalLineItems = async (
  itemId: string,
  fundingRequestId: string,
  description: string,
  amount: number,
): Promise<any> => {
  const url = `/funding_requests/${fundingRequestId}/items/${itemId}/?adjustment=True`;
  const options: AxiosRequestConfig = {
    url,
    method: "PATCH",
    data: {
      description,
      amount_requested: amount
    }
  };
  const response = await axios(options);
  return response.data;
};

const createSelfFactoringClient = async (
  user: Record<string, any>
): Promise<any> => {
  const host = process.env.REACT_APP_VGS_HOST;
  const options: AxiosRequestConfig = {
    url: `${host}/factoring/`,
    method: "POST",
    data: user
  };
  const response = await axios(options);
  return response.data;
};

const updateConnectedFactoringProfile = async (
  factoringId: string,
  paymentProfileId: string,
  data: Record<string, any>
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/factoring/${factoringId}/payment_profiles/${paymentProfileId}/`,
    method: "PATCH",
    data
  };
  const response = await axios(options);
  return response.data;
};

const updatePaymentProfile = async (
  id: string,
  data: Record<string, any>
): Promise<any> => {
  const host = process.env.REACT_APP_VGS_HOST;
  const url = `${host}/factoring/payment_profiles/${id}/`;
  const options: AxiosRequestConfig = {
    url,
    method: "PATCH",
    data
  };
  const response = await axios(options);
  return response.data;
};

const updateFactoringClient = async (
  user: Record<string, any>
): Promise<any> => {
  const host = process.env.REACT_APP_VGS_HOST;
  const options: AxiosRequestConfig = {
    url: `${host}/factoring/${user.factoring_id}/`,
    method: "PATCH",
    data: user
  };
  const response = await axios(options);
  return response.data;
};

const createTransactionNote = async (
  fundingRequestId: string,
  note: string,
  attachment: any
): Promise<any> => {
  let attachments;
  if (attachment && attachment !== [] && attachment !== "") {
    attachments = attachment;
  }
  const options: AxiosRequestConfig = {
    url: `/funding_requests/${fundingRequestId}/notes/`,
    method: "POST",
    data: {
      note,
      attachments
    }
  };
  const response = await axios(options);
  return response.data;
};

const getTransactionNotes = async (id: string): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/funding_requests/${id}/notes/`,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

const updateTransactionNote = async (
  fundingRequestId: string,
  data: Record<string, any>
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/funding_requests/${fundingRequestId}/notes/${data.id}/`,
    method: "PATCH",
    data
  };
  const response = await axios(options);
  return response.data;
};

const deleteProfile = async (id: string): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/factoring/${id}/`,
    method: "DELETE"
  };
  const response = await axios(options);
  return response.data;
};

const getProfile = async (id: string): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/factoring/${id}/`,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

const updateProfile = async (
  id: string,
  update: Record<string, any>
): Promise<any> => {
  const host = process.env.REACT_APP_VGS_HOST;
  const options: AxiosRequestConfig = {
    url: `${host}/factoring/${id}/`,
    method: "PATCH",
    data: update
  };
  const response = await axios(options);
  return response.data;
};

const queryFundingRequests = async (
  status: string,
  ordering: string,
  limit: number,
  offset: number,
  filters: Record<string, string>
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/factoring/funding/request/`,
    method: "GET",
    params: {
      status,
      ordering,
      offset,
      limit,
      line_item_stats: true,
      client_reserve_stats: true,
      ...deleteCommaKeys(omitEmptyKeys(filters))
    }
  };
  const response = await axios(options);
  return response.data;
};

const queryFundingRequestsExport = async (
  filters: Record<string, string>
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/factoring/funding/request/export/`,
    method: "GET",
    params: {
      ...deleteCommaKeys(omitEmptyKeys(filters))
    }
  };
  const response = await axios(options);
  return response.data;
};

const deleteFundingRequest = async ({ id }: { id: string }): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/factoring/funding/request/${id}/`,
    method: "delete"
  };
  const response = await axios(options);
  return response.data;
};

const queryFundingRequestsByFactoringId = async (
  factoringId: string,
  ordering: string,
  limit: number,
  offset: number,
  filters: Record<string, string>
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/factoring/funding/request/`,
    method: "GET",
    params: {
      factoring_id: factoringId,
      ordering,
      offset,
      limit,
      line_item_stats: true,
      client_reserve_stats: true,
      ...deleteCommaKeys(omitEmptyKeys(filters))
    }
  };
  const response = await axios(options);
  return response.data;
};

const updateFundingRequest = async (
  data: Record<string, any>,
  params: Record<string, any>
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/factoring/funding/request/${data.id}/`,
    method: "PATCH",
    data,
    params: {
      details: "extended",
      ...params
    }
  };
  const response = await axios(options);
  return response.data;
};

const getLineItems = async (id: string): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/funding_requests/${id}/items/`,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

const createLineItem = async (
  fundingRequestId: string,
  data: Record<string, any>
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/funding_requests/${fundingRequestId}/items/`,
    method: "POST",
    data
  };
  const response = await axios(options);
  return response.data;
};

const updateLineItem = async (
  fundingRequestId: string,
  data: Record<string, any>
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/funding_requests/${fundingRequestId}/items/${data.id}/`,
    method: "PATCH",
    data
  };
  const response = await axios(options);
  return response.data;
};

const deleteLineItem = async (
  fundingRequestId: string,
  lineItemId: string
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/funding_requests/${fundingRequestId}/items/${lineItemId}/`,
    method: "DELETE"
  };
  const response = await axios(options);
  return response.data;
};

const getLineItemCategories = async (params?: object): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/funding_requests/line_items/categories/`,
    method: "GET",
    params
  };
  const response = await axios(options);
  return response.data;
};

const getReceivables = async (id: string): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/funding_requests/${id}/receivables/`,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

const getFundingRequestReceivables = async (
  ordering: string,
  limit: number,
  filters: Record<string, string>,
  factoringId: string
): Promise<any> => {
  const url = factoringId
    ? `/factoring/${factoringId}/receivables/`
    : `/funding_requests/receivables/`;
  const options: AxiosRequestConfig = {
    url,
    method: "GET",
    params: {
      ordering,
      limit,
      ...deleteCommaKeys(omitEmptyKeys(filters))
    },
    paramsSerializer: (params) => qs.stringify(params, { encode: false }),
  };
  const response = await axios(options);
  return response.data;
};

const getFundingRequestReceivable = async (id: string): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/funding_requests/receivables/${id}/`,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

const getPayment = async (id: string): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/payments/${id}/`,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

const getFundingRequestReceivablesExport = async (
  filters: Record<string, string>,
  ids: string[]
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/funding_requests/receivables/export/`,
    method: "POST",
    data: {
      ...deleteCommaKeys(omitEmptyKeys(filters)),
      ...(() => (!isEmpty(ids) ? { id: ids } : {}))()
    }
  };
  const response = await axios(options);
  return response.data;
};

const getPurchases = async (id: string): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/funding_requests/${id}/purchases/`,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

export const addPurchase = async (
  fundingRequest: string,
  data: Record<string, any>
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/funding_requests/${fundingRequest}/purchases/`,
    method: "POST",
    data
  };
  const response = await axios(options);
  return response.data;
};

const getDebtorRelation = async (
  debtorId: string,
  userFactoringId: string
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/factoring/debtors/relationship/${debtorId}/retrieve/`,
    method: "GET",
    params: {
      factoring_id: userFactoringId
    }
  };
  const response = await axios(options);
  return response.data;
};

const deleteDebtorRelationship = async (
  debtorId: string,
  userFactoringId: string
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/debtor/${debtorId}/clients/`,
    method: "DELETE",
    data: {
      client: userFactoringId
    }
  };
  const response = await axios(options);
  return response.data;
};

const report = async (
  factoringId: string,
  data: Record<string, any>
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/factoring/${factoringId}/report/`,
    method: "POST",
    data
  };
  const response = await axios(options);
  return response.data;
};

const debtorReport = async (data: Record<string, any>): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `funding_requests/reports/broker/`,
    method: "POST",
    data
  };
  const response = await axios(options);
  return response.data;
};

const getDebtorRelationHistory = async (
  relationshipId: string
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/factoring/debtors/relationship/${relationshipId}/history/`,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

const getConnectedProfiles = async (
  factoringClientId: string
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/factoring/profiles/connected/`,
    method: "GET",
    params: {
      factoring_client_id: factoringClientId
    }
  };
  const response = await axios(options);
  return response.data;
};

const getConnectedCarriers = async (
  factoringId: string,
  ordering: string,
  limit: number,
  filters: Record<string, string>
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/factoring/${factoringId}/payment_profiles/`,
    method: "GET",
    params: {
      ordering,
      limit,
      ...deleteCommaKeys(omitEmptyKeys(filters))
    }
  };
  const response = await axios(options);
  return response.data;
};

const updateNOADates = async (
  debtor: string,
  factoring: string,
  noaActive: string,
  noaPlaced: string
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/admin/factoring/debtors/`,
    method: "PATCH",
    data: {
      debtor,
      factoring,
      noa_active: noaActive,
      noa_placed: noaPlaced
    }
  };
  const response = await axios(options);
  return response.data;
};

const getCollatedPdf = async ({
  token = null
}: {
  token: null | string;
}): Promise<any> => {
  const url = token
    ? `/factoring/generate_collated_pdf/?verify=${token}`
    : `/factoring/generate_collated_pdf/`;
  const options: AxiosRequestConfig = {
    url,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

const linkRelatedLineItems = async (
  fundingRequestId: string,
  data: Record<string, any>
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/funding_requests/${fundingRequestId}/items/link_related/`,
    method: "POST",
    data
  };
  const response = await axios(options);
  return response.data;
};

const getFactoringClients = async (
  status: string,
  ordering: string,
  limit: number,
  offset: number,
  filters: Record<string, string>
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/admin/factoring/applications/`,
    method: "GET",
    params: {
      status,
      ordering,
      limit,
      offset,
      ...deleteCommaKeys(omitEmptyKeys(filters))
    }
  };
  const response = await axios(options);
  return response.data;
};

const getFactoringClientsExport = async (
  filters: Record<string, string>
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/admin/factoring/export/`,
    method: "GET",
    params: {
      ...deleteCommaKeys(omitEmptyKeys(filters))
    }
  };
  const response = await axios(options);
  return response.data;
};

const sendNOA = async (
  debtorId: string,
  factoringId: string,
  fundingRequest: any,
  attachmentFileName: string
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/factoring/debtors/relationship/noa/`,
    method: "POST",
    data: {
      debtor: debtorId,
      factoring: factoringId,
      funding_request: fundingRequest,
      attachment: attachmentFileName
    }
  };
  const response = await axios(options);
  return response.data;
};

const getNotesTimeline = async (factoringId: string): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/admin/factoring/notes_timeline/`,
    method: "GET",
    params: {
      factoring_id: factoringId,
      limit: 50
    }
  };
  const response = await axios(options);
  return response.data;
};

const getInvoice = async (factoringId: string): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/factoring/funding/request/${factoringId}/files/?document=invoice`,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

const getCarrierInvoice = async (factoringId: string): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/factoring/funding/request/${factoringId}/files/?document=invoice_payment_profile`,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

const getCollated = async (factoringId: string): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/factoring/funding/request/${factoringId}/files/?document=collated`,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

const createFactoringClientNote = async (
  factoringId: string,
  note: string,
  attachments: any
): Promise<any> => {
  const data = new FormData();
  if (
    attachments &&
    attachments !== [] &&
    attachments !== "" &&
    attachments.length !== 0
  ) {
    data.append("attachments", attachments);
  }
  data.append("note", note);
  data.append("factoring", factoringId);
  const options: AxiosRequestConfig = {
    url: `/admin/factoring/notes/`,
    method: "POST",
    data
  };
  const response = await axios(options);
  return response.data;
};

const getFactoringClientNoteAttachment = async (
  noteId: string,
  attachment: any
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/admin/factoring/notes/${noteId}/`,
    method: "get",
    params: {
      attachment
    },
    paramsSerializer: (params: any): string => `attachment=${params.attachment}`
  };
  const response = await axios(options);
  return response.data;
};

const updateFactoringClientNote = async (
  factoringId: string,
  noteId: string,
  note: string,
  attachments: any
): Promise<any> => {
  const data = new FormData();
  if (
    attachments &&
    attachments !== [] &&
    attachments !== "" &&
    attachments.length !== 0
  ) {
    data.append("attachments", attachments);
  }
  data.append("note", note);
  data.append("factoring", factoringId);
  const options: AxiosRequestConfig = {
    url: `/admin/factoring/notes/${noteId}/`,
    method: "PATCH",
    data
  };
  const response = await axios(options);
  return response.data;
};

const deleteFactoringClientNote = async (noteId: string): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/admin/factoring/notes/${noteId}/`,
    method: "DELETE"
  };
  const response = await axios(options);
  return response.data;
};

const getReceivableNotes = async (receivableId: string): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/funding_requests/receivables/${receivableId}/notes/`,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

const createReceivableNote = async (
  receivableId: string,
  note: string = "",
  followupDate: string,
  responseCategory: any
): Promise<any> => {
  const followup_date = followupDate.trim();
  const options: AxiosRequestConfig = {
    url: `/funding_requests/receivables/${receivableId}/notes/`,
    method: "POST",
    data: {
      note,
      response_category:
        responseCategory.length > 0 ? responseCategory : undefined,
      followup_date: followup_date.length > 0 ? followup_date : undefined
    }
  };
  const response = await axios(options);
  return response.data;
};

const updateReceivableNote = async (
  receivableId: string,
  noteId: string,
  note: string
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/funding_requests/receivables/${receivableId}/notes/${noteId}/`,
    method: "PATCH",
    data: {
      note
    }
  };
  const response = await axios(options);
  return response.data;
};

const deleteReceivableNote = async (
  receivableId: string,
  noteId: string
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/funding_requests/receivables/${receivableId}/notes/${noteId}/`,
    method: "DELETE"
  };
  const response = await axios(options);
  return response.data;
};

const createReceivableFollowup = async (
  debtorId: string,
  fundingRequestIds: string[],
  followupDate: string,
  responseCategory: any
): Promise<any> => {
  const followup_date = followupDate.trim();
  const options: AxiosRequestConfig = {
    url: `/payments/debtor/${debtorId}/funding_requests/`,
    method: "POST",
    data: {
      funding_requests: fundingRequestIds.map(id => ({
        id,
        amount_applied: 0
      })),
      response_category:
        responseCategory.length > 0 ? responseCategory : undefined,
      followup_date: followup_date.length > 0 ? followup_date : undefined
    }
  };
  const response = await axios(options);
  return response.data;
};

const createFundingRequest = async (
  amount: any,
  attachments: any,
  bill_to_company_email: string,
  bill_to_company_phone: string,
  debtorId: string,
  factoringId: string,
  final_destination_location: any,
  first_origin_location: any,
  load_length: any,
  load_trailer_type: any,
  user_load_number: any,
  paymentMethod: string,
  nonFactored: boolean = false,
  clientNote: string = ""
) => {
  let url = "";
  url = nonFactored
    ? `factoring/funding/request/non_factored/`
    : `/factoring/funding/request/`;
  const options: AxiosRequestConfig = {
    url,
    method: "POST",
    data: {
      amount,
      attachments: attachments.map(({ file, base64, ...rest }: any) => rest),
      bill_to_company_email,
      bill_to_company_phone,
      debtor: debtorId,
      factoring: factoringId,
      final_destination_location,
      first_origin_location,
      load_length,
      load_trailer_type,
      user_load_number,
      payment_method: paymentMethod,
      factored: !nonFactored,
      user_notes: clientNote,
      contract_type: nonFactored ? 'NON_FACTORED' : 'STD_CARRIER',
    }
  };
  const response = await axios(options);
  return response.data;
};

const getProfileHistory = async (factoringId: string): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/factoring/profiles/${factoringId}/history/`,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

const getLineItemHistory = async (
  fundingRequestId: string,
  lineItemId: string
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/funding_requests/${fundingRequestId}/items/${lineItemId}/history/`,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

const getClientStats = async (factoringId: string): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/admin/factoring/${factoringId}/stats/`,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

const deleteTransactionNote = async (
  fundingRequestId: string,
  noteId: string
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/funding_requests/${fundingRequestId}/notes/${noteId}/`,
    method: "DELETE"
  };
  const response = await axios(options);
  return response.data;
};

const deleteFactoringAttachment = async (
  fundingRequestId: string,
  attachmentId: string
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/factoring/funding/request/${fundingRequestId}/`,
    method: "DELETE",
    params: {
      file_id: attachmentId
    }
  };
  const response = await axios(options);
  return response.data;
};

const getCollatedAttachment = async (id: string): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/factoring/${id}/attachments/collated/`,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

const getTerms = async (id: string): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/factoring/${id}/terms/`,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

const getPaymentTerms = async (id: string): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/factoring/${id}/payment_terms/`,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

const updatePaymentTerms = async (
  factoringId: string,
  paymentTermId: string,
  paymentTerm: any,
  params: Record<string, string>,
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/factoring/${factoringId}/payment_terms/${paymentTermId}/`,
    method: "PATCH",
    data: paymentTerm,
    params
  };
  const response = await axios(options);
  return response.data;
};

const updateTerms = async (
  factoringId: string,
  termId: string,
  term: any
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/factoring/${factoringId}/terms/${termId}/`,
    method: "PATCH",
    data: term
  };
  const response = await axios(options);
  return response.data;
};

const transferDocument = async (id: string): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/factoring/${id}/send_transfer_packet/`,
    method: "POST"
  };
  const response = await axios(options);
  return response.data;
};

const getFreightClasses = async (): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/funding_requests/freight_classes/`,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

const getCommodities = async (): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/funding_requests/commodities/`,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

const getPaymentProfileAttachment = (
  paymentProfileId: string,
  attachmentId: string
): Promise<any> => {
  const url = `/factoring/payment_profiles/${paymentProfileId}/attachments/${attachmentId}/`;
  const options: AxiosRequestConfig = {
    url,
    method: "GET"
  };
  return axios(options);
};

const sendDocumentIssueReminder = async (id: string): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/factoring/funding/request/document_issue_reminder/`,
    method: "POST",
    data: {
      id
    }
  };
  const response = await axios(options);
  return response.data;
};

export const sendBankLink = async (id: string, source: string): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/users/${id}/resend_plaid/`,
    method: "POST",
    data: {
      target: source
    }
  };
  const res = await axios(options);
  return res.data;
};

export const sendNoaBlast = async (factoryClientId: string): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/factoring/${factoryClientId}/blast_noa/`,
    method: "POST"
  };
  const res = await axios(options);
  return res.data;
};


export const sendEmailTemplate = async (
  emailId: string,
  receivableIds: []
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/admin/debtor/email_template/${emailId}/`,
    method: "PUT",
    data: {
      receivable_ids: receivableIds
    }
  };
  const res = await axios(options);
  return res.data;
};

export const sendFREmailTemplate = async (
  emailId: string,
  id: string,
  email: string,
  ccEmails: []
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/admin/funding_request/${id}/email_template/${emailId}/`,
    method: "PUT",
    data: {
      cc_emails: ccEmails,
      email
    }
  };
  const res = await axios(options);
  return res.data;
};

export const getEmailTemplates = async (id: string): Promise<any> => {
  const url = id
    ? `/admin/debtor/${id}/email_template/`
    : `/admin/debtor/email_template/`;
  const options: AxiosRequestConfig = {
    url,
    method: "GET"
  };
  const res = await axios(options);
  return res.data;
};

export const getFREmailTemplates = async (id: string): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/admin/funding_request/${id}/email_template/`,
    method: "GET"
  };
  const res = await axios(options);
  return res.data;
};

export const searchFactoringDebtors = async (
  params: Record<string, any>
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/factoring/debtors/`,
    method: "GET",
    params
  };
  const res = await axios(options);
  return res.data;
};

const queryFetchNOA = async (
  ordering: string,
  limit: number,
  offset: number,
  filters: Record<string, string>
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/factoring/relationships/`,
    method: "GET",
    params: {
      ordering,
      offset,
      limit,
      ...deleteCommaKeys(omitEmptyKeys(filters))
    }
  };
  const response = await axios(options);
  return response.data;
};

const fundingRequestCalculations = async (data: any): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/funding_requests/calculations/`,
    method: "POST",
    data
  };
  const response = await axios(options);
  return response.data;
};

const sendReminderText = async (data: any): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/admin/funding_request/${data.id}/reminder_text/`,
    method: "PUT",
    data
  };
  const response = await axios(options);
  return response.data;
};

const exportPayment = async (data: any, params: any): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/payments/export/`,
    method: "POST",
    data,
    params
  };
  const response = await axios(options);
  return response.data;
};

const createInvoiceDebtor = async (data: any): Promise<any> => {
  const params = get(data, "filters", { response_type: "debtor_receivables" });
  const options: AxiosRequestConfig = {
    url: `/debtor/${data.debtor_id}/funding_requests/payment_forward/`,
    method: "POST",
    data: omit(data, ["debtor_id", "filters"]),
    params
  };
  const response = await axios(options);
  return response.data;
};

const resubmitFundingRequest = async (
  fundingRequest: string,
  fundingRequestPurchase: string
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/funding_requests/${fundingRequest}/repurchase/`,
    method: "POST",
    data: {
      funding_request_purchase: fundingRequestPurchase
    }
  };
  const response = await axios(options);
  return response.data;
};

const BulkFundingRequestNotes = async (
  fundingRequestIds: [],
  note: string
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/funding_requests/bulk_notes/`,
    method: "POST",
    data: {
      funding_request_ids: fundingRequestIds,
      note
    }
  };
  const response = await axios(options);
  return response.data;
};

const bulkAttachmentsSubmit = async (
  fundingRequests: [],
  attachment: File,
  category: string
): Promise<any> => {
  const bodyFormData = new FormData();
  bodyFormData.append("attachment", attachment);
  bodyFormData.append("category", category);
  fundingRequests.map(ids => bodyFormData.append("funding_request_ids", ids));
  const options: AxiosRequestConfig = {
    url: `/admin/funding_request/bulk_attachments/`,
    method: "POST",
    data: bodyFormData
  };
  const response = await axios(options);
  return response.data;
};

export default {
  tms,
  ...contracts,
  ...reasonForLeaving,
  ...referralSource,
  ...deposit,
  ...ledger,
  sendDocumentIssueReminder,
  getCommodities,
  getFreightClasses,
  getTerms,
  updateTerms,
  getFundingRequest,
  getFundingRequestReceivablesExport,
  getProfile,
  deleteProfile,
  updateProfile,
  updateConnectedFactoringProfile,
  getConnectedProfiles,
  getAttachmentFromProfile,
  addAttachementToProfile,
  deleteAttachmentFromProfile,
  createSelfFactoringClient,
  updateFactoringClient,
  queryFundingRequests,
  queryFundingRequestsByFactoringId,
  updateFundingRequest,
  getLineItems,
  deleteLineItem,
  createLineItem,
  getLineItemCategories,
  getReceivables,
  getPurchases,
  getDebtorRelation,
  getDebtorRelationHistory,
  getCollatedPdf,
  updateNOADates,
  getTransactionNotes,
  updateTransactionNote,
  linkRelatedLineItems,
  getFactoringClients,
  sendNOA,
  getNotesTimeline,
  deleteFundingRequest,
  getFundingRequestReceivables,
  getInvoice,
  getCollated,
  createFactoringClientNote,
  updateFactoringClientNote,
  deleteFactoringClientNote,
  getReceivableNotes,
  createReceivableNote,
  createReceivableFollowup,
  createFundingRequest,
  getProfileHistory,
  getClientStats,
  createTransactionNote,
  deleteTransactionNote,
  deleteFactoringAttachment,
  getCollatedAttachment,
  transferDocument,
  getFactoringClientNoteAttachment,
  updateLineItem,
  report,
  getFactoringClientsExport,
  queryFundingRequestsExport,
  deleteDebtorRelationship,
  getFundingRequestReceivable,
  getPayment,
  getPaymentProfile,
  updatePaymentProfile,
  paymentProfile,
  updateAttachmentCategory,
  updateProfileAttachmentCategory,
  getLineItemHistory,
  postBrokerFR,
  getConnectedCarriers,
  getPaymentTerms,
  updatePaymentTerms,
  addPurchase,
  postBrokerLoad,
  updateReceivableNote,
  deletePaymentProfileAttachment,
  getPaymentProfileAttachment,
  sendBankLink,
  sendNoaBlast,
  searchFactoringDebtors,
  getCarrierInvoice,
  queryFetchNOA,
  getEmailTemplates,
  sendEmailTemplate,
  getFREmailTemplates,
  sendFREmailTemplate,
  fundingRequestCalculations,
  sendReminderText,
  debtorReport,
  checkPayments,
  exportPayment,
  createInvoiceDebtor,
  addFundingRequestAttachment,
  resubmitFundingRequest,
  bulkAttachmentsSubmit,
  updateFundingRequestAttachment,
  updateAttachment,
  deleteReceivableNote,
  BulkFundingRequestNotes,
  reversalLineItems
};
