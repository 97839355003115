import React from "react";
import _ from "lodash";
import { withStyles } from "@material-ui/core/styles";
import InputBase, { InputBaseProps } from "@material-ui/core/InputBase";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import ListSubheader from "@material-ui/core/ListSubheader";
import { DatePicker, DateTimePicker } from "material-ui-pickers";
import { DateTime as LuxonTime } from "luxon";
import {
  Grid,
  InputAdornment,
  MenuItem,
  Select as MuiSelect,
  Switch,
  Theme,
  Typography,
  WithStyles
} from "@material-ui/core";
import validNumber from "../../helpers/validNumber";
import validDecimalNumber from "../../helpers/validDecimalNumber";
import formatCurrency from "../../helpers/format/formatCurrency";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const styles = (theme: Theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap" as "wrap"
  },
  notchedOutline: {},
  bootstrapRoot: {
    "label + &": {
      marginTop: theme.spacing.unit * 3,
      color: "black"
    }
  },
  bootstrapInput: {
    borderRadius: 4,
    position: "relative" as "relative",
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "4px 4px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    },
    "&:disabled": {
      backgroundColor: "#e9e9e9",
      color: "black"
    }
  },
  bootstrapError: {
    // borderRadius: 4,
    // border: "1px solid red"
  },
  select: {
    minWidth: 120
  },
  bootstrapInputDate: {
    borderRadius: 4,
    position: "relative" as "relative",
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "6px 4px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    },
    "&:disabled": {
      backgroundColor: "#e9e9e9",
      color: "black"
    }
  },
  bootstrapFormLabel: {
    fontSize: 20,
    color: "black",
    fontWeight: 900,
    "&:focused": {
      color: "black"
    }
  }
});

const BoldInput = withStyles(styles)((props: any) => {
  const {
    classes,
    label,
    value,
    disabled,
    fullWidth,
    onChange,
    onBlur,
    startAdornment,
    name,
    multiline,
    rows,
    placeholder,
    type,
    error,
    helperText,
    customInputClasses,
    customLabelStyle,
    id
  } = props;
  return (
    <div className={classes.root}>
      <FormControl fullWidth={fullWidth}>
        {label && (
          <InputLabel
            shrink
            className={classes.bootstrapFormLabel}
            style={customLabelStyle}
          >
            {label}
          </InputLabel>
        )}
        <InputBase
          type={type}
          placeholder={placeholder}
          name={name}
          disabled={disabled}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          multiline={multiline}
          rows={rows}
          error={error}
          classes={{
            root: classes.bootstrapRoot,
            input: classes.bootstrapInput,
            error: classes.bootstrapError,
            ...customInputClasses
          }}
          id={id}
          startAdornment={startAdornment}
        />
        {helperText && (
          <FormHelperText error={error}>{helperText}</FormHelperText>
        )}
      </FormControl>
    </div>
  );
});

type InputProps = InputBaseProps & WithStyles<typeof styles>;

export const Input = withStyles(styles)(
  // @ts-ignore
  ({ classes, disabled, value, ...props }: InputProps): JSX.Element => (
    <InputBase
      disabled={disabled}
      value={value}
      classes={{
        root: classes.bootstrapRoot,
        input: classes.bootstrapInput
      }}
      {...props}
    />
  )
);

export const Date = withStyles(styles)(
  (props: any): JSX.Element => {
    const { classes, onChange, value, error, helperText, fullWidth } = props;
    const { label, ...rest } = props;
    return (
      <div className={classes.root}>
        <FormControl fullWidth={fullWidth}>
          {label && (
            <div style={{ marginBottom: 25 }}>
              <InputLabel shrink className={classes.bootstrapFormLabel}>
                {label}
              </InputLabel>
            </div>
          )}
          <DatePicker
            {...rest}
            error={false}
            helperText=""
            value={value}
            onChange={value =>
              onChange ? onChange(value) : rest.setFieldValue(rest.name, value)
            }
            InputProps={{
              disableUnderline: true,
              classes: {
                root: classes.bootstrapRoot,
                input: classes.bootstrapInputDate
              }
            }}
            onlyCalendar
          />
          {helperText && (
            <FormHelperText error={error}>{helperText}</FormHelperText>
          )}
        </FormControl>
      </div>
    );
  }
);

export const DateTime = withStyles(styles)(
  (props: any): JSX.Element => {
    const { classes, value, error, fullWidth, helperText } = props;
    const { label, ...rest } = props;
    return (
      <div className={classes.root}>
        <FormControl fullWidth={fullWidth}>
          {label && (
            <div style={{ marginBottom: 25 }}>
              <InputLabel shrink className={classes.bootstrapFormLabel}>
                {label}
              </InputLabel>
            </div>
          )}
          <DateTimePicker
            {...rest}
            onChange={value => {
              const val = value !== "" ? value : LuxonTime.local();
              rest.setFieldValue(rest.name, val);
            }}
            error={false}
            helperText=""
            value={value}
            InputProps={{
              disableUnderline: true,
              classes: {
                root: classes.bootstrapRoot,
                input: classes.bootstrapInputDate
              }
            }}
            onlyCalendar
          />
          {helperText && (
            <FormHelperText error={error}>{helperText}</FormHelperText>
          )}
        </FormControl>
      </div>
    );
  }
);

interface Selection {
  value: string;
  text: string;
  disabled?: boolean;
}

export const Select = withStyles(styles)(
  ({
    onChange,
    onBlur,
    classes,
    value,
    name,
    label,
    selections = [],
    fullWidth,
    placeholder,
    disabled,
    extraData = <div />,
    selectProps = {},
    helperText,
    error,
    withHeader = false,
  }: any & WithStyles<typeof styles>): JSX.Element => {
    const options = withHeader ? _(selections)
      .groupBy('header')
      .map((options, header) => {
        return ({
          header,
          options
        })
      }).value()
      : selections;
    return (
      <FormControl fullWidth={fullWidth}>
        {label && (
          <InputLabel shrink className={classes.bootstrapFormLabel}>
            {label}
          </InputLabel>
        )}
        <MuiSelect
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          input={<Input name={name} />}
          classes={{
            root: classes.select
          }}
          {...selectProps}
        >
          {withHeader ?
            options.map(({ options, header }: { header: string, options: Array<Selection> }) => {
              const items = options.map(p => {
                return (
                  <MenuItem disabled={p.disabled} key={p.value} value={p.value}>
                    {p.text}
                  </MenuItem>
                );
              });
              return [<ListSubheader>{header}</ListSubheader>, items];
            })
            : options.map(
              (selection: Selection): JSX.Element => (
                <MenuItem disabled={selection.disabled}  value={selection.value}>{selection.text}</MenuItem>
              )
            )}
          {extraData}
        </MuiSelect>
        {helperText && (
          <FormHelperText error={error}>{helperText}</FormHelperText>
        )}
      </FormControl>
    )
  });

export const Pennies = ({
  setFieldValue = () => ({}),
  name,
  label,
  onChangeValue = () => ({}),
  onBlur = () => ({}),
  ...props
}: any): JSX.Element => {
  return (
    <BoldInput
      onChange={(event: any): void => {
        const value = Number(event.target.value);
        if (validNumber(value) || validDecimalNumber(event.target.value)) {
          const str = String(event.target.value);
          if (str.includes(".") && str.split(".")[1].length >= 2) {
            const formattedValue = Number(str).toFixed(2);
            setFieldValue(name, formattedValue);
            onChangeValue(formattedValue);
          } else {
            setFieldValue(name, str);
            onChangeValue(str);
          }
        }
      }}
      onParse={() => { }}
      format={() => { }}
      label={label}
      {...props}
      onBlur={(): void => {
        const value = Number(props.value);
        setFieldValue(name, formatCurrency(value));
        onChangeValue(formatCurrency(value));
        onBlur(formatCurrency(value));
      }}
      startAdornment={
        <InputAdornment position="start">
          <div style={{ color: "black" }}>$</div>
        </InputAdornment>
      }
    />
  );
};

interface CheckboxProps extends WithStyles<typeof styles> {
  value: boolean;
  name?: string;
  label?: string;
  disabled?: boolean;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

export const CheckBox = withStyles(styles)(
  ({
    value,
    name,
    label,
    setFieldValue,
    classes,
    disabled
  }: CheckboxProps): JSX.Element => {
    return (
      <FormControl fullWidth>
        <Grid container alignItems="center">
          {label && (
            <Grid item xs={7}>
              <Typography
                className={classes.bootstrapFormLabel}
                style={{
                  transform: "translate(0, 1.5px) scale(0.75)",
                  transformOrigin: "top left"
                }}
              >
                {label}
              </Typography>
            </Grid>
          )}
          <Grid item xs={5}>
            <Switch
              disabled={disabled}
              checked={value}
              name={name}
              onChange={(): void => {
                if (name) {
                  setFieldValue(name, !value);
                } else {
                  setFieldValue("", !value);
                }
              }}
              value={name}
            />
          </Grid>
        </Grid>
      </FormControl>
    );
  }
);

export default BoldInput;
