import React from "react";
import { Paper, Grid, Tooltip, Chip, Button } from "@material-ui/core";
import { get, head, reverse, find, isEmpty } from "lodash";
import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandshake } from "@fortawesome/free-regular-svg-icons";

import NFBadge from "../../../components/NFBadge";
import Table from "../../../components/Table";
import formatPennies from "../../../helpers/format/formatPennies";
import formatDate from "../../../helpers/format/formatDate";
import NavigationTabs from "../../../components/NavigationTabs";
import InvoiceRow from "../../../components/InvoiceRow";
import TransactionStatus from "../../../components/TransactionStatus";
import Filters from "./components/Filters";
import SetStatus from "./components/SetStatus";
import MoreLink from "./components/MoreLink";
import Can from "../../../components/Can";
import InternalLayout from "../../../components/layouts/InternalLayout";
import Link from "../../../components/Link";
import tabs from "./tabs";
import columns from "./columns";
import colors from "../../../theme/colors";
import TimeZone from "../../../components/TimeZone";
import NegativeMarginIcon from "../../../components/icons/NegativeMarginIcon";
import AddBulkFRNotes from "./components/AddBulkFRNotes";
import SelfFinanceIcon from "../../../components/icons/SelfFinanceIcon";

const NOAStatus = ({ status }) => {
  switch (status) {
    case "noa_placed":
      return <b style={{ color: "green" }}>Placed</b>;
    case "noa_not_sent":
      return <b style={{ color: "orange" }}>Not Sent</b>;
    case "noa_requested":
      return <b style={{ color: "blue" }}>Requested</b>;
    case "noa_sent":
      return <b style={{ color: "gold" }}>Sent</b>;
    default:
      return <b style={{ color: "darkorange" }}>Not Sent</b>;
  }
};

const Transactions = ({
  statusIndex,
  transactions,
  isLoading,
  handleSort,
  sortDirection,
  sortBy,
  count,
  page,
  rowsPerPage,
  handleChangePage,
  rowsPerPageOptions,
  handleChangeRowsPerPage,
  isSelected,
  allSelected,
  handleSelect,
  handleSelectAllClick,
  handleSetStatus,
  handleStatusChange,
  status,
  handleCSV,
  handleSearchFilter,
  filters,
  filterStatus,
  newDocsBadges,
  bulkError,
  selected,
  openDialog,
  closeDialog,
  dialog,
  ...props
}) => {
  const formattedRows = transactions.map((row = {}) => {
    return {
      ...row,
      invoice_amount: (
        <Grid container direction="row" alignItems="center">
          {formatPennies(row.amount)}
          <Grid item>
            <NegativeMarginIcon factoringAmount={row.factoring_amount} />
          </Grid>
        </Grid>
      ),
      debtor_name: (
        <Grid container direction="row" alignItems="center" spacing={8}>
          <Grid item>
            <Link to={`/admin/debtors/${get(row, "debtor.id", "")}/profile`}>
              {get(row, "debtor.dba", "")}
            </Link>
          </Grid>
          <Grid item>
            <TimeZone zone={get(row, "debtor.operating_timezone", "")} />
          </Grid>
        </Grid>
      ),
      noa: <NOAStatus status={row.noa_status} />,
      load_number: (
        <Grid container direction="column">
          <Grid item>{row.user_load_number}</Grid>
          {row.non_factored && (
            <Grid item>
              <NFBadge />
            </Grid>
          )}
        </Grid>
      ),
      client_company_name: (
        <Grid container alignItems="center" spacing={8}>
          <Grid item>
            <Link to={`/admin/fundingrequest/${row.id}`}>
              {get(row, ["factoring", "company_profile", "name"])}
            </Link>
          </Grid>
          {row.payment_profile_id && (
            <Grid item>
              <Tooltip title="Broker-Carrier Funding Request">
                <FontAwesomeIcon color={colors.green} icon={faHandshake} />
              </Tooltip>
            </Grid>
          )}
          <SelfFinanceIcon contractType={row.contract_type} />
        </Grid>
      ),
      amount: formatPennies(row.amount),
      amount_funded: formatPennies(row.amount_funded),
      created: formatDate(row.created),
      invoiceRow: (
        <InvoiceRow
          discountRate={row.discount_rate}
          invoiceNumber={row.invoice_number}
          paymentMethod={row.payment_method}
        />
      ),
      status: (
        <TransactionStatus
          status={row.status}
          modified={row.modified}
          statusTimeline={row.status_timeline}
          newAttachments={get(head(reverse(row.attachments)), "after_issue")}
          nonFactoredType={row.non_factored_type || ""}
          factored={row.factored}
        />
      ),
      more: (
        <Grid container direction="row">
          <MoreLink id={row.id} />
          {/* {row.status === "document_issue" && <SendReminder id={row.id} />} */}
        </Grid>
      )
    };
  });
  return (
    <Can
      perform="admin-transactions:view"
      yes={() => (
        <InternalLayout title="Transactions">
          <NavigationTabs tabs={tabs(newDocsBadges)} value={statusIndex} />
          <Paper style={{ marginTop: "32px" }}>
            <Filters
              handleCSV={() => handleCSV(filters, filterStatus)}
              handleSearchFilter={handleSearchFilter}
              {...props}
            />
            <SetStatus
              status={status}
              statuses={tabs(newDocsBadges)}
              handleSetStatus={handleSetStatus}
              handleStatusChange={handleStatusChange}
            />
            <Grid
              container
              justify="flex-end"
              alignItems="center"
              style={{ paddingRight: "32px" }}
            >
              <Grid item>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: colors.green_dark,
                    color: colors.white
                  }}
                  mini
                  onClick={() => openDialog("bulk_funding_requests_notes")}
                  disabled={isEmpty(selected)}
                >
                  Add Notes
                </Button>
              </Grid>
            </Grid>
            <Table
              columns={columns(filterStatus)}
              rows={formattedRows}
              select
              isLoading={isLoading}
              handleSort={handleSort}
              sortDirection={sortDirection}
              sortBy={sortBy}
              count={count}
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              rowsPerPageOptions={rowsPerPageOptions}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              filter
              filters={filters}
              handleFilterChange={handleSearchFilter}
              isSelected={isSelected}
              handleSelect={handleSelect}
              handleSelectAllClick={handleSelectAllClick}
              allSelected={allSelected}
              allowEmpty
              bulkError={bulkError}
            />
          </Paper>
          {dialog.variant === "bulk_funding_requests_notes" && (
            <AddBulkFRNotes
              show={dialog.show}
              selected={selected}
              handleClose={closeDialog}
            />
          )}
        </InternalLayout>
      )}
      no={() => <Redirect to="/" />}
    />
  );
};

export default Transactions;
