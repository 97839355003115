import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchObligations, getObligationById, getObligations, fetchObligationById } from "../../../../../modules/obligations";
import columns from "./columns";
import Table from "../../../../../components/Table";
import { CardContent, Grid, Typography } from "@material-ui/core";
import ObligationStatus from "./Components/ObligationStatus";
import formatDollars from "../../../../../helpers/format/formatDollars";
import formatDate from "../../../../../helpers/format/formatDate";
import { openSnackbar } from "../../../../../modules/ui";
import api from "../../../../../api";
import nestedPaymentColumn from "./nestedPaymentColumns";
import lineItemsColumns from "./LineItemsColumns";
import colors from "../../../../../theme/colors";
import moment from "moment";
import Card from "../../../../../components/Card";
import ObligationsTypeComponents from "../../../../../components/ObligationsTypeComponents";


interface IProps {
    contractId: string;
}

interface IExpandableView {
    obligationId: string;
    contractId: string;
}

interface ILineItems {
    description: string;
    rate: number;
    rate_type: string;
    quantity: number;
    total_amount: number;
}

interface IParties {
    type: string;
    user: string;
    company: string;
    account: string;
    role: string;
    name: string;
}

interface IObligation {
    id: string;
    created: string;
    to_party: IParties;
    from_party: IParties;
    amount: number;
    status: string;
    type: string;
    description: string;
    line_items: Array<ILineItems>;
    payments_due_date: string;
}

interface IPayment {
    amount: number;
    status: string;
    paid_at: string;
    payment_reference: string;
    due_date: string;
    account_type: string;
    account_method: string;
    account_number: string;
    created: string;
    payments_due_date: string;
};

const typeMapping: { [key: string]: string; } = {
    INVOICE_RECEIVABLE: "Invoice",
    NON_INVOICE_RECEIVABLE: "Non Invoice",
    DEBIT_RECEIVABLE: "Debit",
    PURCHASE: "Purchase",
    EARNINGS: "Earnings",
    PAYABLE: "Payable",
    LEDGER_WALLET_WITHDRAWAL: "Ledger Wallet Withdrawal"
}

function PaymentExpandableView({ obligationId, contractId }: IExpandableView): JSX.Element {
    const dispatch = useDispatch();
    const [payments, setPayments] = useState<Array<IPayment>>([]);
    const [isLoading, setIsLoading] = useState(false);
    const obligation = useSelector(getObligationById(obligationId)) as unknown as IObligation;
    useEffect(() => {
        const f = async () => {
            setIsLoading(true);
            await dispatch(fetchObligationById(contractId, obligationId));
            const { results } = await api.factoring.getPayments(contractId, obligationId);
            setPayments(results);
            setIsLoading(false);
        };
        f();
    }, [obligationId]);

    const ObligationStatusText = ({ status }: { status: string; }): JSX.Element => {
        return (
            <Table
                columnStyle={{ color: colors.primary }}
                columns={nestedPaymentColumn}
                rows={payments.map(payment => ({
                    ...payment,
                    status: (
                        <ObligationStatus status={payment.status} />
                    ),
                    to: obligation.to_party.name,
                    from: obligation.from_party.name,
                    account: `${payment.account_method} (${payment.account_type})`,
                    created: moment(payment.created).format('l')
                }))}
                isLoading={isLoading}
                allowEmpty
            />
        )
    }

    const LineItemsTable = (): JSX.Element => 
        <Table
            columnStyle={{ color: colors.primary }}
            columns={lineItemsColumns}
            rows={obligation.line_items}
            isLoading={isLoading}
            allowEmpty
        />

    return (
        <Grid container direction="column" style={{ textAlign: "left" }}>
            <Grid item style={{ paddingTop: 10, paddingLeft: 10 }}>
                {payments.map(payment => 
                    <Grid>
                        <Typography variant="body2" style={{ wordSpacing: 10 }}>
                            <Typography variant="body2" inline style={{ wordSpacing: 0 }}>{typeMapping[obligation.type]}</Typography><ObligationStatus status={payment.status} />
                            {`${formatDollars(obligation.amount)} on ${moment(payment.created).format('l')}`}
                            <Typography variant="body1" inline color="primary" style={{ marginLeft: 10, marginRight: 10 }}>From</Typography>
                            {`${obligation.from_party.name}`}
                            <Typography variant="body1" inline color="primary" style={{ marginLeft: 10, marginRight: 10 }}>To→</Typography>
                            {`${obligation.to_party.name}`}
                            <Typography variant="body1" inline style={{ marginLeft: 10, marginRight: 10 }}>account</Typography>
                            {`${payment.account_method}(${payment.account_type})`}
                        </Typography>
                    </Grid>
                )}
            </Grid>
            <Grid item container direction="row" style={{ paddingTop: 10, paddingLeft: 10 }}>
                <LineItemsTable />
            </Grid>
        </Grid>
    )
}
export default function ObligationsTab({
    contractId,
}: IProps): JSX.Element {
    const dispatch = useDispatch();
    const obligations = useSelector(getObligations);

    const runFailedLedgerWalletObligationHandler = useCallback(async obligationId => {
        try {
            await api.factoring.runFailedLedgerWalletObligation(obligationId);
            dispatch(openSnackbar("success", "Obligation has been reprocessed."))
        } catch (error) {
            dispatch(openSnackbar("error", error as string))
        }
    }, [obligations]);

    useEffect(() => {
        const f = async () => {
            try{
                await dispatch(fetchObligations(contractId));
            } catch (error) {
            }
        };
        if(contractId) {
            f();
        }
    }, [contractId]);


    return (
        <Table columns={columns(runFailedLedgerWalletObligationHandler)} rows={obligations}
            allowEmpty
            collapse
            expandableView={(id: string) => (
                <PaymentExpandableView contractId={contractId} obligationId={id} />
            )}
        />
    );
}
