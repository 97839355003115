interface OwnNoteProps {
  userId: string;
  noteOwnerId: string;
}

const ownNote = ({ userId, noteOwnerId }: OwnNoteProps): boolean => {
  if (!userId || !noteOwnerId) return false;
  return userId === noteOwnerId;
};

const rules = {
  admin: {
    static: [
      "admin-dashboard:view",
      "admin-funding-request:view",
      "admin-transactions:view",
      "admin-transactions-new_noa:view",
      "admin-transactions-new:view",
      "admin-transactions-all:view",
      "admin-transactions-fuel_advance:view",
      "admin-transactions-non_factored:view",
      "admin-transactions-pending:view",
      "admin-transactions-pending_noa:view",
      "admin-transactions-doc_issue:view",
      "admin-transactions-special_pending:view",
      "admin-transactions-pending_originals:view",
      "admin-transactions-pending_delivery:view",
      "admin-transactions-cf_review:view",
      "admin-transactions-incomplete:view",
      "admin-transactions-document_review:view",
      "admin-transactions-approved:view",
      "admin-transactions-remote_approved:view",
      "admin-transactions-declined:view",
      "admin-transactions-paid:view",
      "admin-debtors:view",
      "admin-finances:view",
      "admin-finances-debtor-balances:view",
      "admin-finances-line-items:view",
      "admin-finances-payments:view",
      "admin-finances-purchases:view",
      "admin-finances-receivables:edit",
      "admin-users:view",
      "admin-debtor-profile:view",
      "admin-debtor-payment:view",
      "admin-user-profile:view",
      "admin-factorclients:view",
      "admin-factorclient-profile:view",
      "admin-factorclient-profile:reports",
      "admin-factorclient-profile:delete",
      "admin-factorclient-profile:save",
      "admin-factorclient-profile:edit-payment-methods",
      "admin-factorclient-profile-address:view",
      "admin-factorclient-profile-phonenumber:view",
      "admin-funding-request:delete",
      "admin-funding-request-tabs:view",
      "admin-funding-request-chargebackgiveback:view",
      "admin-factorclient-funding_requests:view",
      "admin-factorclient-connected_debtors:view",
      "admin-factorclient-connected_carriers:view",
      "admin-factorclient-connect_debtor:view",
      "admin-factorclient-owner_info:view",
      "admin-factorclient-tabs:view",
      "admin-factorclient-attachments:view",
      "admin-factorclient-wallet:view",
      "lineitems:edit",
      "lineitems:delete",
      "index:view",
      "admin-admins:view",
      "admin-groups:view",
      "admin-payments:view",
      "admin-factorclients-all:view",
      "admin-factorclients-lead_in:view",
      "admin-factorclients-new:view",
      "admin-factorclients-document_review:view",
      "admin-factorclients-docissue:view",
      "admin-factorclients-pending:view",
      "admin-factorclients-contract_out:view",
      "admin-factorclients-ucc_hold:view",
      "admin-factorclients-not_authorized:view",
      "admin-factorclients-approved:view",
      "admin-factorclients-declined:view",
      "admin-factorclients-dead:view",
      "taxid:view",
      "noa-summary:view",
      "noa-summary:send",
      "admin-funding-request-paid:save",
      "admin-funding-request-remote-approved:save",
      "admin-funding-request-approved:save",
      "admin-factorclient-profile-nonfactored:view",
      "admin-funding-request-transaction-rep:update",
      "admin-finances-receivables:post-payment",
      "admin-debtor-funding_requests:view",
      "admin-debtor-similar_debtors:view",
      "admin-debtor-profile:setAsMaster",
      "admin-debtor-profile:delete",
      "admin-debtor-profile:save",
      "admin-debtor-profile:creditLimitCard",
      "admin-debtor-profile:availableCredit",
      "admin-debtor-profile:GeneralInfo",
      "admin-debtor-profile-connected_clients:save",
      "admin-debtor-profile-connected_clients:auditTrail",
      "admin-debtor-profile-connected_clients:deleteRelationship",
      "admin-debtor-profile-connected_clients:downloadReport",
      "admin-debtor-profile-audit-log:view",
      "admin-finances-payments:uploadStatement",
      "admin-finances-payments:exportPayments",
      "admin-finances-payments:exportSinglePayment",
      "admin-finances-payments:deletePayment",
      "admin-factorclients-connected_debtors:actions",
      "admin-factorclients-funding_request:downloadReport",
      "admin-factor_client-profile:paymentProfileMatch",
      "admin-actor_client-profile:userProfileLink",
      "admin-factor_client-profile:BlastNOA",
      "admin-actor_client-profile:groupLink",
      "admin-attachments:deleteAttachments",
      "admin-attachments:attachmentLinks",
      "admin-attachments:cropping",
      "admin-factor_client-profile:AddNewReferralSource",
      "admin-factor_client-profile:AddNewReasonOfLeaving",
      "admin-factor_client-profile:AddBrokerLogo",
      "admin-factor_client-profile:makePaymentProfile",
      "admin-finances-line-items:add",
      "lineitems:reversal",
      "lineitems:view"
    ],
    dynamic: {
      "notes:edit": ownNote,
      "notes:delete": ownNote
    }
  },
  sub_admin: {
    static: [
      "admin-dashboard:view",
      "admin-funding-request:view",
      "admin-transactions:view",
      "admin-transactions-all:view",
      "admin-transactions-new_noa:view",
      "admin-transactions-new:view",
      "admin-transactions-fuel_advance:view",
      "admin-transactions-non_factored:view",
      "admin-transactions-pending:view",
      "admin-transactions-pending_noa:view",
      "admin-transactions-doc_issue:view",
      "admin-transactions-special_pending:view",
      "admin-transactions-pending_originals:view",
      "admin-transactions-pending_delivery:view",
      "admin-transactions-cf_review:view",
      "admin-transactions-incomplete:view",
      "admin-transactions-document_review:view",
      "admin-transactions-approved:view",
      "admin-transactions-remote_approved:view",
      "admin-transactions-declined:view",
      "admin-transactions-paid:view",
      "admin-transactions-approved:view",
      "admin-debtors:view",
      "admin-finances:view",
      "admin-finances-debtor-balances:view",
      "admin-finances-line-items:view",
      "admin-finances-payments:view",
      "admin-finances-purchases:view",
      "admin-finances-receivables:edit",
      "admin-users:view",
      "admin-debtor-profile:view",
      "admin-debtor-payment:view",
      "admin-user-profile:view",
      "admin-factorclients:view",
      "admin-factorclient-profile:view",
      "admin-factorclient-profile:reports",
      "admin-factorclient-profile:delete",
      "admin-factorclient-profile:save",
      "admin-factorclient-profile:edit-payment-methods",
      "admin-factorclient-profile-address:view",
      "admin-factorclient-profile-phonenumber:view",
      "admin-funding-request:delete",
      "admin-funding-request-tabs:view",
      "admin-funding-request-chargebackgiveback:view",
      "admin-factorclient-funding_requests:view",
      "admin-factorclient-connected_debtors:view",
      "admin-factorclient-connected_carriers:view",
      "admin-factorclient-connect_debtor:view",
      "admin-factorclient-owner_info:view",
      "admin-factorclient-tabs:view",
      "admin-factorclient-attachments:view",
      "admin-factorclient-wallet:view",
      "lineitems:edit",
      "lineitems:delete",
      "index:view",
      "admin-admins:view",
      "admin-groups:view",
      "admin-payments:view",
      "admin-factorclients-all:view",
      "admin-factorclients-lead_in:view",
      "admin-factorclients-new:view",
      "admin-factorclients-document_review:view",
      "admin-factorclients-document_issue:view",
      "admin-factorclients-pending:view",
      "admin-factorclients-contract_out:view",
      "admin-factorclients-ucc_hold:view",
      "admin-factorclients-not_authorized:view",
      "admin-factorclients-approved:view",
      "admin-factorclients-declined:view",
      "admin-factorclients-dead:view",
      "taxid:view",
      "noa-summary:view",
      "noa-summary:send",
      "admin-funding-request-paid:save",
      "admin-funding-request-remote-approved:save",
      "admin-funding-request-approved:save",
      "admin-funding-request-transaction-rep:update",
      "admin-finances-receivables:post-payment",
      "admin-debtor-funding_requests:view",
      "admin-debtor-similar_debtors:view",
      "admin-debtor-profile:setAsMaster",
      "admin-debtor-profile:delete",
      "admin-debtor-profile:save",
      "admin-debtor-profile:creditLimitCard",
      "admin-debtor-profile:availableCredit",
      "admin-debtor-profile:GeneralInfo",
      "admin-debtor-profile-connected_clients:save",
      "admin-debtor-profile-connected_clients:auditTrail",
      "admin-debtor-profile-connected_clients:deleteRelationship",
      "admin-debtor-profile-connected_clients:downloadReport",
      "admin-debtor-profile-audit-log:view",
      "admin-finances-payments:uploadStatement",
      "admin-finances-payments:exportPayments",
      "admin-finances-payments:exportSinglePayment",
      "admin-finances-payments:deletePayment",
      "admin-factorclients-connected_debtors:actions",
      "admin-factorclients-funding_request:downloadReport",
      "admin-factor_client-profile:paymentProfileMatch",
      "admin-actor_client-profile:userProfileLink",
      "admin-factor_client-profile:BlastNOA",
      "admin-actor_client-profile:groupLink",
      "admin-attachments:deleteAttachments",
      "admin-attachments:attachmentLinks",
      "admin-attachments:cropping",
      "admin-factor_client-profile:AddNewReferralSource",
      "admin-factor_client-profile:AddNewReasonOfLeaving",
      "admin-factor_client-profile:AddBrokerLogo",
      "admin-factor_client-profile:makePaymentProfile",
      "admin-finances-line-items:add",
      "lineitems:reversal",
      "lineitems:view"
    ],
    dynamic: {
      "notes:edit": ownNote,
      "notes:delete": ownNote
    }
  },
  factoring_remote_admin: {
    static: [
      "admin-factorclients:view",
      "admin-factorclients-approved:view",
      "admin-transactions-remote_approved:view",
      "admin-factorclient-funding_requests:view",
      "admin-factorclient-profile:view",
      "admin-transactions:view",
      "noa-summary:view",
      "admin-transactions-new_noa:view",
      "admin-transactions-new:view",
      "admin-transactions-all:view",
      "admin-transactions-fuel_advance:view",
      "admin-transactions-non_factored:view",
      "admin-transactions-pending:view",
      "admin-transactions-pending_noa:view",
      "admin-transactions-doc_issue:view",
      "admin-transactions-special_pending:view",
      "admin-transactions-pending_originals:view",
      "admin-transactions-pending_delivery:view",
      "admin-transactions-cf_review:view",
      "admin-transactions-incomplete:view",
      "admin-transactions-document_review:view",
      "admin-transactions-approved:view",
      "admin-transactions-remote_approved:view",
      "admin-transactions-declined:view",
      "admin-transactions-paid:view",
      "admin-funding-request:view",
      "admin-finances:view",
      "admin-finances-receivables:post-payment",
      "admin-finances-receivables:edit",
      "admin-debtors:view",
      "admin-debtor-profile:view",
      "admin-debtor-profile:internalDebtorNotes",
      "admin-debtor-payment:view",
      "admin-finances-payments:view",
      "admin-factorclients:view",
      "admin-factorclient-connected_debtors:view",
      "noa-summary:send",
      "admin-factorclient-attachments:view",
      "admin-factorclient-owner_info:view",
      "admin-factorclient-profile:save",
      "admin-attachments:attachmentLinks",
      "admin-factorclients-funding_request:downloadReport",
      "lineitems:edit",
      "lineitems:delete",
      "admin-debtor-profile:save",
      "admin-debtor-profile:GeneralInfo",
      "admin-attachments:deleteAttachments",
      "admin-attachments:cropping",
    ]
  },
  carrier: {
    static: ["ic-panel-view"]
  }
};

export default rules;
