import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { debounce } from "lodash";
import axios from "axios";

import { addEntities } from "../../entities";
import {
    FETCH_WALLET_HISTORY_FAILURE,
    FETCH_WALLET_HISTORY_SUCCESS,
    FETCH_WALLET_HISTORY_REQUEST
} from "../types";

const handleResponse = (
    response,
    dispatch,
    walletTransactions,
    limit,
    ordering,
    filters
) => {
    const data = normalize(response.results, [walletTransactions]);
    dispatch(addEntities(data.entities));
    dispatch(
        createAction(FETCH_WALLET_HISTORY_SUCCESS)({
            items: data.result,
            count: response.count,
            next: response.next,
            previous: response.previous,
            ordering,
            limit,
            filters
        })
    );
    return response;
};

const debounced = debounce(
    async (userId, factoringId, isStripeUser, dispatch, schema, api, limit, ordering, filters) => {
        dispatch(
            createAction(FETCH_WALLET_HISTORY_REQUEST)({
                limit,
                filters,
                ordering,
                userId, 
                factoringId,
                isStripeUser,
            })
        );
        const queryWalletTransactionHistory = isStripeUser ? api.factoring.stripeWalletHistory : api.factoring.ledgerWalletHistory
        const response = await queryWalletTransactionHistory(
            userId,
            factoringId,
            limit,
            ordering,
            filters
        );
        handleResponse(response, dispatch, schema.walletTransactions, limit, ordering, filters);
    },
    1000,
    { trailing: true }
);

export const next = (link, limit, ordering, filters) => async (
    dispatch,
    getState,
    { schema }
) => {
    try {
        dispatch(
            createAction(FETCH_WALLET_HISTORY_REQUEST)({
                limit,
                ordering,
                filters
            })
        );

        const response = await axios.get(link);
        handleResponse(
            response.data,
            dispatch,
            schema.walletTransactions,
            limit,
            ordering,
            filters
        );
    } catch (err) {
        dispatch(createAction(FETCH_WALLET_HISTORY_FAILURE)(err));
        throw err;
    }
};

const fetchWalletHistory = (userId, factoringId, isStripeUser, limit, ordering, filters = {}) => async (
    dispatch,
    getState,
    { api, schema }
) => {
    try {
        await debounced(userId, factoringId, isStripeUser, dispatch, schema, api, limit, ordering, filters);
    } catch (err) {
        dispatch(createAction(FETCH_WALLET_HISTORY_FAILURE)(err));
        throw err;
    }
};

export default fetchWalletHistory;
