export default {
  bg: "#FFF",
  black: "#000",
  calendar_timeline: "#EDF5FA",
  danger: "#d0021b",
  disabledButton: "#B5BECF",
  gray: "#5C6979",
  grayBg: "#EEEEEE",
  grayText: "#67758D",
  green_dark: "#498504",
  green: "#4DC527",
  green_light: "#4dc527",
  inputColor: "#444444",
  inputPlaceholderColor: "#B4B4B4",
  inputGrayBorderColor: "#CCC",
  inputBorderColor: "#DCDCDC",
  inputLabelErrorColor: "#f44336",
  link: "#2196f3",
  borderInputColor: "#2196f3",
  borderInputDisableColor: "#9b9b9b",
  orange: "#FF7800",
  light_orange: "#f6b352",
  primary: "#FF7800",
  primaryDark: "#4DC527",
  secondary: "#2196F3",
  textLink: "#3C8DEB",
  info: "#4a90e2",
  textColor: "#5C6979",
  textInputColor: "#5C6979",
  warning: "#FFCC00",
  white: "#FFF",
  success: "#4dc527",
  backgroundTable: "rgba(242, 241, 241, 0.63)",
  divider: "#dee5ef",
  column: "rgba(246, 249, 252, 0.66)",
  menuColorIcon: "#9b9b9b",
  secondaryText: "#9b9b9b",
  purple: "#bd10e0",
  grayIcon: "#363F49",
  pink: "#FF0088"
};
