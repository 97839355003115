import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { AppBar, createStyles, IconButton, Menu, MenuItem, Toolbar, Typography, WithStyles } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import classNames from "classnames";
import { AccountCircle } from "@material-ui/icons";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

const drawerWidth = 240;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const styles = (theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex"
      }
    },
    grow: {
      flexGrow: 1
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 20
    },
    hide: {}
  });

interface Props extends WithStyles<typeof styles> {
  title?: string;
  open: boolean;
  handleDrawerOpen: () => void;
  handleUserMenuOpen: (event: React.MouseEvent<HTMLElement>) => void;
  handleUserMenuClose: () => void;
  openUserSetting: () => void;
  handleLogout: () => void;
  anchorEl: HTMLElement | null;
}

export default withStyles(styles)(
  ({
    title = "ComFreight",
    classes,
    open,
    handleDrawerOpen,
    handleUserMenuOpen,
    handleUserMenuClose,
    openUserSetting,
    handleLogout,
    anchorEl
  }: Props): JSX.Element => (
    <div>
      <AppBar
        position="fixed"
        color="secondary"
        className={classNames(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        <Toolbar disableGutters={!open}>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={handleDrawerOpen}
            className={classNames({
              [classes.hide]: open
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" noWrap>
            {title}
          </Typography>
          <div className={classes.grow} />
          <IconButton
            aria-owns={anchorEl ? "simple-menu" : undefined}
            aria-label="Account of current user"
            aria-controls="toolbar-menu"
            aria-haspopup="true"
            color="inherit"
            onClick={handleUserMenuOpen}
          >
            <AccountCircle />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id="toolbar-menu"
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={Boolean(anchorEl)}
        onClose={handleUserMenuClose}
      >
        <MenuItem
          onClick={(): void => {
            openUserSetting();
            handleUserMenuClose();
          }}
        >
          Settings
        </MenuItem>
        <MenuItem
          onClick={(): void => {
            handleLogout();
            handleUserMenuClose();
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </div>
  )
);
