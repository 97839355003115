import React from 'react';

import ObligationsTypeComponents from '../../../../../components/ObligationsTypeComponents';
import formatDollars from '../../../../../helpers/format/formatDollars';
import ObligationStatus from './Components/ObligationStatus';
import formatDate from '../../../../../helpers/format/formatDate';
import { Button, IconButton } from '@material-ui/core';
import { faRetweet } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import colors from "../../../../../theme/colors";

interface IParties {
    type: string;
    user: string;
    company: string;
    account: string;
    role: string;
}
const columns = (runFailedLedgerWalletObligationHandler: any) => [
    {
        key: 'type',
        name: 'Type',
        format: (type: string): JSX.Element => (
            <ObligationsTypeComponents type={type} />
        ),
    },
    {
        key: ['status', 'id', 'type', 'status_note'],
        name: 'Status',
        format: (status: string, id: string, type: string, status_note: string): JSX.Element => {
            return (
                <div>
                    <ObligationStatus status={status_note || status} style={{ fontSize: 16 }} />
                    {(type === 'LEDGER_WALLET_WITHDRAWAL' && status_note === 'Insufficient Funds') && 
                        <IconButton onClick={() => runFailedLedgerWalletObligationHandler(id)}>
                            <FontAwesomeIcon icon={faRetweet} color={colors.green} />
                        </IconButton>
                    }
                </div>
        )},
    },
    {
        key: ['from_party', 'to_party'],
        name: 'From → To',
        format: (from_party: IParties, to_party: IParties): JSX.Element => 
            <div>
                <p>{from_party.role} → {to_party.role}</p>
            </div>
            
    },
    {
        key: 'payments_due_date',
        name: 'Due Date',
        format: formatDate,
    },
    {
        key: ['amount', 'status'],
        name: 'Amount',
        format: (amount: number, status: string): JSX.Element => (
            <>
                {!status.includes('RECEIVABLE') ? (
                    <p>{formatDollars(amount)}</p>
                ) : (
                    <div />
                )}
            </>
        ),
    },
];

export default columns;
