import React from "react";
import { Grid, Icon, Typography } from "@material-ui/core";
import Replay from '@material-ui/icons/Replay';
import Close from '@material-ui/icons/Close';
import AvTimer from '@material-ui/icons/AvTimer';
import Done from '@material-ui/icons/Done';
import AttachMoney from '@material-ui/icons/AttachMoney';
import Receipt from '@material-ui/icons/Receipt';
import colors from "../theme/colors";
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

export default ({ type, style = {} }) => {
    const typeMapping = {
        INVOICE_RECEIVABLE: {
            text: "Invoice",
            icon: Replay,
            color: colors.info
        },
        NON_INVOICE_RECEIVABLE: {
            text: "Non Invoice",
            icon: Close,
            color: colors.danger
        },
        DEBIT_RECEIVABLE: {
            text: "Debit",
            icon: AvTimer,
            color: colors.purple
        },
        PURCHASE: {
            text: "Purchase",
            icon: Done,
            color: colors.green
        },
        EARNINGS: {
            text: "Earnings",
            icon: AttachMoney,
            color: colors.warning
        },
        PAYABLE: {
            text: "Payable",
            icon: Receipt,
            color: colors.green_dark
        },
        LEDGER_WALLET_WITHDRAWAL: {
            text: "Ledger Wallet Withdrawal",
            icon: AccountBalanceWalletIcon,
            color: colors.info
        }
    }
    if(!type) {
        return <div />
    }
    const { text = type.replace(/[^a-zA-Z0-9 ]/g, ' '), icon: Icon = CheckBoxOutlineBlankIcon, color = colors.black} = typeMapping[type] || {};
    return (
        <Grid container alignContent="center">
            <Icon style={{ color, fontSize: 25, ...style }}/>
            <Typography inline variant="body2" >
                {text}
            </Typography>
        </Grid>
    )
}