import formatStatus from "../../../../helpers/format/formatStatus";

const columns = [
  {
    key: "name",
    name: "Broker",
    filter: "text",
    filter_key: "company_name"
  },
  {
    key: "factor",
    name: "Factor"
  },
  {
    key: "factoring.factoring_approved",
    name: "Status",
    format: formatStatus
  },
  {
    key: "factoring.owner_profile.email",
    name: "Email",
    filter: "text",
    filter_key: "email"
  },
  {
    key: "factoring.owner_profile.phone_number_cell",
    name: "Phone #",
    filter: "text",
    filter_key: "phone"
  },
  {
    key: "factoring.company_profile.mc",
    name: "MC #",
    filter: "text",
    filter_key: "mc"
  },
  {
    key: "factoring.company_profile.dot",
    name: "DOT #",
    filter: "text",
    filter_key: "dot"
  },
  {
    key: "brokerPortion",
    name: "Broker Portion"
  },
  {
    key: "discountRate",
    name: "Payment Speed"
  },
  {
    key: "noa",
    name: "NOA Status"
  },
  {
    key: "noaConnection",
    name: "Factor Connection"
  },
  {
    key: "confirmNoa",
    name: "Confirm NOA"
  },
  {
    key: "actions",
    name: "Action"
  }
];

export default columns;
