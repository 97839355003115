import React, { ChangeEventHandler } from "react";
import { List, Typography } from "@material-ui/core";
import get from "lodash/get";
import { Link } from "react-router-dom";

import onlyNumbers from "../../../../../helpers/format/onlyNumbers";
import BoldInput, {
  CheckBox,
  Select
} from "../../../../../components/inputs/BoldInput";
import AdminDropDown from "../../../../../components/AdminDropDown";
import Card from "../../../../../components/Card";
import ListItem from "../../../../../components/ListItem";
import colors from "../../../../../theme/colors";

const DTPTermsValues = [
  {
    value: "15",
    label: "15 Days"
  },
  {
    value: "30",
    label: "30 Days"
  },
  {
    value: "45",
    label: "45 Days"
  },
  {
    value: "60",
    label: "60 Days"
  },
  {
    value: "75",
    label: "75 Days"
  },
  {
    value: "90",
    label: "90 Days"
  }
];

interface Props {
  values: {
    canBlastNOA: boolean;
    runAutomaticCreditCheck: boolean;
    salesRep: string;
    sourceType: string;
    originals_required: boolean;
    requires_scanned_copy: boolean;
    mc: string;
    dot: string;
    taxId: string;
    discountRate: string;
    averageDaysToPay: string;
    dtpTerms: string;
    creditApprovedNote: string;
    bankStatementLookup: string;
    duns: string;
    remitNotes: string;
    requiresNOAInvoice: boolean;
    source: {};
    StateFilingNumber: string;
  };
  errors: {
    mc?: string;
    sourceType?: string;
    dot?: string;
    taxId?: string;
    discountRate?: string;
    averageDaysToPay?: string;
    noaOnInvoice?: string;
    dtpTerms?: string;
    creditApprovedNote?: string;
    bankStatementLookup: string;
    duns?: string;
    remitNotes?: string;
    StateFilingNumber?: string;
  };
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  handleChange: ChangeEventHandler<HTMLInputElement>;
  handleBlur: ChangeEventHandler<HTMLInputElement>;
  disabled: boolean;
}



export default ({
  values,
  setFieldValue,
  handleChange,
  handleBlur,
  errors,
  disabled
}: Props): JSX.Element => (
  <Card style={{ height: "100%" }}>
    <List dense style={{ width: "100%" }}>
      <ListItem>
        <AdminDropDown
          name="salesRep"
          onChange={handleChange}
          onBlur={handleBlur}
          label="Sales Rep"
          value={values.salesRep}
          disabled={disabled}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          disabled
          name="sourceType"
          label="Source"
          value={values.sourceType}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.sourceType}
          helperText={errors.sourceType}
        />
      </ListItem>
      <ListItem>
        <Link to={`/admin/users/${get(values, "source.id")}/profile`}>
          {get(values, "source.company_name")}
        </Link>
      </ListItem>
      <ListItem>
        <CheckBox
          value={values.originals_required}
          setFieldValue={setFieldValue}
          name="originals_required"
          label="Original Required"
        />
      </ListItem>
      <ListItem>
        <CheckBox
          value={values.requires_scanned_copy}
          setFieldValue={setFieldValue}
          name="requires_scanned_copy"
          label="Scanned Copies Required"
        />
      </ListItem>
      <ListItem>
        <CheckBox
          value={values.requiresNOAInvoice}
          setFieldValue={setFieldValue}
          name="requiresNOAInvoice"
          label="Requires NOA on first Invoice"
        />
      </ListItem>
      <ListItem>
        <CheckBox
          value={values.runAutomaticCreditCheck}
          setFieldValue={setFieldValue}
          name="runAutomaticCreditCheck"
          label="Run Automatic Credit Check"
          disabled={disabled}
        />
      </ListItem>
      <ListItem>
        <CheckBox
          value={values.canBlastNOA} 
          setFieldValue={setFieldValue} 
          name="canBlastNOA" 
          label="Included in NOA Blast"
          disabled={disabled}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          label="MC"
          name="mc"
          value={onlyNumbers(values.mc)}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.mc}
          helperText={errors.mc}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          label="DOT"
          name="dot"
          value={onlyNumbers(values.dot)}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.dot}
          helperText={errors.dot}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          label="Tax ID"
          name="taxId"
          value={values.taxId}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.taxId}
          helperText={errors.taxId}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          label="DUNS Number"
          name="duns"
          value={values.duns}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.duns}
          helperText={errors.duns}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          label="State Filing Number"
          name="StateFilingNumber"
          value={values.StateFilingNumber}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.StateFilingNumber}
          helperText={errors.StateFilingNumber}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          label="Discount Rate"
          name="discountRate"
          value={values.discountRate}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.discountRate}
          helperText={errors.discountRate}
          disabled={disabled}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          label="Average days to pay"
          name="averageDaysToPay"
          value={values.averageDaysToPay}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.averageDaysToPay}
          helperText={errors.averageDaysToPay}
          disabled
        />
      </ListItem>
      <ListItem>
        <Select
          value={values.dtpTerms}
          onBlur={handleBlur}
          onChange={handleChange}
          fullWidth
          label="DTP Terms"
          name="dtpTerms"
          selections={DTPTermsValues.map(({ value, label }) => ({
            value,
            text: label
          }))}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          label="Internal Payment Remit Notes"
          name="remitNotes"
          value={values.remitNotes}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.remitNotes}
          helperText={errors.remitNotes}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          customLabelStyle={{ color: colors.danger }}
          label="Credit Approval Notes (shows to client)"
          name="creditApprovedNote"
          value={values.creditApprovedNote}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.creditApprovedNote}
          helperText={errors.creditApprovedNote}
          disabled={disabled}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          label="Bank Statement Lookup"
          name="bankStatementLookup"
          value={values.bankStatementLookup}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.bankStatementLookup}
          helperText={errors.bankStatementLookup}
        />
      </ListItem>
    </List>
  </Card>
);
