import axios, { AxiosRequestConfig } from "axios";


const getObligations = async (contractId: string): Promise<any> => {
    const url = `/contracts/${contractId}/obligations`;
    const options: AxiosRequestConfig = {
        url,
        method: "GET"
    };
    const response = await axios(options);
    return response.data;
};

const getObligationsById = async (contractId: string, obligationId:string): Promise<any> => {
    const url = `/contracts/${contractId}/obligations/${obligationId}`;
    const options: AxiosRequestConfig = {
        url,
        method: "GET"
    };
    const response = await axios(options);
    return response.data;
};

const getPayments = async (contractId: string, obligationId: string): Promise<any> => {
    const url = `/contracts/${contractId}/obligations/${obligationId}/payments`;
    const options: AxiosRequestConfig = {
        url,
        method: "GET"
    };
    const response = await axios(options);
    return response.data;
};

const runFailedLedgerWalletObligation = async (obligationId: string): Promise<any> => {
    const url = `/obligations/run_failed_ledger_wallet_obligation`;
    const options: AxiosRequestConfig = {
        url,
        method: "POST",
        data: {
            obligation_id: obligationId
        }
    };
    const response = await axios(options);
    return response.data;
};

export default {
    getObligations,
    getObligationsById,
    getPayments,
    runFailedLedgerWalletObligation
}