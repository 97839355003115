export const fundingRequestType = [{
    value: 'STD_BROKER',
    text: 'Factored',
    header: 'Standard Funding Request',
},
{
    text: 'Non-Factored Pay Carrier',
    value: 'NON_FACTORED_STANDARD',
    header: 'Non Factored Funding Requests'
},
{
    text: 'Non-Factored Bill Out Only',
    value: 'NON_FACTORED_BILL_OUT',
    header: 'Non Factored Funding Requests'
},
{
    value: 'SELF_FINANCE_NO_INVOICE',
    text: 'Self Finance No invoice',
    header: 'Self Finance Funding Request',
},
{
    value: 'SELF_FINANCE_WITH_INVOICE',
    text: 'Self Finance with invoice',
    header: 'Self Finance Funding Request',
},
{
    value: 'SELF_FINANCE_LEDGER_DEPOSIT',
    text: 'Self Finance Ledger Deposit',
    header: 'Self Finance Funding Request',
},
{
    value: 'SELF_FINANCE_RECEIVABLES',
    text: 'Self Finance Receivables',
    header: 'Self Finance Funding Request',
}];