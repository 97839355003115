import splitAndCase from "../../../../helpers/format/splitAndCase";
import formatPennies from "../../../../helpers/format/formatPennies";
import formatDate from "../../../../helpers/format/formatDate";

const columns = [
  {
    key: "client",
    name: "Client"
  },
  {
    key: "debtor",
    name: "Debtor",
    sort: "debtor",
    filter: "text",
    filter_key: "bill_to_company_name"
  },
  {
    key: "amount",
    name: "Invoice Amnt."
  },
  {
    key: "invoice",
    name: "Invoice #",
    filter: "text",
    filter_key: "invoice_number",
    sort: true
  },
  {
    key: "user_load_number",
    name: "Load #",
    filter: "text",
    filter_key: "user_load_number",
    sort: true
  },
  {
    key: "created",
    name: "Requested At",
    format: formatDate,
    sort: true
  },
  {
    key: "assigned_admin.name",
    name: "Transaction Rep",
    sort: "assigned_admin"
  },
  {
    key: "status",
    name: "Status",
    format: splitAndCase,
    filter_key: "status",
    filter: "dropdown",
    filter_value: "",
    filter_values: [
      { label: "New (NOA)", value: "new_noa" },
      { label: "New", value: "new" },
      { label: "Fuel Advance", value: "fuel_advance" },
      { label: "Pending", value: "pending" },
      { label: "Pending (NOA)", value: "pending_noa" },
      { label: "Pending Originals", value: "pending_originals" },
      { label: "Document Issue", value: "document_issue" },
      { label: "CF Review", value: "haulpay_review" },
      { label: "Document Review", value: "document_review" },
      { label: "Approved", value: "approved" },
      { label: "Incomplete", value: "incomplete" },
      { label: "Remote Approved", value: "remote_approved" },
      { label: "Declined", value: "declined" },
      { label: "Paid", value: "paid" },
      { label: "Deleted", value: "deleted" },
      { label: "New - Fast Track", value: "new_fasttrack" }
    ]
  }
];

export default columns;
