import { get, head, omit } from "lodash";
import maskEIN from "../../../../helpers/format/maskEIN";
import getPhone from "../../../../helpers/getPhone";
import getExtension from "../../../../helpers/getExtension";
import { debtor as debtorSchmea } from "./validation";

export const notesForm = (
  id,
  createDebtorNote,
  updateDebtorNote,
  openSnackbar
) => ({
  enableReinitialize: true,
  initialStatus: "CREATE",
  initialValues: {
    note: "",
    attachments: "",
    notesID: ""
  },
  onSubmit: async (values, { setSubmitting, resetForm }) => {
    try {
      if (values.notesID !== "") {
        await updateDebtorNote(values.notesID, values.note, values.attachments);
        openSnackbar("success", "Updated Note!");
      } else {
        await createDebtorNote(id, values.note, values.attachments);
        openSnackbar("success", "Created Note!");
      }
    } catch (err) {
      openSnackbar("error", err.message || err);
    } finally {
      setSubmitting(false);
      resetForm();
    }
  }
});

export const debtorForm = (updateDebtor, openSnackbar, debtor = {}) => {
  const {
    name: contactName = "",
    contact_email: contactEmail = "",
    payment_status_email: paymentStatusEmail = "",
    company_name: companyName = "",
    phone_number: phoneNumber = "",
    email: apEmail = "",
    noa_contact_email: noaContactEmail = "",
    mc = "",
    dot = "",
    tax_id: taxId = "",
    address: {
      street_one: streetOne = "",
      street_two: streetTwo = "",
      city = "",
      zip = "",
      state = ""
    } = {},
    run_automatic_credit_check: runAutomaticCreditCheck = false,
    fax_number: faxNumber = "",
    discount_rate: discountRate = "",
    dtp_allowance: dtpTerms,
    credit_approved_note: creditApprovedNote = "",
    assigned_admin: salesRep,
    credit_available: creditAvailable = "",
    credit_limit: creditLimit = 0,
    credit_approved: creditStatus = "",
    master = false,
    originals_required = false,
    requires_scanned_copy = false,
    source: { type: sourceType = "" } = {},
    statement_search_key: bankStatementLookup = "",
    duns_number,
    operating_timezone: timezone = "",
    remit_notes: remitNotes = "",
    requires_noa_on_invoice: requiresNOAInvoice = false,
    source,
    can_blast_noa: canBlastNOA,
    state_filing_number: StateFilingNumber,
  } = debtor;

  const averageDaysToPay = get(debtor, "avgpay") || 0;

  return {
    enableReinitialize: true,
    validationSchema: debtorSchmea,
    initialValues: {
      canBlastNOA,
      runAutomaticCreditCheck,
      requiresNOAInvoice,
      sourceType,
      requires_scanned_copy,
      originals_required,
      master,
      creditStatus,
      creditAvailable,
      creditLimit: creditLimit / 100,
      phoneNumber: getPhone(phoneNumber),
      phoneNumberExt: getExtension(phoneNumber),
      paymentStatusEmail,
      contactEmail,
      companyName,
      mc,
      dot,
      taxId: (taxId || "").includes("tok") ? maskEIN(taxId) : taxId,
      duns: duns_number,
      apEmail,
      noaContactEmail,
      contactName,
      streetOne,
      streetTwo,
      city,
      zip,
      faxNumber: getPhone(faxNumber),
      faxNumberExt: getExtension(faxNumber) || "",
      state,
      discountRate,
      averageDaysToPay,
      dtpTerms: dtpTerms || "",
      creditApprovedNote,
      salesRep: salesRep || "",
      bankStatementLookup: bankStatementLookup || "",
      timezone,
      remitNotes: remitNotes || "",
      source: source || {},
      StateFilingNumber: StateFilingNumber || "",
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        let update = {
          can_blast_noa: values.canBlastNOA,
          requires_noa_on_invoice: values.requiresNOAInvoice,
          originals_required: values.originals_required,
          requires_scanned_copy: values.requires_scanned_copy,
          credit_available: values.creditAvailable,
          credit_limit: Math.round(values.creditLimit * 100),
          assigned_admin: values.salesRep,
          name: values.contactName,
          run_automatic_credit_check: values.runAutomaticCreditCheck,
          // contact_email: values.contactEmail,
          payment_status_email: values.paymentStatusEmail,
          company_name: values.companyName,
          phone_number: `${values.phoneNumber};${values.phoneNumberExt}`,
          email: values.apEmail,
          noa_contact_email: values.noaContactEmail,
          mc: values.mc,
          dot: values.dot,
          tax_id: values.taxId,
          address: {
            street_one: values.streetOne,
            street_two: values.streetTwo,
            city: values.city,
            zip: values.zip,
            state: values.state
          },
          fax_number: `${values.faxNumber};${values.faxNumberExt}`,
          discount_rate:
            values.discountRate === "" ? null : values.discountRate,
          avgpay: values.averageDaysToPay,
          dtp_allowance: values.dtpTerms === "" ? null : values.dtpTerms,
          credit_approved: values.creditStatus,
          credit_approved_note: values.creditApprovedNote,
          statement_search_key: values.bankStatementLookup,
          operating_timezone: values.timezone,
          duns_number: values.duns,
          remit_notes: values.remitNotes,
          state_filing_number: values.StateFilingNumber,
        };

        if (get(update, "tax_id", "").includes("*")) {
          update = omit(update, "tax_id");
        }
        await updateDebtor(debtor.id, update);
        openSnackbar("success", "Updated Debtor Profile!");
      } catch (err) {
        openSnackbar("error", head(err));
      } finally {
        setSubmitting(false);
      }
    }
  };
};
