import React from "react";
import { MenuItem, Grid, FormHelperText } from "@material-ui/core";
import { Formik } from "formik";
import SaveIcon from "@material-ui/icons/Save";
import { isUndefined, get } from "lodash";

import Colors from "../../../../theme/colors";
import AutoCompleteAddress from "../../../../components/inputs/AutoCompleteAddress";
import MultiButtonToggleInputs from "../../../../components/inputs/MultiButtonToggleInputs";
import PhoneInput from "../../../../components/inputs/PhoneInput";
import {
  DialogActions,
  DialogTitle,
  Dialog,
  DialogContent
} from "../../../../components/Dialog";
import normalizeFormPhone from "../../../../helpers/format/normalizeFormPhone";
import RoundedButton from "../../../../components/buttons/RoundedButton";
import { SUCCESS, ERROR } from "../../../../helpers/Modals";
import CustomTextField from "../../../../components/inputs/CustomTextField";
import CustomInputField from "../../../../components/inputs/CustomInputField";
import addDebtorFormValidation from "./components/addDebtorFormValidation";

const DTPTermsValues = [
  {
    value: "15",
    label: "15 Days"
  },
  {
    value: "30",
    label: "30 Days"
  },
  {
    value: "45",
    label: "45 Days"
  },
  {
    value: "60",
    label: "60 Days"
  },
  {
    value: "75",
    label: "75 Days"
  },
  {
    value: "90",
    label: "90 Days"
  }
];

const DTPTermsInput = ({ onChange, onBlur, values }) => (
  <CustomTextField
    fullWidth
    select
    label="DTP Terms"
    name="dtp_allowance"
    id="dtp_allowance"
    onChange={onChange}
    onBlur={onBlur}
    value={values.dtp_allowance}
  >
    {DTPTermsValues.map(option => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ))}
  </CustomTextField>
);

const GridColumn = ({ children, numbers, ...props }) => (
  <Grid direction="column" item xs={numbers} {...props}>
    {children}
  </Grid>
);

export default ({ open, handleClose, callback, openModal }) => (
  <Dialog open={open} size="sm" fullWidth>
    <DialogTitle onClose={handleClose}>New Debtor</DialogTitle>
    <Formik
      validationSchema={addDebtorFormValidation}
      onSubmit={async (fields, formikActions) => {
        try {
          const data = {
            email: fields.email,
            name: fields.contact_name,
            company_name: fields.company_name,
            address: {
              street_one: fields.street_one,
              street_two: fields.street_two,
              city: fields.city,
              state: fields.state,
              zip: fields.zip
            },
            phone_number: normalizeFormPhone(fields, "phone_number"),
            fax_number: normalizeFormPhone(fields, "fax_number"),
            mc: fields.mc,
            dot: fields.dot,
            tax_id: fields.tax_id,
            noa_contact_email: fields.noa_contact_email,
            dtp_allowance: fields.dtp_allowance
          };
          await callback(data);
          await handleClose();
          openModal(SUCCESS, "Debtor has been created successfully.");
        } catch (e) {
          openModal(ERROR, "Error while creating debtor.");
        }
        formikActions.setSubmitting(false);
      }}
      initialValues={{
        street_one: "",
        street_two: "",
        city: "",
        state: "",
        zip: "",
        phone_number: "",
        fax_number: "",
        mc: undefined,
        dot: undefined,
        tax_id: undefined,
        dtp_allowance: 30
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        errors,
        touched
      }) => {
        return (
          <React.Fragment>
            <DialogContent>
              <Grid container spacing={16} direction="column">
                <Grid item>
                  <CustomTextField
                    fullWidth
                    name="email"
                    label="AP Email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                </Grid>
                <Grid item>
                  <CustomInputField
                    id="Phone"
                    fullWidth
                    label="AP Phone"
                    value={values.phone_number}
                    name="phone_number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputComponent={PhoneInput}
                  />
                </Grid>
                <Grid item>
                  <CustomTextField
                    label="Phone Ext."
                    fullWidth
                    value={values.phone_ext}
                    name="phone_number_ext"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item>
                  <CustomTextField
                    fullWidth
                    name="contact_name"
                    label="AP Contact Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.contact_name}
                  />
                </Grid>
                <Grid item>
                  <CustomTextField
                    fullWidth
                    name="noa_contact_email"
                    label="NOA Email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.noa_contact_email}
                  />
                </Grid>
                <Grid item>
                  <CustomTextField
                    fullWidth
                    name="company_name"
                    label="Company"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.company_name}
                  />
                </Grid>
                <Grid item>
                  <CustomTextField
                    fullWidth
                    label="Street One"
                    value={values.street_one}
                    name="street_one"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item>
                  <CustomTextField
                    fullWidth
                    label="Street Two"
                    value={values.street_two}
                    name="street_two"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item>
                  <CustomTextField
                    fullWidth
                    label="City"
                    value={values.city}
                    name="city"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item>
                  <CustomTextField
                    fullWidth
                    label="State"
                    value={values.state}
                    name="state"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item>
                  <CustomTextField
                    fullWidth
                    label="Zip Code"
                    value={values.zip}
                    name="zip"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item>
                  <CustomInputField
                    id="fax"
                    fullWidth
                    label="Fax"
                    value={values.fax_number}
                    name="fax_number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputComponent={PhoneInput}
                  />
                </Grid>
                <Grid item>
                  <CustomTextField
                    fullWidth
                    label="Fax Ext."
                    value={values.fax_ext}
                    name="fax_number_ext"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item>
                  <React.Fragment>
                    <MultiButtonToggleInputs
                      names={["mc", "dot", "tax_id"]}
                      placeholder={{
                        mc: "MC#",
                        dot: "DOT#",
                        tax_id: "TAX ID#"
                      }}
                      selectedName="mc"
                      label="MC / DOT / TAX ID"
                      hasError={
                        !!(
                          (errors.mc && touched.mc) ||
                          (errors.dot && touched.dot) ||
                          (errors.tax_id && touched.tax_id)
                        )
                      }
                      withStyle
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                    />
                    {!!(
                      (errors.mc && touched.mc) ||
                      (errors.dot && touched.dot)
                    ) && (
                      <FormHelperText error>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: [errors.mc, errors.dot]
                              .filter(val => !isUndefined(val))
                              .join(`<br />`)
                          }}
                        />
                      </FormHelperText>
                    )}
                  </React.Fragment>
                </Grid>
                <Grid item>
                  <DTPTermsInput
                    onChange={handleChange}
                    onBlur={handleBlur}
                    values={values}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <RoundedButton
                variant="contained"
                color="primary"
                progressStyle={{ color: Colors.primary }}
                onClick={handleSubmit}
                loading={isSubmitting}
              >
                <SaveIcon />
                Save
              </RoundedButton>
            </DialogActions>
          </React.Fragment>
        );
      }}
    </Formik>
  </Dialog>
);
