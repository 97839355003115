import React from "react";
import { Typography } from "@material-ui/core";
import colors from "../../../../../../theme/colors";

interface Props {
    status: string;
    style?: object;
}
interface IObjectKeys {
    [key: string]: {
        text: string;
        color: string;
    };
}

const ObligationStatus = ({ status, style = {} }: Props): JSX.Element => {
    const statusMapping: IObjectKeys = {
        DRAFT: {
            text: 'Draft',
            color: colors.black
        },
        CREATED: {
            text: 'Created',
            color: colors.info
        },
        ACTIVE: {
            text: 'Active',
            color: colors.green_dark
        },
        WAITING_FOR_OBLIGATION: {
            text: 'Waiting for obligation',
            color: colors.warning
        },
        WAITING_FOR_PAYMENT: {
            text: 'Waiting for payment',
            color: colors.warning
        },
        INVOICED: {
            text: "Received",
            color: colors.purple
        },
        PROCESSED: {
            text: "Pending",  
            color: colors.warning
        },
        COMPLETED: {
            text: "Paid",
            color: colors.success
        },
        FAILED: {
            text: "Failed",
            color: colors.danger
        },
        CANCELLED: {
            text: "Cancelled",
            color: colors.danger
        },
        SCHEDULED:{
            text: "Scheduled",
            color: colors.info
        },
        'Insufficient Funds': {
            text: "Insufficient Funds",
            color: colors.danger
        },
    };

    if (!status) {
        return <div />
    }

    const { text = status.replace(/[^a-zA-Z0-9 ]/g, ' '), color = colors.info } = statusMapping[status] || {};

    return (
        <Typography inline style={{ fontSize: 20, color: color, ...style }}>
            {` ${text} `}
        </Typography>
    );
};

export default ObligationStatus;
