import { createStyles } from "@material-ui/core";
import colors from "../../../theme/colors";

const styles = createStyles({
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderBottom: `2px solid ${colors.borderInputDisableColor} !important`
    },
    "&:after": {
      borderBottom: `2px solid ${colors.borderInputColor}`
    }
  },
  underlineAddress: {
    "&:hover:not($disabled):before,&:before": {
      borderBottom: `2px solid ${colors.borderInputDisableColor} !important`
    },
    "&:after": {
      borderBottom: `2px solid ${colors.borderInputColor} !important`
    }
  },
  cssFocused: {
    color: `${colors.borderInputColor} !important`
  },
  errorLabel: {
    color: `${colors.inputLabelErrorColor} !important`
  },
  errorInput: {
    borderBottom: `2px solid ${colors.inputLabelErrorColor} !important`
  },
  input: {
    color: `${colors.textColor} !important`,
    // fontFamily: "Avenir !important",
    fontSize: "16px !important",
    fontWeight: 500,
    lineHeight: `1.5 !important`,
    opacity: 0.9
  }
});

export default styles;
