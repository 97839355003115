import React from "react";
import { Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faClock, faMinus } from "@fortawesome/free-solid-svg-icons";
import Colors from "../theme/colors";
import formatStatus from "../helpers/format/formatStatus";

interface Props {
  status: string;
  style?: object;
}

const HaulPayStatus = ({ status, style = {} }: Props): JSX.Element => {
  return (
    <React.Fragment>
      {status ? (
        <div>
          <Typography style={{ fontSize: 10, ...style }}>
            <IconStatus status={formatStatus(status)} style={style} />
            {` ${formatStatus(status)}`}
          </Typography>
        </div>
      ) : (
        <FontAwesomeIcon size="xs" icon={faMinus} />
      )}
    </React.Fragment>
  );
};

const IconStatus = ({ status }: Props): JSX.Element => {
  switch (status) {
    case "Approved":
    case "Open":
    case "Paid":
    case "Client Settled":
    case "Non Factored Open":
      return <FontAwesomeIcon size="xs" color={Colors.green} icon={faCircle} />;
    case "Declined":
    case "Void":
    case "Expired":
    case "Failed":
      return (
        <FontAwesomeIcon size="xs" color={Colors.danger} icon={faCircle} />
      );
    case "Lead In":
    case "Printed":
    case "Mailed":
    case "Broker Invited":
      return (
        <FontAwesomeIcon size="xs" color={Colors.secondary} icon={faCircle} />
      );
    case "Pending":
    case "In Process":
    case "Verify Duplicate":
    case "Needs Review":
    case "Requested":
    case "Non Factored Over Paid":
    case "Returned":
      return (
        <FontAwesomeIcon size="xs" color={Colors.warning} icon={faCircle} />
      );
    default:
      return <FontAwesomeIcon size="xs" icon={faClock} />;
  }
};

export default HaulPayStatus;
