import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { get } from "lodash";
import { DateTime } from "luxon";
import {
  faFileInvoiceDollar,
  faUserClock
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import colors from "../../../theme/colors";
import DateRangePicker from "../../../components/inputs/DateRangePicker";
import AvatarCard from "../../../components/AvatarCard";
import ClientsStats from "./components/ClientsStats";
import ApplicationsStats from "./components/ApplicationsStats";
import InvoiceStats from "./components/InvoiceStats";
import OutstandingAR from "./components/OutstandingAR";
import Revenue from "./components/Revenue";
import Finances from "./components/Finances";
import MoreStats from "./components/MoreStats";
import InternalLayout from "../../../components/layouts/InternalLayout";
import Can from "../../../components/Can";

const Dashboard = ({ ...props }) => {
  const { fetchAdminStats, dateRange, onDateChange, stats } = props;
  return (
    <Can
      perform="admin-dashboard:view"
      yes={() => (
        <InternalLayout title="Dashboard">
          <Grid container style={{ width: "100%" }} spacing={16}>
            <Grid
              container
              item
              xs={12}
              direction="row"
              spacing={16}
              alignItems="flex-end"
            >
              <Grid item lg={6}>
                <Typography variant="h4">
                  Welcome to Haulpay Dashboard
                </Typography>
              </Grid>
              <Grid container item lg={6} justify="flex-end">
                <DateRangePicker
                  value={dateRange}
                  onChange={async values => {
                    try {
                      await fetchAdminStats({
                        datetime_start: DateTime.fromJSDate(values[0]).toISO(),
                        datetime_end: DateTime.fromJSDate(values[1]).toISO()
                      });
                      onDateChange(values);
                    } catch (err) {}
                  }}
                />
              </Grid>
              <Grid container item spacing={16}>
                <Grid item lg={3}>
                  <AvatarCard
                    title={
                      <Typography
                        variant="h5"
                        style={{
                          color: colors.white,
                          width: "75%",
                          fontSize: 16,
                          letterSpacing: "0.57px"
                        }}
                      >
                        Total Applications Approved
                      </Typography>
                    }
                    color={colors.green}
                    icon={
                      <FontAwesomeIcon
                        icon={faUserClock}
                        size="2x"
                        color={colors.green}
                      />
                    }
                    subheader={get(
                      stats,
                      ["items", "total_approved_applications"],
                      0
                    )}
                  />
                </Grid>
                <Grid item lg={3}>
                  <AvatarCard
                    title={
                      <Typography
                        variant="h5"
                        style={{
                          color: colors.white,
                          width: "75%",
                          fontSize: 16,
                          letterSpacing: "0.57px"
                        }}
                      >
                        {" "}
                        HaulPays Applications
                      </Typography>
                    }
                    color={colors.secondary}
                    icon={
                      <FontAwesomeIcon
                        icon={faFileInvoiceDollar}
                        size="2x"
                        color={colors.secondary}
                      />
                    }
                    subheader={get(
                      stats,
                      "items.total_haulpay_applications",
                      0
                    )}
                  />
                </Grid>
                <Grid item lg={3}>
                  <ClientsStats stats={stats} />
                </Grid>
                <Grid item lg={3}>
                  <ApplicationsStats stats={stats} />
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12} direction="row" spacing={16}>
              <Grid item lg={6}>
                <InvoiceStats {...props} />
              </Grid>
              <Grid item lg={6}>
                <OutstandingAR {...props} />
              </Grid>
            </Grid>
            <Grid container item xs={12} direction="row" spacing={16}>
              <Grid item lg={3}>
                <Revenue {...props} />
              </Grid>
              <Grid item lg={9}>
                <Finances />
              </Grid>
            </Grid>
            <Grid container item xs={12} direction="row" spacing={16}>
              <Grid item lg={6} direction="column">
                <MoreStats {...props} />
              </Grid>
            </Grid>
          </Grid>
        </InternalLayout>
      )}
      no={() => <div />}
    />
  );
};

export default Dashboard;
