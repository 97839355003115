import { Fade, Grid, IconButton, Paper, Popper, Tooltip } from "@material-ui/core";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import colors from "../theme/colors";

interface Props {
  attachment: {
    preview_url: string;
    download_url: string;
  };
}

export default function AttachmentLinks({ attachment }: Props): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState<EventTarget | null>(null);

  function handleClick(event: React.MouseEvent<HTMLElement, MouseEvent>): void {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  }

  const open = Boolean(anchorEl);
  return (
    <React.Fragment>
      <IconButton onClick={handleClick}>
        <FontAwesomeIcon icon={faPaperclip} size="xs" />
      </IconButton>
      <Popper open={open} anchorEl={anchorEl as HTMLElement} transition>
        {({ TransitionProps }): JSX.Element => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <Grid container spacing={8}>
                <Grid item>
                  <Tooltip title="View Attachment">
                    <IconButton
                      onClick={(): void => {
                        window.open(attachment.preview_url, "_blank");
                        setAnchorEl(null);
                      }}
                    >
                      <FontAwesomeIcon icon={faEye} color={colors.link} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title="Download Attachment">
                    <a href={attachment.download_url}>
                      <IconButton
                        onClick={(): void => {
                          setAnchorEl(null);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faDownload}
                          color={colors.light_orange}
                        />
                      </IconButton>
                    </a>
                  </Tooltip>
                </Grid>
              </Grid>
            </Paper>
          </Fade>
        )}
      </Popper>
    </React.Fragment>
  );
}
