import React from 'react';
import { Redirect } from 'react-router-dom';
import {
  Button,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
  withStyles
} from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { Formik } from 'formik';
import { get, compact } from 'lodash';

import WaitView from '../../../../components/WaitView';
import MasterStar from '../../../../components/icons/MasterStar';
import Notes from '../../../../components/Notes';
import DeleteButton from '../../../../components/buttons/DeleteButton';
import AuditTrail from '../../../../components/AuditTrail';
import InternalLayout from '../../../../components/layouts/InternalLayout';
import Can from '../../../../components/Can';
import NavigationTabs from '../../../../components/NavigationTabs';
import ConfirmDialog from '../../../../components/dialogs/ConfirmDialog';
import CreditStatusCard from './components/CreditStatusCard';
import AvailableCreditCard from './components/AvailableCreditCard';
import CreditLimitCard from './components/CreditLimitCard';
import ContactInfoCard from './components/ContactInfoCard';
import GeneralInfoCard from './components/GeneralInfoCard';
import colors from '../../../../theme/colors';
import Link from '../../../../components/Link';
import CreditReport from './components/CreditReport';
import ProfileHeader from '../../../../components/ProfileHeader';

export default withStyles(() => {}, { withTheme: true })(
  ({
    handleRoute,
    tabs,
    navIndex,
    theme,
    index,
    handleChange: handleTabChange,
    handleChangeIndex: handleTabChangeIndex,
    debtorForm,
    auditLog,
    id,
    dialog,
    openDialog,
    closeDialog,
    deleteDebtor,
    notesForm,
    notes,
    handleSetMaster,
    companyName,
    isLoading,
    loadDebtorNoteAttachments,
    deleteDebtorNote,
    userId,
    disabled
  }) => (
    <Can
      perform="admin-debtor-profile:view"
      yes={() => (
        <InternalLayout title="Debtor Profile">
          {isLoading ? (
            <WaitView />
          ) : (
            <React.Fragment>
              <Formik {...debtorForm}>
                {({
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  errors,
                  touched
                }) => (
                  <React.Fragment>
                    <Grid container direction="column" spacing={16}>
                      <ProfileHeader
                        icon={values.master === true && <MasterStar />}
                        title={companyName}
                        links={compact([
                          <Link to={`/admin/users/${userId}/profile`}>
                            User Profile
                          </Link>
                        ])}
                        actions={compact([
                          values.master !== true && (
                            <Can
                              perform="admin-debtor-profile:setAsMaster"
                              yes={() => (
                                <Button
                                  variant="contained"
                                  style={{
                                    background: colors.link,
                                    color: colors.white
                                  }}
                                  size="medium"
                                  onClick={() => handleSetMaster()}
                                >
                                  Set As Master
                                </Button>
                              )}
                            />
                          ),
                          <Can
                            perform="admin-debtor-profile:delete"
                            yes={() => (
                              <DeleteButton
                                disabled={isSubmitting}
                                onClick={() =>
                                  openDialog(
                                    'confirm',
                                    'Confirm Delete',
                                    'Are you sure you want to delete this Debtor?',
                                    {
                                      id
                                    }
                                  )
                                }
                              />
                            )}
                          />,
                          <Can
                            perform="admin-debtor-profile:save"
                            yes={() => (
                              <Button
                                variant="contained"
                                color="primary"
                                disabled={isSubmitting}
                                onClick={() => handleSubmit()}
                              >
                                Save
                              </Button>
                            )}
                          />
                        ])}
                      />
                      <Grid item style={{ width: '100%' }}>
                        <NavigationTabs
                          tabs={tabs}
                          value={navIndex}
                          handleRoute={handleRoute}
                        />
                      </Grid>
                      <Grid item>
                        <Grid container spacing={16}>
                          <Grid item lg={4}>
                            <CreditStatusCard
                              disabled={disabled}
                              values={values}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                            />
                          </Grid>
                          <Grid item lg={4}>
                            <Can
                              perform="admin-debtor-profile:creditLimitCard"
                              yes={() => (
                                <CreditLimitCard
                                  values={values}
                                  handleBlur={handleBlur}
                                  setFieldValue={setFieldValue}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item lg={4}>
                            <Can
                              perform="admin-debtor-profile:availableCredit"
                              yes={() => (
                                <AvailableCreditCard
                                  available={values.creditAvailable}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item style={{ width: '100%' }}>
                        <Grid container spacing={16}>
                          <Grid item xs={6}>
                            <ContactInfoCard
                              disabled={disabled}
                              values={values}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              errors={errors}
                              touched={touched}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Can
                              perform="admin-debtor-profile:GeneralInfo"
                              yes={() => (
                                <GeneralInfoCard
                                  disabled={disabled}
                                  values={values}
                                  errors={errors}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  setFieldValue={setFieldValue}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item style={{ width: '100%' }}>
                        <Paper>
                          <Can
                            perform="admin-debtor-profile:GeneralInfo"
                            yes={() => (
                              <>
                                <Tabs
                                  value={index}
                                  onChange={handleTabChange}
                                  variant="fullWidth"
                                >
                                  <Tab label="Internal Debtor Notes" />
                                  <Tab label="Audit Trail" />
                                  <Tab label="Credit Report" />
                                </Tabs>
                                <SwipeableViews
                                  axis={
                                    theme.direction === 'rtl'
                                      ? 'x-reverse'
                                      : 'x'
                                  }
                                  index={index}
                                  onChangeIndex={handleTabChangeIndex}
                                >
                                  <div id="debtor_notes">
                                    <Grid
                                      container
                                      justify="center"
                                      style={{
                                        padding: '32px'
                                      }}
                                    >
                                      <Grid item lg={11}>
                                        <Formik {...notesForm}>
                                          {({
                                            handleChange,
                                            handleSubmit,
                                            handleBlur,
                                            values,
                                            errors,
                                            touched,
                                            setFieldValue,
                                            setFieldTouched,
                                            status,
                                            setStatus,
                                            isSubmitting
                                          }) => {
                                            return (
                                              <Notes
                                                values={values}
                                                errors={errors}
                                                touched={touched}
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                setFieldValue={setFieldValue}
                                                setFieldTouched={
                                                  setFieldTouched
                                                }
                                                handleSubmit={handleSubmit}
                                                isCreatingNote={isSubmitting}
                                                notes={notes}
                                                isFetching={isSubmitting}
                                                loadNotesAttachments={
                                                  loadDebtorNoteAttachments
                                                }
                                                notesAttachments={{}}
                                                isUpdatingNote={isSubmitting}
                                                handleDeleteNote={noteId => {
                                                  deleteDebtorNote(id, noteId);
                                                }}
                                                status={status}
                                                setStatus={setStatus}
                                              />
                                            );
                                          }}
                                        </Formik>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div id="audit_trail">
                                    <AuditTrail
                                      auditLog={auditLog}
                                      prefix={['debtor_', 'user_']}
                                    />
                                  </div>
                                  <div id="credit_report">
                                    <CreditReport id={id} />
                                  </div>
                                </SwipeableViews>
                              </>
                            )}
                            no={() => (
                              <>
                                <Tabs
                                  value={index}
                                  onChange={handleTabChange}
                                  variant="fullWidth"
                                >
                                  <Tab label="Internal Debtor Notes" />
                                </Tabs>
                                <SwipeableViews
                                  axis={
                                    theme.direction === 'rtl'
                                      ? 'x-reverse'
                                      : 'x'
                                  }
                                  index={index}
                                  onChangeIndex={handleTabChangeIndex}
                                >
                                  <div id="debtor_notes">
                                    <Grid
                                      container
                                      justify="center"
                                      style={{
                                        padding: '32px'
                                      }}
                                    >
                                      <Grid item lg={11}>
                                        <Formik {...notesForm}>
                                          {({
                                            handleChange,
                                            handleSubmit,
                                            handleBlur,
                                            values,
                                            errors,
                                            touched,
                                            setFieldValue,
                                            setFieldTouched,
                                            status,
                                            setStatus,
                                            isSubmitting
                                          }) => {
                                            return (
                                              <Notes
                                                values={values}
                                                errors={errors}
                                                touched={touched}
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                setFieldValue={setFieldValue}
                                                setFieldTouched={
                                                  setFieldTouched
                                                }
                                                handleSubmit={handleSubmit}
                                                isCreatingNote={isSubmitting}
                                                notes={notes}
                                                isFetching={isSubmitting}
                                                loadNotesAttachments={
                                                  loadDebtorNoteAttachments
                                                }
                                                notesAttachments={{}}
                                                isUpdatingNote={isSubmitting}
                                                handleDeleteNote={noteId => {
                                                  deleteDebtorNote(id, noteId);
                                                }}
                                                status={status}
                                                setStatus={setStatus}
                                              />
                                            );
                                          }}
                                        </Formik>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </SwipeableViews>
                              </>
                            )}
                          />
                        </Paper>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )}
              </Formik>
            </React.Fragment>
          )}
          {dialog.variant === 'confirm' && (
            <ConfirmDialog
              open={dialog.show}
              message={dialog.message}
              handleConfirm={async () => {
                await deleteDebtor(get(dialog, ['data', 'id']));
                closeDialog();
                handleRoute('/admin/debtors');
              }}
              handleExit={() => closeDialog()}
            />
          )}
        </InternalLayout>
      )}
      no={() => <Redirect to="/" />}
    />
  )
);
