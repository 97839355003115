import React from "react";
import { connect } from "react-redux";
import { compose, lifecycle, withProps } from "recompose";
import { findIndex, get, head, isEmpty, isEqual, reduce } from "lodash";
import { DateTime } from "luxon";
import {
  closeDialog,
  getDialog,
  openDialog,
  openSnackbar
} from "../../../../modules/ui";
import formatDate from "../../../../helpers/format/formatDate";
import withSelect from "../../../../components/withSelect";
import {
  createConnectedClientsCSV,
  deleteDebtorRelationship,
  fetchDebtorRelationHistory,
  getConnectedClients,
  getConnectedClientsCount,
  getConnectedClientsFilters,
  getConnectedClientsIsLoading,
  getConnectedClientsPage,
  getConnectedClientsRowsPerPage,
  getConnectedClientsRowsPerPageOptions,
  getConnectedClientsSortBy,
  getConnectedClientsSortDirection,
  getDebtorCompanyName,
  getDebtorId,
  getDebtorMaster,
  handleConnectedClientsChangePage,
  handleConnectedClientsChangeRowsPerPage,
  handleConnectedClientsSearchFilter,
  handleConnectedClientsSort,
  queryConnectedClients,
  queryDebtor,
  updateFactoringDebtor,
  fetchSimilarDebtors,
  getSimilarDebtors
} from "../../../../modules/debtor";
import {
  fetchAttachments,
  getAttachments,
  sendNOA,
  updateNOADates
} from "../../../../modules/factoring";

import { client as validationSchema } from "./validation";
import PureConnectedClients from "./ConnectedClients";
import tabs from "../tabs";
import MoreLinks from "./components/MoreLinks";
import CustomTextField from "../../../../components/inputs/CustomTextField";
import NoaSummary from "../../../../components/NOASummary";
import columns from "./columns";
import Link from "../../../../components/Link";
import { getUserType } from "apps/haulpay-frontend/src/modules/auth";
import NOADropDown from "../../../../components/NOADropDown";

export const ConnectedClients = PureConnectedClients;

export const connectedClientsForm = (
  debtorId,
  updateFactoringDebtor,
  refresh,
  openSnackbar
) => client => ({
  enableReinitialize: true,
  initialValues: {
    minimum_fee: client.minimumFee / 100,
    discount_rate: client.discountRate,
    relationship_id: client.relationship_id
  },
  validationSchema,
  onSubmit: async (values, { setSubmitting }) => {
    const factoringId = values.id;
    const { minimum_fee: minimumFee, discount_rate: discountRate } = values;
    try {
      await updateFactoringDebtor(debtorId, factoringId, {
        minimum_fee: minimumFee ? Math.round(minimumFee * 100) : minimumFee,
        discount_rate: discountRate,
        ...reduce(values, (results, value, key) => {
          if (/noa/.test(key)) {
            results[key] = value;
          }
          return results;
        }, {})
      });
      // await refresh();
      openSnackbar("success", "Updated Factoring Debtor!");
    } catch (err) {
      openSnackbar("error", head(err));
    } finally {
      setSubmitting(false);
    }
  }
});

const placeNOAForm = (
  openSnackbar,
  attachments,
  sendNOA,
  debtorId,
  factoringId,
  refresh
) => ({
  enableReinitialize: true,
  initialValues: {
    noaToSend: get(head(attachments), "filename", ""),
    attachments
  },
  onSubmit: async (values, { setSubmitting }) => {
    try {
      await sendNOA(debtorId, factoringId, undefined, values.noaToSend);
      await refresh();
      openSnackbar("success", "NOA Sent!");
    } catch (err) {
      openSnackbar("error", head(err));
    } finally {
      setSubmitting(false);
    }
  }
});

const noaActiveForm = ({
  noaSummary: { active, placed },
  debtorId,
  factoringId,
  updateNOADates: update,
  openSnackbar,
  refresh
}) => ({
  enableReinitialize: true,
  initialValues: {
    active: active || ""
  },
  onSubmit: async (values, { setSubmitting }) => {
    try {
      await update(
        debtorId,
        factoringId,
        values.active !== "" ? DateTime.fromISO(values.active).toISO() : null,
        null
      );
      await refresh();
      openSnackbar("success", "Updated NOA Dates!");
    } catch (err) {
      openSnackbar("error", head(err));
    } finally {
      setSubmitting(false);
    }
  }
});

const mapStateToProps = state => ({
  similarDebtors: getSimilarDebtors(state),
  companyName: getDebtorCompanyName(state),
  debtorId: getDebtorId(state),
  connectedClients: getConnectedClients(state),
  isLoading: getConnectedClientsIsLoading(state),
  count: getConnectedClientsCount(state),
  page: getConnectedClientsPage(state),
  rowsPerPage: getConnectedClientsRowsPerPage(state),
  rowsPerPageOptions: getConnectedClientsRowsPerPageOptions(state),
  sortDirection: getConnectedClientsSortDirection(state),
  sortBy: getConnectedClientsSortBy(state),
  filters: getConnectedClientsFilters(state),
  dialog: getDialog(state),
  attachmentsByFactoringId: getAttachments(state),
  master: getDebtorMaster(state),
  userType: getUserType(state),
});

const mapDispatchToProps = {
  queryDebtor,
  openSnackbar,
  queryConnectedClients,
  handleSort: handleConnectedClientsSort,
  handleChangePage: handleConnectedClientsChangePage,
  handleChangeRowsPerPage: handleConnectedClientsChangeRowsPerPage,
  handleSearchFilter: handleConnectedClientsSearchFilter,
  updateFactoringDebtor,
  fetchDebtorRelationHistory,
  openDialog,
  closeDialog,
  fetchAttachments,
  sendNOA,
  updateNOADates: updateNOADates(),
  createConnectedClientsCSV,
  deleteDebtorRelationship,
  fetchSimilarDebtors
};

export default compose(
  withSelect,
  connect(mapStateToProps, mapDispatchToProps),
  withProps(
    ({
      history: {
        location: { pathname },
        push
      },
      match: {
        params: { id }
      },
      connectedClients,
      updateFactoringDebtor,
      queryConnectedClients,
      sortDirection,
      sortBy,
      rowsPerPage,
      page,
      filters,
      openSnackbar,
      openDialog,
      companyName,
      sendNOA,
      updateNOADates,
      debtorId,
      attachmentsByFactoringId,
      createConnectedClientsCSV,
      deleteDebtorRelationship,
      dialog,
      closeDialog,
      similarDebtors
    }) => ({
      createConnectedClientsCSV: () => createConnectedClientsCSV(id),
      columns,
      tabs: tabs(id, similarDebtors.length),
      navIndex: findIndex(tabs(id), tab => pathname === tab.route),
      handleRoute: route => push(route),
      id,
      connectedClients: connectedClients.map(client => ({
        ...client,
        discountRate: client.discount_rate,
        minimumFee: client.minimum_fee,
        dba: (
          <Link to={`/admin/factorclients/${client.id}/profile`}>
            {get(client, "company_profile.name")}
          </Link>
        ),
        time_posted: formatDate(client.time_posted),
        discount_rate: ({ values, errors, handleChange, handleBlur }) => (
          <CustomTextField
            name="discount_rate"
            value={values.discount_rate}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.discount_rate}
            helperText={errors.discount_rate}
          />
        ),
        minimum_fee: ({ values, errors, handleChange, handleBlur }) => (
          <CustomTextField
            name="minimum_fee"
            value={values.minimum_fee}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.minimum_fee}
            helperText={errors.minimum_fee}
          />
        ),
        noa_summary: ({ values, setFieldValue, handleBlur }) => {
          const attachments = attachmentsByFactoringId(client.id)
            .filter(
              attachment =>
                attachment.category === "noa" ||
                attachment.category === "comfreight_noa"
            )
            .map(attachment => ({
              ...attachment,
              modified: formatDate(attachment.modified)
            }));
          return (
            <NOADropDown
              fullWidth
              row={{
                ...client,
                relationship_created: client.time_posted
              }}
              setFieldValue={setFieldValue}
              updatedValue={values.noa}
              onBlur={handleBlur}
              disabled={isEmpty(attachments)}
              placeNOAForm={placeNOAForm(
                openSnackbar,
                attachments,
                sendNOA,
                debtorId,
                client.id
              )}
              clientName={get(client, "company_profile.name")}
              debtorName={companyName}
              helperText={isEmpty(attachments) && 'No NOA on File.'}
            />
          );
        },
        more: ({
          values: { relationship_id: relationshipId, id },
          handleSubmit
        }) => (
          <MoreLinks
            handleSubmit={handleSubmit}
            relationshipId={relationshipId}
            handleAuditLogClick={() =>
              openDialog("auditlog", "Audit Log", "", {
                relationshipId
              })
            }
            handleDeleteRelation={() => {
              openDialog(
                "delete-relation",
                "Delete Debtor Relationship",
                "Are you sure?",
                {
                  factoring: id
                }
              );
            }}
          />
        )
      })),
      connectedClientsForm: connectedClientsForm(
        id,
        updateFactoringDebtor,
        async () => {
          await queryConnectedClients(
            id,
            sortDirection === "asc" ? sortBy : `-${sortBy}`,
            rowsPerPage,
            page,
            filters
          );
        },
        openSnackbar
      ),
      handleConfirmDeleteRelationship: async () => {
        try {
          await deleteDebtorRelationship(id, get(dialog, "data.factoring"));
          openSnackbar("success", "Removed Connected Client!");
        } catch (err) {
          openSnackbar("error", head(err));
        } finally {
          await queryConnectedClients(
            id,
            sortDirection === "asc" ? sortBy : `-${sortBy}`,
            rowsPerPage,
            page,
            filters
          );
          closeDialog();
        }
      }
    })
  ),
  lifecycle({
    componentDidMount() {
      const { id, connectedClients, fetchAttachments } = this.props;
      this.props.queryDebtor(id);
      this.props.fetchSimilarDebtors(id);
      this.props.queryConnectedClients(
        id,
        "-time_posted",
        this.props.rowsPerPage,
        0
      );
      Promise.all(connectedClients.map(client => fetchAttachments(client.id)));
    },
    async componentDidUpdate(prevProps) {
      const {
        fetchDebtorRelationHistory,
        connectedClients,
        fetchAttachments
      } = this.props;
      const { connectedClients: prevClients } = prevProps;

      if (
        !isEqual(
          prevClients.map(client => client.id),
          connectedClients.map(client => client.id)
        )
      ) {
        Promise.all(
          connectedClients.map(client => fetchAttachments(client.id))
        );
      }
    }
  })
)(PureConnectedClients);
