import React, { ChangeEventHandler } from "react";
import { Person } from "@material-ui/icons";
import { MenuItem, TextField } from "@material-ui/core";

import AvatarCard from "../../../../../components/AvatarCard";
import CustomTextField from "../../../../../components/inputs/CustomTextField";

const statuses = [
  {
    value: "approved",
    name: "Approved"
  },
  {
    value: "pending",
    name: "Pending"
  },
  {
    value: "declined",
    name: "Declined"
  },
  {
    value: "declined_3_months",
    name: "Declined 3 Months"
  },
  {
    value: "declined_6_months",
    name: "Declined 6 Months"
  },
  {
    value: "needs_review",
    name: "Needs Review"
  },
  {
    value: "verify_duplicate",
    name: "Verify Duplicate"
  }
];

const statusColor = (status: string): string => {
  switch (status) {
    case "approved":
      return "#4dc527";
    case "declined":
      return "#d0021b";
    case "declined_3_months":
      return "#d0021b";
    case "declined_6_months":
      return "#d0021b";
    case "pending":
      return "#f5a623";
    case "verify_duplicate":
      return "#f5a623";
    case "needs_review":
      return "#d0021b";
    default:
      return "#d0021b";
  }
};

interface Props {
  values: {
    creditStatus: string;
  };
  handleChange: ChangeEventHandler<HTMLInputElement>;
  handleBlur: ChangeEventHandler<HTMLInputElement>;
  disabled: boolean;
}

const CreditStatusCard = ({
  values,
  handleChange,
  handleBlur,
  disabled
}: Props): JSX.Element => (
  <AvatarCard
    title="Credit Approval Status"
    color={statusColor(values.creditStatus)}
    icon={<Person style={{ fontSize: "32px" }} />}
    subheader={
      <TextField
        fullWidth
        select
        disabled={disabled}
        name="creditStatus"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.creditStatus}
        InputProps={{
          disableUnderline: true,
          style: { color: "white" }
        }}
>
  {statuses.map(
          (status): JSX.Element => (
            <MenuItem key={status.value} value={status.value}>
              {status.name}
            </MenuItem>
          )
        )}
</TextField>
    }
  />
);

// @ts-ignore
export default CreditStatusCard;
