import React from 'react';
import {
    FormControlLabel,
    FormGroup,
    List,
    Switch,
    Typography,
    Grid,
    Checkbox,
    Chip
} from '@material-ui/core';
import { FieldArray } from 'formik';
import Card from '../../../../../../components/Card';
import ListItem from '../../../../../../components/ListItem';
import BoldInput, {
    Pennies,
    Select
} from '../../../../../../components/inputs/BoldInput';
import { Attachments } from '../../../../../../components/AttachmentsCard';
import CollatedAttachmentsCard from './CollatedAttachmentsCard';
import TmsInput from '../../../../../../components/inputs/TmsInput';
import Can from '../../../../../../components/Can';
import ProfileSection from '../../../../../../components/ProfileSection';
import { find } from 'lodash';

const styles = {
    container: {
        borderBottom: '1px solid black'
    }
};
const ACH_MAP = {
    ach_same_day_05_30_am: 'N/A',
    ach_same_day_09_00_am: '9AM (SD)',
    ach_same_day_11_30_am: '11:30 (SD)',
    ach_next_day_14_30_pm: '2:30PM',
    ach_next_day_17_30_pm: '5:30PM'
};

const fundingRequestType = [
    {
        value: 'STD_BROKER',
        text: 'Factored',
        header: 'Standard Funding Request'
    },
    {
        text: 'Non-Factored Pay Carrier',
        value: 'NON_FACTORED_STANDARD',
        header: 'Non Factored Funding Requests'
    },
    {
        text: 'Non-Factored Bill Out Only',
        value: 'NON_FACTORED_BILL_OUT',
        header: 'Non Factored Funding Requests'
    },
    {
        value: 'SELF_FINANCE_NO_INVOICE',
        text: 'Self Finance No invoice',
        header: 'Self Finance Funding Request'
    },
    {
        value: 'SELF_FINANCE_WITH_INVOICE',
        text: 'Self Finance with invoice',
        header: 'Self Finance Funding Request'
    }
];

const FundingRequestTypeMapping = {
    STD_BROKER: 'Factored',
    SELF_FINANCE_NO_INVOICE: 'SF No Invoice',
    SELF_FINANCE_WITH_INVOICE: 'SF With Invoice',
    NON_FACTORED_STANDARD: 'Non-Factored',
    NON_FACTORED_BILL_OUT: 'Bill-Out'
};
export default ({
    values,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    files,
    handleDelete,
    categories,
    collatedAttachments,
    handleSendTransferDocuments,
    userType,
    disableAll
}) => (
    <Card style={{ height: '100%' }}>
        <List dense>
            <ListItem>
                <ProfileSection>Factoring Profile Details</ProfileSection>
            </ListItem>
            <ListItem>
                <BoldInput
                    fullWidth
                    name="creditInternalStatusNotes"
                    label="Internal Status Notes:"
                    value={values.creditInternalStatusNotes}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors.creditInternalStatusNotes}
                    helperText={errors.creditInternalStatusNotes}
                    disabled={disableAll}
                />
            </ListItem>
            <ListItem>
                <BoldInput
                    fullWidth
                    name="contractTerm"
                    label="Contract Term:"
                    value={values.contractTerm}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors.contractTerm}
                    helperText={errors.contractTerm}
                    disabled={disableAll}
                />
            </ListItem>
            <ListItem>
                <BoldInput
                    fullWidth
                    name="creditBrokerApproval"
                    label="Broker Approval:"
                    disabled
                    value={values.creditBrokerApproval}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors.creditBrokerApproval}
                    helperText={errors.creditBrokerApproval}
                />
            </ListItem>
            <ListItem>
                <BoldInput
                    fullWidth
                    name="creditMessageToClient"
                    label="Message to Client:"
                    value={values.creditMessageToClient}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors.creditMessageToClient}
                    helperText={errors.creditMessageToClient}
                    disabled={disableAll}
                />
            </ListItem>
            <ListItem>
                <Pennies
                    fullWidth
                    name="creditMinimumFee"
                    label="Minimum Fee:"
                    value={values.creditMinimumFee}
                    error={!!errors.creditMinimumFee}
                    helperText={errors.creditMinimumFee}
                    setFieldValue={setFieldValue}
                    disabled={disableAll}
                />
            </ListItem>
            <ListItem>
                <BoldInput
                    fullWidth
                    name="creditDiscountRate"
                    label="Discount Rate:"
                    value={values.creditDiscountRate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors.creditDiscountRate}
                    helperText={errors.creditDiscountRate}
                    disabled={disableAll}
                />
            </ListItem>
            <Can
                perform="admin-factorclient-profile-nonfactored:view"
                yes={() => (
                    <>
                        <ListItem>
                            <Pennies
                                fullWidth
                                name="nonFactoredFee"
                                label="Non-Factored Fee:"
                                value={values.nonFactoredFee}
                                error={!!errors.nonFactoredFee}
                                helperText={errors.nonFactoredFee}
                                setFieldValue={setFieldValue}
                                disabled={disableAll}
                            />
                        </ListItem>
                        <ListItem>
                            <Pennies
                                fullWidth
                                name="wireFee"
                                label="Wire Fee:"
                                value={values.wireFee}
                                error={!!errors.wireFee}
                                helperText={errors.wireFee}
                                setFieldValue={setFieldValue}
                                disabled={disableAll}
                            />
                        </ListItem>
                    </>
                )}
            />
            <ListItem>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Switch
                                disabled={disableAll}
                                checked={
                                    values.allowBulkImport
                                }
                                onChange={() => {
                                    setFieldValue(
                                        'allowBulkImport',
                                        !values.allowBulkImport
                                    );
                                    if (!values.externalCode) {
                                        setFieldValue(
                                            'dataSyncOption',
                                            !values.allowBulkImport
                                        );
                                    }
                                }}
                                value="allowBulkImport"
                            />
                        }
                        label="Enable Data Import (FRs, Carrier & Debtors)"
                    />
                </FormGroup>
            </ListItem>
            {userType === 'broker' && (
                <>
                    <ListItem>
                        <BoldInput
                            fullWidth
                            name="marginDiscountRate"
                            label="Margin Discount Rate:"
                            value={values.marginDiscountRate}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!errors.marginDiscountRate}
                            helperText={errors.marginDiscountRate}
                            disabled={disableAll}
                        />
                    </ListItem>
                    <ListItem>
                        <BoldInput
                            fullWidth
                            name="reserveHoldbackRate"
                            label="Reserve Holdback %:"
                            value={values.reserveHoldbackRate}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!errors.reserveHoldbackRate}
                            helperText={errors.reserveHoldbackRate}
                            disabled={disableAll}
                        />
                    </ListItem>
                    <ListItem>
                        <Pennies
                            fullWidth
                            name="negativeMarginValue"
                            label="Negative Margin Max Per FR:"
                            value={values.negativeMarginValue}
                            error={!!errors.negativeMarginValue}
                            helperText={errors.negativeMarginValue}
                            setFieldValue={setFieldValue}
                            disabled={disableAll}
                        />
                    </ListItem>
                    <ListItem>
                        <BoldInput
                            fullWidth
                            name="negativeMarginLimit"
                            label="Negative Margin Max No. in 30 Days:"
                            value={values.negativeMarginLimit}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!errors.negativeMarginLimit}
                            helperText={errors.negativeMarginLimit}
                            disabled={disableAll}
                        />
                    </ListItem>
                    <ListItem>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        disabled={disableAll}
                                        checked={values.reserveHoldback}
                                        onChange={() => {
                                            setFieldValue(
                                                'reserveHoldback',
                                                !values.reserveHoldback
                                            );
                                        }}
                                        value="reserveHoldback"
                                    />
                                }
                                label="Reserve Holdback"
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        disabled={disableAll}
                                        checked={values.noNOADebitProgram}
                                        onChange={() => {
                                            setFieldValue(
                                                'noNOADebitProgram',
                                                !values.noNOADebitProgram
                                            );
                                        }}
                                        value="noNOADebitProgram"
                                    />
                                }
                                label="No NOA Debit Program"
                            />
                        </FormGroup>
                    </ListItem>
                </>
            )}
            <ListItem>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Switch
                                disabled={disableAll}
                                checked={values.creditFuelAdvance}
                                onChange={() => {
                                    setFieldValue(
                                        'creditFuelAdvance',
                                        !values.creditFuelAdvance
                                    );
                                }}
                                value="creditFuelAdvance"
                            />
                        }
                        label="Fuel Advance"
                    />
                </FormGroup>
                {userType === 'broker' && (
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    disabled={disableAll}
                                    checked={values.fuelAdvanceAutoApprove}
                                    onChange={() => {
                                        setFieldValue(
                                            'fuelAdvanceAutoApprove',
                                            !values.fuelAdvanceAutoApprove
                                        );
                                    }}
                                    value="fuelAdvanceAutoApprove"
                                />
                            }
                            label="Auto-Approve FA Purchase"
                        />
                    </FormGroup>
                )}
            </ListItem>
            <ListItem>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Switch
                                disabled={disableAll}
                                checked={values.creditPriorityRate}
                                onChange={() => {
                                    setFieldValue(
                                        'creditPriorityRate',
                                        !values.creditPriorityRate
                                    );
                                }}
                                value="creditPriorityRate"
                            />
                        }
                        label="Priority Rate"
                    />
                </FormGroup>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Switch
                                disabled={disableAll}
                                checked={values.showDebtorDeclined}
                                onChange={() => {
                                    setFieldValue(
                                        'showDebtorDeclined',
                                        !values.showDebtorDeclined
                                    );
                                }}
                                value="showDebtorDeclined"
                            />
                        }
                        label="Show Debtors With Rates Above Client Base Discount Rate as Declined"
                    />
                </FormGroup>
                {userType === 'broker' && (
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    disabled={disableAll}
                                    checked={values.editSpeedDisplay}
                                    onChange={() => {
                                        setFieldValue(
                                            'editSpeedDisplay',
                                            !values.editSpeedDisplay
                                        );
                                    }}
                                    value="editSpeedDisplay"
                                />
                            }
                            label="Edit Speed Display"
                        />
                    </FormGroup>
                )}
            </ListItem>
            <ListItem>
                <Pennies
                    fullWidth
                    name="creditFuelAdvanceFee"
                    label="Fuel Advance Fee:"
                    value={values.creditFuelAdvanceFee}
                    error={!!errors.creditFuelAdvanceFee}
                    helperText={errors.creditFuelAdvanceFee}
                    setFieldValue={setFieldValue}
                    disabled={disableAll}
                />
            </ListItem>
            <ListItem>
                <Pennies
                    fullWidth
                    name="creditFuelAdvanceLimit"
                    label="Fuel Advance Limit:"
                    value={values.creditFuelAdvanceLimit}
                    error={!!errors.creditFuelAdvanceLimit}
                    helperText={errors.creditFuelAdvanceLimit}
                    setFieldValue={setFieldValue}
                    disabled={disableAll}
                    withStyle
                />
            </ListItem>
            <ListItem>
                <FieldArray
                    name="achRemitBatches"
                    render={arrayHelpers => (
                        <Grid container direction="column">
                            <Grid item style={styles.container}>
                                <Typography variant="body1">
                                    ACH Client Payment Remit Batches
                                </Typography>
                            </Grid>
                            <Grid item container direction="row">
                                {values.achRemitBatchesOptions &&
                                    values.achRemitBatchesOptions.length > 0 &&
                                    values.achRemitBatchesOptions.map(
                                        (achRemitBatch, index) =>
                                            achRemitBatch !==
                                                'ach_next_day_17_30_pm' &&
                                            achRemitBatch !==
                                                'ach_same_day_05_30_am' ? (
                                                <Grid item>
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    disabled={
                                                                        disableAll
                                                                    }
                                                                    checked={values.achRemitBatches.includes(
                                                                        achRemitBatch
                                                                    )}
                                                                    onChange={event => {
                                                                        if (
                                                                            event
                                                                                .target
                                                                                .checked
                                                                        ) {
                                                                            return arrayHelpers.push(
                                                                                achRemitBatch
                                                                            );
                                                                        }
                                                                        return arrayHelpers.remove(
                                                                            values.achRemitBatches.findIndex(
                                                                                object =>
                                                                                    achRemitBatch ===
                                                                                    object
                                                                            )
                                                                        );
                                                                    }}
                                                                    value={
                                                                        achRemitBatch
                                                                    }
                                                                />
                                                            }
                                                            label={
                                                                ACH_MAP[
                                                                    achRemitBatch
                                                                ]
                                                            }
                                                        />
                                                    </FormGroup>
                                                </Grid>
                                            ) : null
                                    )}
                            </Grid>
                        </Grid>
                    )}
                />
            </ListItem>
            {userType === 'broker' && (
                <>
                    <ListItem>
                        <Grid container direction="column">
                            <Select
                                selectProps={{
                                    multiple: true,
                                    renderValue: (selected = []) => (
                                        <div>
                                            {selected.map(value => (
                                                <Chip
                                                    key={value}
                                                    label={
                                                        FundingRequestTypeMapping[
                                                            value
                                                        ]
                                                    }
                                                />
                                            ))}
                                        </div>
                                    )
                                }}
                                fullWidth
                                value={values.enabledContractTypes}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                name="enabledContractTypes"
                                label="Enable/Disable Funding Request Types"
                                selections={fundingRequestType}
                                disabled={!values.enabledContractTypes}
                                withHeader
                            />
                        </Grid>
                    </ListItem>
                    <ListItem>
                        <Grid container direction="column">
                            <Grid item style={styles.container}>
                                <Typography variant="body1">
                                    Settings - Self Financed Funding Requests
                                </Typography>
                            </Grid>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            disabled={disableAll}
                                            checked={values.allowSelfFinanceFr}
                                            onChange={() => {
                                                setFieldValue(
                                                    'allowSelfFinanceFr',
                                                    !values.allowSelfFinanceFr
                                                );
                                            }}
                                            value="allowSelfFinanceFr"
                                        />
                                    }
                                    label="Self-Finance"
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            disabled={
                                                disableAll ||
                                                !values.allowSelfFinanceFr
                                            }
                                            checked={
                                                values.allowSFApprovalWithoutNOA
                                            }
                                            onChange={() => {
                                                setFieldValue(
                                                    'allowSFApprovalWithoutNOA',
                                                    !values.allowSFApprovalWithoutNOA
                                                );
                                            }}
                                            value="allowSFApprovalWithoutNOA"
                                        />
                                    }
                                    label="Allow SF Approval Without NOA"
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            disabled={
                                                disableAll ||
                                                !values.allowSelfFinanceFr
                                            }
                                            checked={
                                                values.stripeEnabled
                                            }
                                            onChange={() => {
                                                setFieldValue(
                                                    'stripeEnabled',
                                                    !values.stripeEnabled
                                                );
                                            }}
                                            value="stripeEnabled"
                                        />
                                    }
                                    label="Enable Stripe"
                                />
                            </FormGroup>
                            <Grid item container direction="row">
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                disabled={
                                                    disableAll ||
                                                    !values.allowSelfFinanceFr
                                                }
                                                checked={
                                                    values.allowSelfFinanceFrDeclinedDebtors
                                                }
                                                onChange={event =>
                                                    setFieldValue(
                                                        'allowSelfFinanceFrDeclinedDebtors',
                                                        !values.allowSelfFinanceFrDeclinedDebtors
                                                    )
                                                }
                                                value="allowSelfFinanceFrDeclinedDebtors"
                                            />
                                        }
                                        label="Declined Debtor"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                disabled={
                                                    disableAll ||
                                                    !values.allowSelfFinanceFr
                                                }
                                                checked={
                                                    values.allowSelfFinanceFrApprovedDebtors
                                                }
                                                onChange={event =>
                                                    setFieldValue(
                                                        'allowSelfFinanceFrApprovedDebtors',
                                                        !values.allowSelfFinanceFrApprovedDebtors
                                                    )
                                                }
                                                value="allowSelfFinanceFrApprovedDebtors"
                                            />
                                        }
                                        label="Approved Debtor"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                disabled={
                                                    disableAll ||
                                                    !values.allowSelfFinanceFr
                                                }
                                                checked={
                                                    values.enableHideDebtor
                                                }
                                                onChange={event =>
                                                    setFieldValue(
                                                        'enableHideDebtor',
                                                        !values.enableHideDebtor
                                                    )
                                                }
                                                value="enableHideDebtor"
                                            />
                                        }
                                        label="Hide Debtor"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                disabled={
                                                    disableAll ||
                                                    !values.allowSelfFinanceFr
                                                }
                                                checked={
                                                    values.allowSelfFinanceApprovedDebtorsOutOfCredit
                                                }
                                                onChange={event =>
                                                    setFieldValue(
                                                        'allowSelfFinanceApprovedDebtorsOutOfCredit',
                                                        !values.allowSelfFinanceApprovedDebtorsOutOfCredit
                                                    )
                                                }
                                                value="allowSelfFinanceApprovedDebtorsOutOfCredit"
                                            />
                                        }
                                        label="Approved Debtors Out of Credit"
                                    />
                                </FormGroup>
                            </Grid>
                            <Pennies
                                fullWidth
                                name="selfFinanceFRLimit"
                                label="self-Finance FR Limit (Per FR)"
                                value={values.selfFinanceFRLimit}
                                error={!!errors.selfFinanceFRLimit}
                                helperText={errors.selfFinanceFRLimit}
                                setFieldValue={setFieldValue}
                                disabled={!values.allowSelfFinanceFr}
                            />
                            <Pennies
                                fullWidth
                                name="selfFinanceRolling30DayFRLimit"
                                label="Rolling 30 Day FR Limit (ALL FRs)"
                                value={values.selfFinanceRolling30DayFRLimit}
                                error={!!errors.selfFinanceRolling30DayFRLimit}
                                helperText={
                                    errors.selfFinanceRolling30DayFRLimit
                                }
                                setFieldValue={setFieldValue}
                                disabled={!values.allowSelfFinanceFr}
                            />
                            <Select
                                fullWidth
                                value={values.defaultFrTypeApi}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                name="defaultFrTypeApi"
                                label="Default FR Type"
                                selections={fundingRequestType.map(
                                    contractType => {
                                        return {
                                            ...contractType,
                                            disabled: !values.enabledContractTypes.includes(
                                                contractType.value
                                            )
                                        };
                                    }
                                )}
                                disabled={!values.allowSelfFinanceFr}
                                error={!!errors.defaultFrTypeApi}
                                helperText={errors.defaultFrTypeApi}
                                withHeader
                            />
                        </Grid>
                    </ListItem>
                </>
            )}
            <ListItem>
                <Grid container direction="column">
                    <Grid item style={styles.container}>
                        <Typography variant="body1">
                            Settings - General
                        </Typography>
                    </Grid>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    disabled={disableAll}
                                    checked={values.enablePayViaCheck}
                                    onChange={() => {
                                        setFieldValue(
                                            'enablePayViaCheck',
                                            !values.enablePayViaCheck
                                        );
                                    }}
                                    value="enablePayViaCheck"
                                />
                            }
                            label="Enable Pay Via Check"
                        />
                    </FormGroup>
                </Grid>
            </ListItem>
            {userType === 'carrier' && (
                <>
                    <ListItem>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        disabled={disableAll}
                                        checked={values.ccClientDebtorInvoiceEmail}
                                        onChange={() => {
                                            setFieldValue(
                                                'ccClientDebtorInvoiceEmail',
                                                !values.ccClientDebtorInvoiceEmail
                                            );
                                        }}
                                        value="ccClientDebtorInvoiceEmail"
                                    />
                                }
                                label="CC Client on Invoices to Debtor"
                            />
                        </FormGroup>
                    </ListItem>
                    <ListItem>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        disabled={disableAll}
                                        checked={
                                            values.stripeEnabled
                                        }
                                        onChange={() => {
                                            setFieldValue(
                                                'stripeEnabled',
                                                !values.stripeEnabled
                                            );
                                        }}
                                        value="stripeEnabled"
                                    />
                                }
                                label="Enable Stripe"
                            />
                        </FormGroup>
                    </ListItem>
                </>
            )}
            {userType === 'broker' && (
                <>
                    <ListItem>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        disabled={disableAll}
                                        checked={values.allowLineItemizedItems}
                                        onChange={() => {
                                            setFieldValue(
                                                'allowLineItemizedItems',
                                                !values.allowLineItemizedItems
                                            );
                                        }}
                                        value="allowLineItemizedItems"
                                    />
                                }
                                label="Line Items Generated By Client"
                            />
                        </FormGroup>
                    </ListItem>
                    <ListItem>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        disabled={disableAll}
                                        checked={values.autoGenerateLoadNumber}
                                        onChange={() => {
                                            setFieldValue(
                                                'autoGenerateLoadNumber',
                                                !values.autoGenerateLoadNumber
                                            );
                                        }}
                                        value="autoGenerateLoadNumber"
                                    />
                                }
                                label="Auto Generate Load Numbers"
                            />
                        </FormGroup>
                    </ListItem>
                    <ListItem>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        disabled={disableAll}
                                        checked={
                                            values.sendCarrierRemittanceToBroker
                                        }
                                        onChange={() => {
                                            setFieldValue(
                                                'sendCarrierRemittanceToBroker',
                                                !values.sendCarrierRemittanceToBroker
                                            );
                                        }}
                                        value="sendCarrierRemittanceToBroker"
                                    />
                                }
                                label="Send Daily Carrier Remit to Broker"
                            />
                        </FormGroup>
                    </ListItem>
                    <ListItem>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        disabled={disableAll}
                                        checked={
                                            values.sendDailyInvoiceSettlement
                                        }
                                        onChange={() => {
                                            setFieldValue(
                                                'sendDailyInvoiceSettlement',
                                                !values.sendDailyInvoiceSettlement
                                            );
                                        }}
                                        value="sendDailyInvoiceSettlement"
                                    />
                                }
                                label="Send Daily Invoice Settlement Report to Broker"
                            />
                        </FormGroup>
                    </ListItem>
                    <ListItem>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        disabled={disableAll}
                                        checked={values.sendDebtorOpenARCopy}
                                        onChange={() => {
                                            setFieldValue(
                                                'sendDebtorOpenARCopy',
                                                !values.sendDebtorOpenARCopy
                                            );
                                        }}
                                        value="sendDebtorOpenARCopy"
                                    />
                                }
                                label="Send Client Specific Open AR Email"
                            />
                        </FormGroup>
                    </ListItem>
                    <ListItem>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        disabled={disableAll}
                                        checked={values.allowNegativeMargin}
                                        onChange={() => {
                                            setFieldValue(
                                                'allowNegativeMargin',
                                                !values.allowNegativeMargin
                                            );
                                        }}
                                        value="allowNegativeMargin"
                                    />
                                }
                                label="Allow broker Negative Margin"
                            />
                        </FormGroup>
                    </ListItem>
                    <ListItem>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        disabled={disableAll}
                                        checked={
                                            values.allowPendingReserveAccess
                                        }
                                        onChange={() => {
                                            setFieldValue(
                                                'allowPendingReserveAccess',
                                                !values.allowPendingReserveAccess
                                            );
                                        }}
                                        value="allowPendingReserveAccess"
                                    />
                                }
                                label="Access to Pending CB/GB Report "
                            />
                        </FormGroup>
                    </ListItem>
                    <ListItem>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        disabled={disableAll}
                                        checked={
                                            values.requireSignedBOLDelivered
                                        }
                                        onChange={() => {
                                            setFieldValue(
                                                'requireSignedBOLDelivered',
                                                !values.requireSignedBOLDelivered
                                            );
                                        }}
                                        value="requireSignedBOLDelivered"
                                    />
                                }
                                label="Require Signed BOL Delivered for FR Approval"
                            />
                        </FormGroup>
                    </ListItem>
                    <ListItem>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        disabled={disableAll}
                                        checked={
                                            values.ccBrokerRateConfirmation
                                        }
                                        onChange={() => {
                                            setFieldValue(
                                                'ccBrokerRateConfirmation',
                                                !values.ccBrokerRateConfirmation
                                            );
                                        }}
                                        value="ccBrokerRateConfirmation"
                                    />
                                }
                                label="CC Broker on Rate Con. Email"
                            />
                        </FormGroup>
                    </ListItem>
                    <ListItem>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        disabled={disableAll}
                                        checked={
                                            values.ccBrokerDefaultPayment
                                        }
                                        onChange={() => {
                                            setFieldValue(
                                                'ccBrokerDefaultPayment',
                                                !values.ccBrokerDefaultPayment
                                            );
                                        }}
                                        value="ccBrokerDefaultPayment"
                                    />
                                }
                                label="CC Broker on Default Payment Settings Email"
                            />
                        </FormGroup>
                    </ListItem>
                    <ListItem>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        disabled={disableAll}
                                        checked={
                                            values.ccClientDebtorInvoiceEmail
                                        }
                                        onChange={() => {
                                            setFieldValue(
                                                'ccClientDebtorInvoiceEmail',
                                                !values.ccClientDebtorInvoiceEmail
                                            );
                                        }}
                                        value="ccClientDebtorInvoiceEmail"
                                    />
                                }
                                label="CC Client on Invoices to Debtor"
                            />
                        </FormGroup>
                    </ListItem>
                    <ListItem>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        disabled={disableAll}
                                        checked={values.sendRateConfirmationPdf}
                                        onChange={() => {
                                            setFieldValue(
                                                'sendRateConfirmationPdf',
                                                !values.sendRateConfirmationPdf
                                            );
                                        }}
                                        value="sendRateConfirmationPdf"
                                    />
                                }
                                label="Send Rate Confirmation PDF to Carrier"
                            />
                        </FormGroup>
                    </ListItem>
                    <ListItem>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        disabled={disableAll}
                                        checked={
                                            values.sendRateConfirmationRemoteApproved
                                        }
                                        onChange={() => {
                                            setFieldValue(
                                                'sendRateConfirmationRemoteApproved',
                                                !values.sendRateConfirmationRemoteApproved
                                            );
                                        }}
                                        value="sendRateConfirmationRemoteApproved"
                                    />
                                }
                                label="Only Send Rate Con. Emails in Remote Approved"
                            />
                        </FormGroup>
                    </ListItem>
                    <ListItem>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        disabled={disableAll}
                                        checked={values.reportAnsoniaHistory}
                                        onChange={() => {
                                            setFieldValue(
                                                'reportAnsoniaHistory',
                                                !values.reportAnsoniaHistory
                                            );
                                        }}
                                        value="reportAnsoniaHistory"
                                    />
                                }
                                label="Report Debtor's Payment History as Client's"
                            />
                        </FormGroup>
                    </ListItem>
                    <ListItem>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        disabled={disableAll}
                                        checked={
                                            values.factoringCreateNonFactoredFr
                                        }
                                        onChange={() => {
                                            setFieldValue(
                                                'factoringCreateNonFactoredFr',
                                                !values.factoringCreateNonFactoredFr
                                            );
                                        }}
                                        value="factoringCreateNonFactoredFr"
                                    />
                                }
                                label="Non-Factored FR Create"
                            />
                        </FormGroup>
                    </ListItem>
                    <ListItem>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        disabled={disableAll}
                                        checked={
                                            values.disableSendClientInvoices
                                        }
                                        onChange={() => {
                                            setFieldValue(
                                                'disableSendClientInvoices',
                                                !values.disableSendClientInvoices
                                            );
                                        }}
                                        value="disableSendClientInvoices"
                                    />
                                }
                                label="Do Not Send Debtor Invoice"
                            />
                        </FormGroup>
                    </ListItem>
                    <ListItem>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        disabled={disableAll}
                                        checked={values.allowFrWithoutCarrier}
                                        onChange={() => {
                                            setFieldValue(
                                                'allowFrWithoutCarrier',
                                                !values.allowFrWithoutCarrier
                                            );
                                        }}
                                        value="allowFrWithoutCarrier"
                                    />
                                }
                                label="Allow FR Create w/o Carrier"
                            />
                        </FormGroup>
                    </ListItem>
                    <ListItem>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        disabled={disableAll}
                                        checked={values.autoRecovery}
                                        onChange={() => {
                                            setFieldValue(
                                                'autoRecovery',
                                                !values.autoRecovery
                                            );
                                        }}
                                        value="autoRecovery"
                                    />
                                }
                                label="Auto-create chargeBacks on positive FRs"
                            />
                        </FormGroup>
                    </ListItem>
                    <ListItem>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        disabled={disableAll}
                                        checked={
                                            values.enableReserveHoldbackHybrid
                                        }
                                        onChange={() => {
                                            setFieldValue(
                                                'enableReserveHoldbackHybrid',
                                                !values.enableReserveHoldbackHybrid
                                            );
                                        }}
                                        value="enableReserveHoldbackHybrid"
                                    />
                                }
                                label="Reserve Choice Program (Hybrid Program)"
                            />
                        </FormGroup>
                    </ListItem>
                    <ListItem>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        disabled={disableAll}
                                        checked={
                                            values.defaultEnableReserveHoldback
                                        }
                                        onChange={() => {
                                            setFieldValue(
                                                'defaultEnableReserveHoldback',
                                                !values.defaultEnableReserveHoldback
                                            );
                                        }}
                                        value="defaultEnableReserveHoldback"
                                    />
                                }
                                label="Default Selection for Reserve Choice Program"
                            />
                        </FormGroup>
                    </ListItem>
                    <ListItem>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        disabled={disableAll}
                                        checked={values.dataSyncOption}
                                        onChange={() => {
                                            setFieldValue(
                                                'dataSyncOption',
                                                !values.dataSyncOption
                                            );
                                        }}
                                        value="dataSyncOption"
                                    />
                                }
                                label="Enable External Code"
                            />
                        </FormGroup>
                    </ListItem>
                    <ListItem>
                        <TmsInput
                            disabled={disableAll}
                            value={values.tms}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            setFieldValue={setFieldValue}
                        />
                    </ListItem>
                </>
            )}
            <Can
                perform="admin-factorclient-attachments:view"
                yes={() => (
                    <React.Fragment>
                        <ListItem>
                            <ProfileSection>Attachments</ProfileSection>
                        </ListItem>

                        <div style={{ margin: 8 }}>
                            <Attachments
                                files={files || []}
                                values={values}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                handleDelete={handleDelete}
                                setFieldValue={setFieldValue}
                                setFieldTouched={setFieldTouched}
                                categories={categories}
                            />
                            <CollatedAttachmentsCard
                                collatedAttachment={collatedAttachments}
                                sendTransferDocuments={
                                    handleSendTransferDocuments
                                }
                            />
                        </div>
                    </React.Fragment>
                )}
            />
        </List>
    </Card>
);
