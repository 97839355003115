import React from "react";
import AdminDropDown from "../../../components/AdminDropDown";
import formatPennies from "../../../helpers/format/formatPennies";
import Source from "../../../components/icons/SourceIcon";
import formatDate from "../../../helpers/format/formatDate";
import splitAndCase from "../../../helpers/format/splitAndCase";

// @ts-ignore
export default (status: string) => {
  let columns: any[] = [
    {
      key: "client_company_name",
      name: "Client",
      filter: "text",
      filter_key: "client_company_name",
      sort: "factoring"
    },
    {
      key: "factoring.user_type",
      name: "User Type",
      format: splitAndCase,
      filter_key: "fr_type",
      filter: "dropdown",
      filter_values: [
        { label: "All", value: "" },
        { label: "Broker", value: "broker" },
        { label: "Carrier", value: "carrier" }
      ]
    },
    {
      key: "debtor_name",
      name: "Debtor",
      filter: "text",
      filter_key: "bill_to_company_name",
      sort: "debtor"
    },
    {
      key: "noa",
      name: "NOA",
      sort: "noa_sent"
    },
    {
      key: "invoice_amount",
      name: "Invoice Amnt.",
      sort: "amount"
    },
    {
      key: "invoiceRow",
      name: "Invoice #",
      filter: "text",
      filter_key: "invoice_number",
      sort: "invoice_number"
    },
    {
      key: "load_number",
      name: "Load #",
      filter: "text",
      filter_key: "user_load_number",
      sort: true
    },
    {
      key: "pending_line_items_count",
      name: "Pending Line Items"
    },
    {
      key: "client_reserve_negative_active",
      name: "Reserve Balance",
      format: formatPennies
    },
    {
      key: "created",
      name: "Requested At",
      sort: true
    }
  ];

  if (status === "approved") {
    columns = columns.concat([
      {
        key: "scheduled_purchase_date",
        name: "Scheduled Purchase Date",
        filter_key: "scheduled_purchase_date",
        filter_value: "",
        filter: "date",
        filter_format: "toDateTime",
        format: formatDate
      }
    ]);
  }

  columns = columns.concat([
    {
      key: "assigned_admin.name",
      name: "Transaction Rep",
      default: "Not Assigned",
      filter: "component",
      filter_key: "assigned_admin",
      filter_value: "",
      filter_component: AdminDropDown,
      sort: "assigned_admin"
    },
    {
      key: "amount_funded",
      name: "Amnt. Funded",
      sort: true
    },
    {
      key: "status",
      name: "Status",
      sort: "status_set",
      filter: "dropdown",
      filter_key: "status",
      filter_value: "",
      colSpan: 2,
      filter_values: [
        { label: "New (NOA)", value: "new_noa" },
        { label: "New", value: "new" },
        { label: "Fuel Advance", value: "fuel_advance" },
        { label: "Non-Factored", value: "non_factored" },
        { label: "Pending", value: "pending" },
        { label: "Pending (NOA)", value: "pending_noa" },
        { label: "Special Pending", value: "special_pending" },
        { label: "Pending Originals", value: "pending_originals" },
        { label: "Pending Delivery", value: "pending_delivery" },
        { label: "Document Issue", value: "document_issue" },
        { label: "CF Review", value: "haulpay_review" },
        { label: "Incomplete", value: "incomplete" },
        { label: "Document Review", value: "document_review" },
        { label: "Approved", value: "approved" },
        { label: "Remote Approved", value: "remote_approved" },
        { label: "Declined", value: "declined" },
        { label: "Paid", value: "paid" }
      ]
    },
    {
      key: "source",
      name: "Source",
      format: (source: string): JSX.Element => <Source source={source} />
    },
    {
      key: "more",
      name: "More"
    }
  ]);

  return columns;
};
