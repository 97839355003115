import { createReducer } from "redux-starter-kit";
import { get } from "lodash";
import { compose } from "recompose";

import * as TYPES from "./types";
import fetchAttachmentsReducer from "./reducers/fetchAttachments";
import notesReducer from "./reducers/notes";
import queryRelationshipsReducer from "./reducers/queryRelationships";
import withTable from "../withTable";
import queryRelationships, {
  next as nextQueryRelationships
} from "./actions/queryRelationships";
import queryFundingRequests, { next as nextQueryFundingRequests } from "./actions/queryFundingRequests";
import queryFundingRequestsReducer from "./reducers/queryFundingRequests";
import queryPurchasesReducer from "./reducers/fetchPurchases";
import fetchHistory from "./reducers/fetchHistory";
import fetchPaymentProfilePurchases, {
  next as nextfetchPaymentProfilePurchases
} from "./actions/fetchPurchases";
import fetchSimilarPaymentProfileReducer from "./reducers/fetchSimilarPaymentProfile";

export {
  default as fetchSimilarPaymentProfile
} from "./actions/fetchSimilarPaymentProfile";

const reducer = createReducer(
  {
    attachments: {},
    billingNotes: {}
  },
  {
    [TYPES.FETCH_ATTACHMENTS_REQUEST]: fetchAttachmentsReducer,
    [TYPES.FETCH_ATTACHMENTS_SUCCESS]: fetchAttachmentsReducer,
    [TYPES.FETCH_ATTACHMENTS_FAILURE]: fetchAttachmentsReducer,

    [TYPES.FETCH_HISTORY_REQUEST]: fetchHistory,
    [TYPES.FETCH_HISTORY_SUCCESS]: fetchHistory,
    [TYPES.FETCH_HISTORY_FAILURE]: fetchHistory,

    [TYPES.QUERY_RELATIONSHIPS_REQUEST]: queryRelationshipsReducer,
    [TYPES.QUERY_RELATIONSHIPS_SUCCESS]: queryRelationshipsReducer,
    [TYPES.QUERY_RELATIONSHIPS_FAILURE]: queryRelationshipsReducer,

    [TYPES.QUERY_FUNDING_REQUEST_REQUEST]: queryFundingRequestsReducer,
    [TYPES.QUERY_FUNDING_REQUEST_SUCCESS]: queryFundingRequestsReducer,
    [TYPES.QUERY_FUNDING_REQUEST_FAILURE]: queryFundingRequestsReducer,

    [TYPES.FETCH_NOTES_REQUEST]: notesReducer,
    [TYPES.FETCH_NOTES_FAILURE]: notesReducer,
    [TYPES.FETCH_NOTES_SUCCESS]: notesReducer,

    [TYPES.CREATE_NOTES_SUCCESS]: notesReducer,

    [TYPES.DELETE_NOTES_FAILURE]: notesReducer,
    [TYPES.DELETE_NOTES_REQUEST]: notesReducer,
    [TYPES.DELETE_NOTES_SUCCESS]: notesReducer,

    [TYPES.FETCH_PAYMENT_PROFILE_PURCHASES_SUCCESS]: queryPurchasesReducer,
    [TYPES.FETCH_PAYMENT_PROFILE_PURCHASES_REQUEST]: queryPurchasesReducer,
    [TYPES.FETCH_PAYMENT_PROFILE_PURCHASES_ERROR]: queryPurchasesReducer,

    [TYPES.FETCH_SIMILAR_PAYMENT_PROFILE_REQUEST]: fetchSimilarPaymentProfileReducer,
    [TYPES.FETCH_SIMILAR_PAYMENT_PROFILE_SUCCESS]: fetchSimilarPaymentProfileReducer,
    [TYPES.FETCH_SIMILAR_PAYMENT_PROFILE_FAILURE]: fetchSimilarPaymentProfileReducer
  }
);

const relationshipsTable = withTable({
  stateKey: "payment.relationships",
  typeKey: "payment_profile/query_relationships"
});

const fundingRequestsTable = withTable({
  stateKey: "payment.fundingRequests",
  typeKey: "payment_profile/query_funding_request"
});

const purchasesTable = withTable({
  stateKey: "payment.purchases",
  typeKey: "payment_profile/purchases"
});

export default compose(
  relationshipsTable.reducer,
  fundingRequestsTable.reducer,
  purchasesTable.reducer
)(reducer);

export const middleware = [
  purchasesTable.middleware(selectors => store => action => {
    const state = store.getState();
    const filters = selectors.getFilters(state);
    const rowsPerPage = selectors.getRowsPerPage(state);
    const prevPage = selectors.getPrevPage(state);
    const links = selectors.getLinks(state);
    const id = get(state, ["payment", "purchases", "id"]);

    if (action.type.includes("UPDATE_PAGE")) {
      if (action.payload.page > prevPage) {
        store.dispatch(
          nextfetchPaymentProfilePurchases(id, links.next, rowsPerPage, filters)
        );
      } else {
        store.dispatch(
          nextfetchPaymentProfilePurchases(
            id,
            links.previous,
            rowsPerPage,
            filters
          )
        );
      }
    } else {
      store.dispatch(fetchPaymentProfilePurchases(id, rowsPerPage, filters));
    }
  }),
  relationshipsTable.middleware(selectors => store => action => {
    const state = store.getState();
    const filters = selectors.getFilters(state);
    const sortDirection = selectors.getSortDirection(state);
    const sortBy = selectors.getSortBy(state);
    const rowsPerPage = selectors.getRowsPerPage(state);
    const prevPage = selectors.getPrevPage(state);
    const ordering = sortDirection === "asc" ? sortBy : `-${sortBy}`;
    const links = selectors.getLinks(state);
    const id = get(state, ["payment", "relationships", "id"]);

    if (action.type.includes("UPDATE_PAGE")) {
      if (action.payload.page > prevPage) {
        store.dispatch(
          nextQueryRelationships(id, links.next, rowsPerPage, ordering, filters)
        );
      } else {
        store.dispatch(
          nextQueryRelationships(
            id,
            links.previous,
            rowsPerPage,
            ordering,
            filters
          )
        );
      }
    } else {
      store.dispatch(queryRelationships(id, rowsPerPage, ordering, filters));
    }
  }),
  fundingRequestsTable.middleware(selectors => store => action => {
    const state = store.getState();
    const filters = selectors.getFilters(state);
    const sortDirection = selectors.getSortDirection(state);
    const sortBy = selectors.getSortBy(state);
    const rowsPerPage = selectors.getRowsPerPage(state);
    const prevPage = selectors.getPrevPage(state);
    const ordering = sortDirection === "asc" ? sortBy : `-${sortBy}`;
    const links = selectors.getLinks(state);
    const id = get(state, ["payment", "fundingRequests", "id"]);
    
    if (action.type.includes("UPDATE_PAGE")) {
      if (action.payload.page > prevPage) {
        store.dispatch(
          nextQueryFundingRequests(id, links.next, rowsPerPage, ordering, filters)
        );
      } else {
        store.dispatch(
          nextQueryFundingRequests(
            id,
            links.previous,
            rowsPerPage,
            ordering,
            filters
          )
        );
      }
    } else {
      store.dispatch(queryFundingRequests(id, rowsPerPage, ordering, filters));
    }
  })
];

export const fundingRequestTableSelectors = fundingRequestsTable.selectors;
export const fundingRequestTableActions = fundingRequestsTable.actions;

export const relationsTableSelectors = relationshipsTable.selectors;
export const relationsTableActions = relationshipsTable.actions;

export const purchasesTableSelectors = purchasesTable.selectors;
export const purchasesTableActions = purchasesTable.actions;

export { default as getFundingRequests } from "./selectors/getFundingRequests";
export {
  default as queryFundingRequests
} from "./actions/queryFundingRequests";
export {
  default as fetchPaymentProfilePurchases
} from "./actions/fetchPurchases";
export { default as fetchNotes } from "./actions/fetchNotes";
export { default as updateNotes } from "./actions/updateNotes";
export { default as deleteNotes } from "./actions/deleteNotes";
export { default as createNotes } from "./actions/createNotes";
export { default as queryRelationships } from "./actions/queryRelationships";
export { default as getRelationships } from "./selectors/getRelationships";

export { default as getPaymentProfile } from "./selectors/getPaymentProfile";
export { default as fetchPaymentProfile } from "./actions/fetchPaymentProfile";
export {
  default as updatePaymentProfile
} from "./actions/updatePaymentProfile";

export { default as fetchUser } from "./actions/fetchUser";
export { default as getUser } from "./selectors/getUser";

export { default as fetchHistory } from "./actions/fetchHistory";
export { default as getHistory } from "./selectors/getHistory";

export { default as fetchAttachments } from "./actions/fetchAttachments";
export { default as uploadAttachments } from "./actions/uploadAttachments";
export { default as getAttachments } from "./selectors/getAttachments";
export { default as getBillingNotes } from "./selectors/getBillingNotes";
export {
  default as getLoadingBillingNotes
} from "./selectors/getLoadingBillingNotes";
export {
  default as getPaymentProfilePurchases
} from "./selectors/getPurchases";
export {
  default as getSimilarPaymentProfile
} from "./selectors/getSimilarPaymentProfile";
export { default as deleteAttachment } from "./actions/deleteAttachment";
export { default as createPurchasesCSV } from "./createPurchasesCSV";
