import React, { createRef, useCallback, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { Button, Grid, IconButton, Paper, Tooltip } from "@material-ui/core";
import { get, head, omit, find, isEmpty, filter } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faList } from "@fortawesome/free-solid-svg-icons";
import { normalize } from "normalizr";

import Link from "../../../../components/Link";
import {
  fetchPaymentProfile,
  getPaymentProfile,
  getRelationships,
  queryRelationships,
  relationsTableActions,
  relationsTableSelectors
} from "../../../../modules/payment";
import Table from "../../../../components/Table";
import InternalLayout from "../../../../components/layouts/InternalLayout";
import Can from "../../../../components/Can";
import NavigationTabs from "../../../../components/NavigationTabs";
import tabs from "../tabs";
import columns from "./columns";
import api from "../../../../api/groups";
import {
  createPaymentProfileAttachment,
  queryConnectedClients,
  updateRelationship
} from "../../../../modules/factorCompany";
import { Select } from "../../../../components/inputs/BoldInput";
import {
  getDialog,
  openDialog,
  openSnackbar,
  closeDialog
} from "../../../../modules/ui";
import openFileDialog from "../../../../helpers/openFileDialog";
import colors from "../../../../theme/colors";
import awsImageUpload from "../../../../helpers/awsImageUpload";
import RemoveFactorDialog from "../../../../components/dialogs/RemoveFactorDialog";
import ConfirmNOADialog from "../../../../components/dialogs/ConfirmNOADialog";
import ConnectBrokerDialog from "./components/ConnectBrokerDialog";
import LinkFactorDialog from "./components/LinkFactorDialog";
import WithSimilarPaymentProfile from "../WithSimilarPaymentProfile";
import AuditLogDialog from "./components/AuditLogDialog";
import { paymentProfileRelationship } from "../../../../api/schema";
import { addEntities } from "../../../../modules/entities";
import ConfirmDialog from "../../../../components/dialogs/ConfirmDialog";
import PaperClip from "./components/icons/PaperClip";
import Save from "./components/icons/Save";
import Trash from "./components/icons/Trash";

const ConnectBroker = ({ onClick }) => {
  return (
    <Button
      variant="contained"
      style={{
        backgroundColor: colors.green,
        color: colors.white,
        justifySelf: "flex-end"
      }}
      onClick={onClick}
      mini
    >
      <FontAwesomeIcon icon={faPlus} style={{ margin: 5 }} size="xs" />
      Connect Broker
    </Button>
  );
};

const NOAInput = ({ status, onChange }) => {
  return (
    <Select
      fullWidth
      name="noa_status"
      onChange={onChange}
      value={status}
      selections={[
        {
          text: "In-Active",
          value: "inactive"
        },
        {
          text: "Active",
          value: "active"
        }
      ]}
    />
  );
};

export default function ConnectedBrokers(props) {
  const dispatch = useDispatch();
  const id = get(props, ["match", "params", "id"], "");
  const [count] = WithSimilarPaymentProfile(id);
  const paymentProfile = useSelector(getPaymentProfile(id));

  useEffect(() => {
    dispatch(queryRelationships(id));
  }, [id]);

  useEffect(() => {
    dispatch(fetchPaymentProfile(id));
  }, [id]);

  const [send, setSend] = useState(false);
  const [newAttachments, setNewAttachments] = useState([]);
  const relationships = useSelector(getRelationships);
  const dialog = useSelector(getDialog);

  const updateFactorCompany = useCallback(
    paymentProfileRelationshipObject => {
      const data = normalize(
        paymentProfileRelationshipObject,
        paymentProfileRelationship
      );
      dispatch(addEntities(data.entities));
    },
    [dispatch]
  );

  const accounts = filter(
    paymentProfile.accounts,
    o => !o.deleted_reason && o.account_user_type !== "factoring_company"
  );

  const handleConnectBroker = useCallback(
    () => dispatch(openDialog("connect-broker-dialog")),
    [dispatch]
  );

  const openAuditLogDialog = useCallback(
    relationshipId =>
      dispatch(openDialog("audit-log-dialog", "", "", { relationshipId })),
    [dispatch]
  );

  const handleLinkFactor = useCallback(
    args =>
      dispatch(
        openDialog("link-factor-dialog", "", "", { relationship: args })
      ),
    [dispatch]
  );

  const refresh = useCallback(
    args => dispatch(queryRelationships(id, 20, "-created", {})),
    [dispatch]
  );

  const handleCloseDialog = useCallback(() => dispatch(closeDialog()), [
    dispatch
  ]);

  const openConfirmDialog = useCallback(
    args =>
      dispatch(
        openDialog(
          "confirm",
          "",
          "Carrier does not have a default Payment method, You still want to continue?",
          args,
          "delete_broker_factor"
        )
      ),
    []
  );

  const deleteRelationFactorDebtor = useCallback(
    args => dispatch(openDialog("delete_relation_factor_debtor", "", "", args)),
    []
  );

  return (
    <Can
      perform="admin-payments:view"
      yes={() => (
        <InternalLayout title="Payment Profile">
          <Grid
            container
            direction="column"
            spacing={24}
            justify="flex-start"
            alignItems="stretch"
          >
            <Grid item>
              <NavigationTabs tabs={tabs(id, count)} value={3} />
            </Grid>
            <Grid item>
              <Paper style={{ padding: "16px" }}>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-end"
                  }}
                >
                  <ConnectBroker onClick={handleConnectBroker} />
                </div>
                <Table
                  columns={columns}
                  rows={relationships.map((relationship, index) => {
                    const attachments = get(
                      relationship,
                      ["factoring_company_relation", "attachments"],
                      []
                    ).filter(a => a.category === "NOA");
                    const factoring_company = get(
                      relationship,
                      ["factoring_company_relation", "factoring_company"],
                      ""
                    );
                    const factoring_company_relation_id = get(relationship, [
                      "factoring_company_relation",
                      "id"
                    ]);
                    const factoring_company_name = get(
                      relationship,
                      ["factoring_company_relation", "factoring_company_name"],
                      ""
                    );
                    let upload = createRef();
                    return {
                      name: (
                        <Link
                          to={`/admin/factorclients/${get(relationship, [
                            "factoring",
                            "id"
                          ])}/profile`}
                        >
                          {get(relationship, [
                            "factoring",
                            "company_profile",
                            "name"
                          ])}
                        </Link>
                      ),
                      noa: ({ values, handleChange }) =>
                        factoring_company_relation_id ? (
                          <NOAInput
                            onChange={handleChange}
                            status={values.noa_status}
                          />
                        ) : (
                          <div />
                        ),
                      noaConnection: ({ values }) => (
                        <>
                          {factoring_company_relation_id ? (
                            <Grid container direction="row">
                              <Grid item>
                                <Tooltip title="Remove Member">
                                  <IconButton
                                    style={{ width: "48px", height: "48px" }}
                                    onClick={async () =>
                                      !accounts.length > 0
                                        ? openConfirmDialog({
                                            ...relationship,
                                            index
                                          })
                                        : deleteRelationFactorDebtor({
                                            ...relationship,
                                            index
                                          })
                                    }
                                  >
                                    <Trash />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                              <Grid item>
                                {newAttachments[index] && (
                                  <Tooltip title="View NOA">
                                    <IconButton
                                      style={{ width: "48px", height: "48px" }}
                                      onClick={async () => {
                                        const url = URL.createObjectURL(
                                          newAttachments[index]
                                        );
                                        window.open(url, "_blank");
                                      }}
                                    >
                                      <PaperClip />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                {attachments.length > 0 && (
                                  <Tooltip title="View NOA">
                                    <IconButton
                                      style={{ width: "48px", height: "48px" }}
                                      onClick={async () => {
                                        window.open(
                                          head(attachments).preview_url,
                                          "_blank"
                                        );
                                      }}
                                    >
                                      <PaperClip />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                {!attachments.length > 0 &&
                                  !newAttachments[index] && (
                                    <React.Fragment>
                                      <Tooltip title="Upload NOA">
                                        <IconButton
                                          style={{
                                            width: "48px",
                                            height: "48px"
                                          }}
                                          onClick={async () => {
                                            openFileDialog(upload);
                                          }}
                                        >
                                          <CloudUploadIcon
                                            style={{
                                              color: colors.light_orange
                                            }}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                      <input
                                        type="file"
                                        name="attachments"
                                        ref={ref => (upload = ref)}
                                        style={{ display: "none" }}
                                        onChange={async e => {
                                          try {
                                            const file =
                                              e.currentTarget.files[0];
                                            const response = await dispatch(
                                              createPaymentProfileAttachment(
                                                factoring_company_relation_id,
                                                file.name,
                                                "NOA"
                                              )
                                            );
                                            await awsImageUpload([
                                              {
                                                file,
                                                upload_url:
                                                  response.data.upload_url
                                              }
                                            ]);
                                            const array = newAttachments;
                                            array[index] = file;
                                            setNewAttachments(array);
                                            await dispatch(
                                              queryConnectedClients(id)
                                            );
                                          } catch (e) {}
                                        }}
                                      />
                                    </React.Fragment>
                                  )}
                              </Grid>
                            </Grid>
                          ) : (
                            <div />
                          )}
                          <Tooltip title="Upload NOA">
                            <IconButton
                              style={{
                                width: "48px",
                                height: "48px"
                              }}
                              onClick={() =>
                                openAuditLogDialog(relationship.id)
                              }
                            >
                              <FontAwesomeIcon
                                icon={faList}
                                color={colors.purple}
                              />
                            </IconButton>
                          </Tooltip>
                        </>
                      ),
                      confirmNoa: () =>
                        factoring_company_relation_id ? (
                          <React.Fragment>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                setSend(factoring_company_relation_id)
                              }
                            >
                              Send
                            </Button>
                            <ConfirmNOADialog
                              open={send === factoring_company_relation_id}
                              name={get(relationship, [
                                "factoring_company_relation",
                                "factoring_company_name"
                              ])}
                              companyId={get(relationship, [
                                "factoring_company_relation",
                                "factoring_company"
                              ])}
                              paymentProfileId={get(relationship, [
                                "factoring_company_relation",
                                "payment_profile"
                              ])}
                              handleExit={() => setSend(false)}
                            />
                          </React.Fragment>
                        ) : (
                          <div />
                        ),
                      actions: ({ handleSubmit }) => (
                        <Tooltip title="Save Updates">
                          <IconButton
                            style={{ width: "48px", height: "48px" }}
                            onClick={() => handleSubmit()}
                          >
                            <Save />
                          </IconButton>
                        </Tooltip>
                      ),
                      broker: (
                        <Link
                          to={`/admin/factoring_client/${get(
                            relationship,
                            ["broker_relation", "factoring_id"],
                            ""
                          )}/profile`}
                        >
                          {get(relationship, "broker_client_name", "")}
                        </Link>
                      ),
                      factor: factoring_company ? (
                        <Link
                          to={`/admin/companies/${factoring_company}/profile`}
                        >
                          {factoring_company_name}
                        </Link>
                      ) : (
                        !isEmpty(
                          get(paymentProfile, "factoring_company", {})
                        ) && (
                          <Button
                            onClick={() => handleLinkFactor(relationship)}
                            color="primary"
                          >
                            Add Factor
                          </Button>
                        )
                      ),
                      brokerPortion: ({ values, handleChange, handleBlur }) => (
                        <Select
                          value={values.broker_portion}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="broker_portion"
                          disabled={factoring_company}
                          selections={[
                            {
                              text: "100%",
                              value: "100"
                            },
                            {
                              text: "50%",
                              value: "50"
                            },
                            {
                              text: "0%",
                              value: "0"
                            }
                          ]}
                        />
                      ),
                      discountRate: ({
                        values,
                        handleChange,
                        handleBlur,
                        setFieldValue
                      }) => (
                        <Select
                          value={values.payout_days}
                          onBlur={handleBlur}
                          fullWidth
                          disabled={factoring_company}
                          onChange={e => {
                            try {
                              handleChange(e);
                              const payoutDays = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                              setFieldValue(
                                "broker_portion",
                                find(relationship.payment_speed, {
                                  payout_days: parseInt(payoutDays, 0)
                                }).factoring_fee_split_rate
                              );
                            } catch (err) {}
                          }}
                          name="payout_days"
                          selections={relationship.payment_speed
                            .sort((a, b) => a.payout_days - b.payout_days)
                            .map(term => {
                              if (term.payout_days == "0") {
                                term.payout_days = 1;
                              }
                              return term;
                            })
                            .map(term => ({
                              value: `ach_${term.payout_days}_day`,
                              text: `${term.payout_days} Days ${term.carrier_rate}%`
                            }))}
                        />
                      ),
                      ...relationship
                    };
                  })}
                  allowEmpty
                  isLoading={useSelector(relationsTableSelectors.getIsLoading)}
                  handleSort={useCallback(
                    (...args) =>
                      dispatch(relationsTableActions.handleSort(...args)),
                    [dispatch]
                  )}
                  sortDirection={useSelector(
                    relationsTableSelectors.getSortDirection
                  )}
                  sortBy={useSelector(relationsTableSelectors.getSortBy)}
                  count={useSelector(relationsTableSelectors.getCount)}
                  page={useSelector(relationsTableSelectors.getPage)}
                  rowsPerPage={useSelector(
                    relationsTableSelectors.getRowsPerPage
                  )}
                  handleChangePage={useCallback(
                    (...args) =>
                      dispatch(relationsTableActions.handleChangePage(...args)),
                    [dispatch]
                  )}
                  rowsPerPageOptions={useSelector(
                    relationsTableSelectors.getRowsPerPageOptions
                  )}
                  handleChangeRowsPerPage={useCallback(
                    (...args) =>
                      dispatch(
                        relationsTableActions.handleChangeRowsPerPage(...args)
                      ),
                    [dispatch]
                  )}
                  filter
                  filters={useSelector(relationsTableSelectors.getFilters)}
                  handleFilterChange={useCallback(
                    (...args) =>
                      dispatch(
                        relationsTableActions.handleSearchFilter(...args)
                      ),
                    [dispatch]
                  )}
                  formikRow={args => ({
                    enableReinitialize: true,
                    initialValues: {
                      id: get(args, "id"),
                      noa_status: get(args, [
                        "factoring_company_relation",
                        "noa_status"
                      ]),
                      discount_rate: get(args, "discount_rate"),
                      broker_portion: get(args, "factoring_fee_split_rate"),
                      payout_days: get(args, "payout_days")
                    },
                    onSubmit: async (values, { setSubmitting }) => {
                      try {
                        if (get(args, ["factoring_company_relation", "id"])) {
                          await dispatch(
                            updateRelationship(
                              get(args, ["factoring_company_relation", "id"]),
                              {
                                noa_status: values.noa_status
                              }
                            )
                          );
                        }
                        await api.updatePaymentProfileRelationship(
                          get(args, "factoring.id"),
                          get(args, "id"),
                          {
                            factoring_fee_split_rate: values.broker_portion,
                            payout_days: values.payout_days
                          }
                        );
                        dispatch(
                          openSnackbar("success", "Connected Broker Updated!")
                        );
                      } catch (err) {
                        dispatch(openSnackbar("error", head(err)));
                      } finally {
                        setSubmitting(false);
                      }
                    }
                  })}
                />
              </Paper>
            </Grid>
            {dialog.variant === "connect-broker-dialog" && (
              <ConnectBrokerDialog
                paymentProfileId={id}
                {...dialog}
                handleClose={handleCloseDialog}
                refresh={refresh}
              />
            )}
            {dialog.variant === "link-factor-dialog" && (
              <LinkFactorDialog
                paymentProfileId={id}
                {...dialog}
                handleClose={handleCloseDialog}
              />
            )}
            {dialog.variant === "audit-log-dialog" && (
              <AuditLogDialog
                {...dialog.data}
                {...dialog}
                handleClose={handleCloseDialog}
              />
            )}
            {dialog.variant === "confirm" &&
              dialog.action === "delete_broker_factor" && (
                <ConfirmDialog
                  open={dialog.show}
                  message={dialog.message}
                  handleConfirm={() => {
                    dispatch(closeDialog());
                    deleteRelationFactorDebtor(dialog.data);
                  }}
                  handleExit={() => {
                    dispatch(closeDialog());
                  }}
                />
              )}
            {dialog.variant === "delete_relation_factor_debtor" && (
              <RemoveFactorDialog
                open={dialog.show}
                id={get(dialog.data, ["factoring_company_relation", "id"])}
                handleExit={() => dispatch(closeDialog())}
                name={get(dialog.data, [
                  "factoring_company_relation",
                  "broker_client_name"
                ])}
                baseId={get(dialog.data, "id")}
                extraSubmitFunction={() =>
                  updateFactorCompany(
                    omit(
                      relationships[get(dialog.data, ["index"])],
                      "factoring_company_relation"
                    )
                  )
                }
              />
            )}
          </Grid>
        </InternalLayout>
      )}
      no={() => <Redirect to="/" />}
    />
  );
}
