import React from "react";
import { withStateHandlers } from "recompose";
import {
  Button,
  Collapse,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Slide,
  Switch,
  Typography
} from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { Formik } from "formik";
import SaveIcon from "@material-ui/icons/Save";
import { filter, get } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import {
  DialogActions,
  DialogTitle,
  Dialog,
  DialogContent
} from "../../../../../components/Dialog";
import RoundedButton from "../../../../../components/buttons/RoundedButton";
import Colors from "../../../../../theme/colors";
import { openSnackbar } from "../../../../../modules/ui";
import PenniesInput from "../../../../../components/inputs/PenniesInput";
import CustomTextField from "../../../../../components/inputs/CustomTextField";
import TableList from "../../../../../components/Table/TableList";
import lineItemsColumns from "./columns";
import formatPennies from "../../../../../helpers/format/formatPennies";
import { getReceivable } from "../../../../../modules/finances";

const appliedAgainst = [
  {
    value: "client",
    label: "Client"
  },
  {
    value: "debtor",
    label: "Debtor"
  },
  {
    value: "organization",
    label: "ComFreight"
  },
  {
    value: "proof",
    label: "Proof"
  }
];

const AppliedAgainstInput = ({ values, handleChange, handleBlur }) => (
  <CustomTextField
    fullWidth
    select
    label="Who's Charged"
    name="applied_against"
    id="applied_against"
    onChange={handleChange}
    onBlur={handleBlur}
    value={values.applied_against}
  >
    {appliedAgainst.map(option => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ))}
  </CustomTextField>
);

const CategoriesInput = ({ values, handleChange, handleBlur, categories }) => (
  <CustomTextField
    fullWidth
    select
    label="Category"
    name="category"
    id="category"
    onChange={handleChange}
    onBlur={handleBlur}
    value={values.category}
  >
    {categories.map(option => (
      <MenuItem key={option.id} value={option.id}>
        {option.name}
      </MenuItem>
    ))}
  </CustomTextField>
);

const GridColumn = ({ children, numbers, ...props }) => (
  <Grid direction="column" item xs={numbers} {...props}>
    {children}
  </Grid>
);

const AdjustmentView = ({
  receivables,
  setShowForm,
  fetchLineItems,
  setSelectedReceivable,
  handleClose
}) => (
  <div style={{ width: "100%" }}>
    <DialogContent>
      <Grid container spacing={8}>
        {receivables.map(item => {
          return (
            <Grid container alignItems="center" item xs={12} direction="row">
              <Grid item xs={5}>
                <Typography variant="body2">
                  {`Invoice # ${get(
                    item,
                    "funding_request_invoice_number",
                    ""
                  )}`}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography variant="body2">
                  {`Open ${formatPennies(get(item, "amount_balance", ""))}`}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Button
                  mini
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setShowForm(true);
                    fetchLineItems(get(item, "funding_request.id", ""));
                    setSelectedReceivable(item);
                  }}
                >
                  Adjust
                </Button>
              </Grid>
            </Grid>
          );
        })}
        <Grid container alignItems="center" item xs={12} direction="row" />
      </Grid>
    </DialogContent>
    <DialogActions>
      <RoundedButton
        variant="contained"
        color="primary"
        progressStyle={{ color: Colors.primary }}
        onClick={handleClose}
      >
        <SaveIcon fontSize="small" />
        Done
      </RoundedButton>
    </DialogActions>
  </div>
);

export default withStateHandlers(
  ({ showForm = false }) => ({
    showForm
  }),
  {
    setShowForm: () => showForm => ({ showForm })
  }
)(
  ({
    open,
    handleClose,
    values = {
      applied_against: "",
      approval_status: true,
      amount_requested: 0,
      category: "",
      description: ""
    },
    setShowForm,
    showForm,
    receivables,
    fetchLineItems,
    lineItems,
    selectedReceivable,
    setSelectedReceivable,
    LineItemCategories,
    createLineItem
  }) => {
    const receivable = useSelector(
      getReceivable(get(selectedReceivable, "id"))
    );
    const dispatch = useDispatch();
    return (
      <Dialog open={open} maxWidth="md" fullWidth>
        <DialogTitle onClose={handleClose}>
          {showForm && (
            <IconButton
              style={{ padding: 5 }}
              onClick={() => setShowForm(false)}
            >
              <ArrowBack />
            </IconButton>
          )}
          Adjustments
        </DialogTitle>
        <Collapse in={!showForm}>
          <AdjustmentView
            receivables={receivables}
            setShowForm={setShowForm}
            fetchLineItems={fetchLineItems}
            setSelectedReceivable={setSelectedReceivable}
            handleClose={handleClose}
          />
        </Collapse>
        <Formik
          onSubmit={async (fields, formikActions) => {
            try {
              fields.amount_requested = Math.round(
                fields.amount_requested * 100
              );
              if (fields.approval_status) {
                fields.approval_status = "approved";
              } else {
                delete fields.approval_status;
              }
              await createLineItem(fields);
              formikActions.resetForm(values);
              dispatch(
                openSnackbar(
                  "success",
                  "Line item has been created successfully."
                )
              );
            } catch (e) {
              dispatch(openSnackbar("error", e.message || e));
            }
            formikActions.setSubmitting(false);
          }}
          initialValues={values}
          enableReinitialize
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue
          }) => {
            return (
              <Slide in={showForm} direction="right" mountOnEnter unmountOnExit>
                <React.Fragment>
                  <DialogContent>
                    <Grid
                      container
                      spacing={16}
                      alignItems="center"
                      style={{ padding: 10 }}
                    >
                      <GridColumn numbers={12}>
                        <Typography
                          variant="body2"
                          style={{ marginBottom: 10 }}
                        >
                          {`Open: ${formatPennies(
                            get(receivable, "amount_balance", "")
                          )}`}
                        </Typography>
                        <Divider />
                      </GridColumn>
                      <GridColumn numbers={12}>
                        <PenniesInput
                          fullWidth
                          name="amount_requested"
                          label="Amount"
                          onBlur={handleBlur}
                          value={values.amount_requested}
                          setFieldValue={setFieldValue}
                          withStyle
                        />
                      </GridColumn>
                      <GridColumn numbers={12}>
                        <CustomTextField
                          fullWidth
                          type="number"
                          name="discount_rate"
                          label="Discount Rate"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                %
                              </InputAdornment>
                            )
                          }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.discount_rate}
                        />
                      </GridColumn>
                      <GridColumn numbers={12}>
                        <AppliedAgainstInput
                          values={values}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                        />
                      </GridColumn>
                      {values.applied_against && (
                        <GridColumn numbers={12}>
                          <CategoriesInput
                            values={values}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            categories={filter(LineItemCategories, [
                              "appliedAgainst",
                              values.applied_against
                            ])}
                          />
                        </GridColumn>
                      )}
                      <GridColumn numbers={12}>
                        <CustomTextField
                          fullWidth
                          name="description"
                          label="Description"
                          multiline
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.description}
                        />
                      </GridColumn>
                      <GridColumn numbers={12}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={values.approval_status}
                                onChange={() => {
                                  setFieldValue(
                                    "approval_status",
                                    !values.approval_status
                                  );
                                }}
                                value="approval_status"
                              />
                            }
                            label="Approved"
                          />
                        </FormGroup>
                      </GridColumn>
                    </Grid>
                    <Grid container>
                      <GridColumn numbers={12}>
                        <Typography
                          variant="body2"
                          style={{ marginBottom: 10 }}
                        >
                          Line Items
                        </Typography>
                        <Divider />
                      </GridColumn>
                      <TableList
                        columns={lineItemsColumns}
                        rows={lineItems}
                        allowEmpty
                      />
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <div>
                      <RoundedButton
                        variant="contained"
                        color="primary"
                        progressStyle={{ color: Colors.primary }}
                        onClick={handleSubmit}
                        loading={isSubmitting}
                      >
                        <SaveIcon fontSize="small" />
                        Save
                      </RoundedButton>
                    </div>
                  </DialogActions>
                </React.Fragment>
              </Slide>
            );
          }}
        </Formik>
      </Dialog>
    );
  }
);
